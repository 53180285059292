import React from "react";
import styles from "../../../styles/CreateCase.module.css";
import DropdownLevel from "../../managementMember/DropdownLevel";

export default function Level({
  temporaryStorage,
  setLevelChoose,
  excelData,
  createClick,
  idx,
  levelChoose,
}) {
  return (
    <div className={styles.ChoiceTask}>
      <div className={styles.DivBox}>
        <span>난이도*</span>
      </div>
      <div className={styles.Test}>
        <DropdownLevel
          setLevelChoose={setLevelChoose}
          researcherTF={true}
          createCase={true}
          leave={temporaryStorage}
          idx={idx}
          excelData={excelData.length > 0 && excelData}
          levelChoose={levelChoose}
        />

        {createClick && levelChoose[idx]?.level.length === 0 && (
          <span style={{ color: "red" }}> 필수 정보를 입력해주세요.</span>
        )}
      </div>
    </div>
  );
}
