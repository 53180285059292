import Button from "react-bootstrap/esm/Button";
import styles from "../../../styles/MainResearcher.module.css";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { updateCurrentTaskInfo } from "../../../redux/slice/taskInfo/taskInfo";
import { useDispatch, useSelector } from "react-redux";
import { useAxiosContext } from "../../../context/AxiosContainer";

export default function CreateEditTheoryTableRow({ taskInfo }) {
  let navigate = useNavigate();
  console.log(taskInfo);

  return (
    <tr className={styles.QuizTable}>
      <td>{taskInfo?.category}</td>
      <td>{taskInfo?.level}</td>
      <td>
        {taskInfo?.title
          ? taskInfo?.title
          : "(케이스 생성 후 입력한 케이스명이 노출됩니다.)"}
      </td>
      <td>{taskInfo?.deadLine}</td>
      <td>{taskInfo?.status}</td>
      <td>
        <Button
          className={styles.BatchCreateBtn}
          onClick={() => {
            console.log(taskInfo?.taskId);
            // navigate("/Admin/createCaseR", {
            //   state: { data: taskInfo },
            // });
          }}
        >
          생성하기
        </Button>
      </td>
    </tr>
  );
}
