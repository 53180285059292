import React from 'react';
import { useSelector } from 'react-redux';
import CategoryData from './CategoryData';
import TheoriesData from './TheoriesData';

export default function NodeList() {
  const { categoryList, theoryManager } = useSelector(
    (state) => state.createTheory
  );
  const theoryList = theoryManager?.getSortedTheoryListByCategoryId();
  const unifiedData = categoryList?.map((category) => ({
    category,
    theories: theoryList[category.getCategoryId() - 1],
  }));

  return (
    <div>
      {unifiedData.map((data) => {
        return (
          <div key={data.category.getCategoryId()}>
            {data.category && <CategoryData category={data.category} />}
            {data.theories && <TheoriesData theories={data.theories} />}
          </div>
        );
      })}
    </div>
  );
}
