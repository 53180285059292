import React from 'react';
import { popupMode } from '../../../key/popupMode';
import AddPopup from './AddPopup';
import RemovePopup from './RemovePopup';

export default function Popup({ mode }) {
  const show = mode !== popupMode.invisible;
  switch (mode) {
    case popupMode.createCategory:
    case popupMode.createTheory:
      return <AddPopup show={show} />;
    case popupMode.deleteTheory:
    case popupMode.deleteCategory:
      return <RemovePopup show={show} />;
    default:
      return <>NONE</>;
  }
}
