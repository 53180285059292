import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAxiosContext } from "../context/AxiosContainer";
import Button from "react-bootstrap/Button";
import styles from "../styles/SignUpFinish.module.css";

export default function SignUpFinishPage() {
  const { pathname } = useLocation();
  const { httpInterface } = useAxiosContext();
  let navigate = useNavigate();
  const [isValidation, setIsValidation] = useState(false);

  console.log(pathname, pathname.split("/")[2] == "rJy2OBoxd-8TSh3aFuBU6");
  useEffect(() => {
    httpInterface
      .postEmailValidation({ token: pathname.split("/")[2] })
      .then((res) => {
        console.log(res);
        setIsValidation(true);
      })
      .catch((error) => {
        console.log("Test Component Error", error);
        setIsValidation(false);
      });
  }, []);
  return (
    <div className={styles.Container}>
      <div className={styles.Title}>RTW 회원가입</div>
      <div className={styles.Content}>
        <span>
          {isValidation
            ? "회원가입이 완료 되었습니다."
            : "1일 내 인증되지 않아 회원가입을 완료할 수 없습니다."}
        </span>
        <br />
        <span>
          {isValidation
            ? "RTW 웹사이트에서 로그인해 주세요."
            : "RTW 웹사이트에서 새로 가입해 주세요."}
        </span>
      </div>
      <div className={styles.LoginBtn}>
        <Button
          style={{ width: "60%" }}
          onClick={() => {
            navigate("/");
          }}
        >
          {isValidation ? "로그인하기" : "RTW로 이동하기"}
        </Button>
      </div>
    </div>
  );
}
