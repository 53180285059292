import React, { useEffect, useState } from 'react';
import styles from './NodeList.module.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeMode,
  changePopupMode,
  setCurrentTheory,
} from '../../../../redux/slice/theory/createTheory';
import { theoryMode } from '../../../../key/theoryMode';
import EditTheoryInput from './EditTheoryInput';
import { popupMode } from '../../../../key/popupMode';

export default function TheoryData({ theory }) {
  const { mode, currentTarget } = useSelector((state) => state.createTheory);
  console.log(currentTarget);
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    if (currentTarget.theory !== theory) {
      setEdit(false);
    }
  }, [currentTarget.theory]);

  if (edit && currentTarget.theory === theory) return <EditTheoryInput />;

  return (
    <div
      className={`${theory === currentTarget.theory ? styles.selected : ''} ${
        styles.default
      } ${styles.theory}`}
      key={theory.getId()}
      onClick={() => {
        if (mode !== theoryMode.modify && mode !== theoryMode.delete) {
          dispatch(changeMode({ mode: theoryMode.move }));
        }
        dispatch(setCurrentTheory({ theory }));
      }}
      onDoubleClick={() => {
        if (currentTarget.theory !== theory) return;
        if (mode === theoryMode.modify) {
          setEdit(true);
        }
        if (mode === theoryMode.delete) {
          // alert('모달 띄울 예정. 삭제로직 구현- TheoryData 컴포넌트');
          dispatch(changePopupMode({ popupMode: popupMode.deleteTheory }));
        }
      }}
    >
      {theory.getText()}
    </div>
  );
}
