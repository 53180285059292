import React, { useState, useEffect } from "react";
import styles from "../../styles/ManagementResearcher.module.css";
import Table from "react-bootstrap/Table";

export default function ChoiceTestList({
  data,
  researcherTF,
  setChoiceTest,
  choice,
  createPs,
  taskType,
  level,
  category,
  setCaseNum,
  searchTF,
}) {
  console.log(level);
  const [createPsNum, setCreatePsNum] = useState(
    level?.length > 0 ? [1] : [1, 2, 3, 4, 5]
  );
  useEffect(() => {
    setCreatePsNum(level?.length > 0 ? [1] : [1, 2, 3, 4, 5]);
  }, [level]);

  // 체크된 아이템을 담을 배열
  const [checkItems, setCheckItems] = useState([]);

  // 체크박스 단일 선택
  const handleSingleCheck = (checked, id) => {
    if (checked) {
      // 단일 선택 시 체크된 아이템을 배열에 추가
      setCheckItems((prev) => [...prev, id]);
    } else {
      // 단일 선택 해제 시 체크된 아이템을 제외한 배열 (필터)
      setCheckItems(checkItems.filter((el) => el !== id));
    }
  };

  // 체크박스 전체 선택
  const handleAllCheck = (checked) => {
    if (checked) {
      // 전체 선택 클릭 시 데이터의 모든 아이템(id)를 담은 배열로 checkItems 상태 업데이트
      const idArray = [];

      data.forEach((el) => {
        console.log(el);
        idArray.push(el.testId);
      });
      setCheckItems(idArray);
    } else {
      // 전체 선택 해제 시 checkItems 를 빈 배열로 상태 업데이트
      setCheckItems([]);
    }
  };
  console.log(checkItems);
  useEffect(() => {
    if (checkItems) setChoiceTest(checkItems);
  }, [checkItems]);

  return (
    <>
      <div className={styles.TaskBox}>
        <Table borderless hover>
          <thead>
            <tr className={styles.Header}>
              <th
                style={{
                  width: "10%",
                }}
              >
                {!createPs && (
                  <div className={styles.AllcheckWrap}>
                    <input
                      type="checkbox"
                      name="select-all"
                      onChange={(e) => handleAllCheck(e.target.checked)}
                      // 데이터 개수와 체크된 아이템의 개수가 다를 경우 선택 해제 (하나라도 해제 시 선택 해제)
                      checked={checkItems.length === data.length ? true : false}
                    />
                    <span className={styles.Allcheck}>전체선택</span>
                  </div>
                )}
              </th>

              <th
                style={{
                  width: "10%",
                }}
              >
                {createPs && "케이스 유형"}
              </th>
              <th
                style={{
                  width: "10%",
                }}
              >
                {" "}
                {createPs && "난이도"}
              </th>
              <th
                style={{
                  width: "20%",
                }}
              >
                {" "}
              </th>
              <th
                style={{
                  width: "30%",
                }}
              >
                {" "}
              </th>
            </tr>
          </thead>

          <tbody className={styles.QuizTable}>
            {data.length !== 0 &&
              !createPs &&
              data.map((a, i) => {
                return (
                  <>
                    <tr>
                      <td style={{ textAlign: "center" }}>
                        <input
                          type="checkbox"
                          name={`select-${a.testId}`}
                          onChange={(e) =>
                            handleSingleCheck(e.target.checked, a.testId)
                          }
                          // 체크된 아이템 배열에 해당 아이템이 있을 경우 선택 활성화, 아닐 시 해제
                          checked={checkItems.includes(a.testId) ? true : false}
                        />
                      </td>
                      <td style={{ textAlign: "center" }}>{a.quizCategory}</td>
                      <td style={{ textAlign: "center" }}>{a.level}</td>
                      <td>{a.quizBody}</td>
                      <td style={{ padding: "1.5%", lineHeight: "18px" }}>
                        {a.subQuestionBody.map((b, j) => {
                          return (
                            <>
                              {b}

                              <br />
                            </>
                          );
                        })}
                      </td>
                    </tr>
                  </>
                );
              })}
            {createPs &&
              searchTF &&
              createPsNum.map((a, i) => {
                console.log(category, level, createPsNum);
                return (
                  <>
                    {
                      <>
                        <tr>
                          <td></td>
                          <td>{category}</td>
                          <td>{level ? level : i + 1}</td>
                          <td>
                            <input
                              type="number"
                              onChange={(e) => {
                                setCaseNum(e.target.value);
                              }}
                            ></input>
                          </td>
                          <td style={{ textAlign: "left" }}>개 케이스 생성</td>
                        </tr>
                      </>
                    }
                  </>
                );
              })}
          </tbody>
        </Table>
      </div>
    </>
  );
}
