import React, { useEffect, useState } from 'react';
import { useAxiosContext } from '../../../context/AxiosContainer';
import { useDispatch, useSelector } from 'react-redux';
import { DBCategory } from '../../../api/theory/category';
import { TheoryCalculator } from '../../../api/theory/theoryCalculator';
import { DBTheory } from '../../../api/theory/theory';
import { setSubQuestionData } from '../../../redux/slice/theory/manageTheory';
import ManageTheory from './ManageTheory';

export default function ReassignTheories({ subQuestionId }) {
  const { httpInterface } = useAxiosContext();
  const { accessToken } = useSelector((state) => state.loginInfo);
  const [categoryList, setCategoryList] = useState([]);
  const [theoryList, setTheoryList] = useState([]);
  const dispatch = useDispatch();
  const { subQuestionText } = useSelector((state) => state.manageTheory);

  console.log(subQuestionId);
  useEffect(() => {
    httpInterface
      .getAssignTheoryData(accessToken, {
        params: {
          subQuestionId,
        },
      })
      .then((res) => {
        const { cardList, subQuestionId, subQuestionText } = res.data.data[0];
        console.log(subQuestionId, subQuestionText);
        dispatch(setSubQuestionData({ subQuestionId, subQuestionText }));
        const { categories, theories, theoryCount, totalTheoryCount } =
          convertCardList(cardList);
        // console.log(categories, theories, theoryCount, totalTheoryCount);
        if (categories.length > 0 && theories.length > 0) {
          const calculator = new TheoryCalculator();
          let acc = 0;
          const categoryInstances = categories.map((data, idx) => {
            const category = new DBCategory(data);
            const cur = theoryCount[idx];
            const angles = calculator.calcCategoryAngles(
              cur,
              acc,
              totalTheoryCount
            );
            acc += cur;
            category.setAngles(angles);
            const coord = calculator.getScreenCoordByDegreeAndDepth(
              category,
              50,
              10
            );
            category.setScreenCoord(coord);
            return category;
          });

          setCategoryList(categoryInstances);
          // setCategoryList(category);
          const theoryInstances = theories.map((data) => {
            const theory = new DBTheory(data);
            const coord = calculator.calculateTheoryScreenCoordByCircleCoord(
              theory,
              categoryInstances
            );
            theory.setScreenCoord(coord);
            return theory;
          });
          setTheoryList(theoryInstances);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <div>{subQuestionText}</div>
      <ManageTheory categoryList={categoryList} theoryList={theoryList} />
    </div>
  );
}

function convertCardList(dataset) {
  const results = {
    categories: dataset
      .filter((data) => data.depth <= 20)
      .map((data) => ({
        ...data,
        depth: 10,
        degree: 50,
        answerCategory: parseInt(data.answerCategory),
      }))
      .sort((a, b) => a.answerCategory - b.answerCategory),
    // category: dataset.filter(data => data.depth === '10'),
    theories: dataset
      .filter((data) => parseInt(data.depth) > 20)
      .map((data) => ({
        ...data,
        degree: parseFloat(data.degree),
        depth: parseFloat(data.depth),
      })),
  };
  results.theoryCount = results.theories.reduce((acc, cur) => {
    const idx = cur.answerCategory;
    if (!acc[idx - 1]) acc[idx - 1] = 2;
    else acc[idx - 1] += 1;
    return acc;
  }, []);
  // results.totalTheoryCount = results.theories.length;
  results.totalTheoryCount = dataset.length;
  return results;
}
