import { Category } from '../../../api/theory/category';
import { Theory } from '../../../api/theory/theory';

const classifyCategory = (data) => {
  const { depth } = data;
  if (depth === '10') {
    return true;
  } else {
    return false;
  }
};

export const getTheoryOrCategory = (data) => {
  if (classifyCategory(data)) {
    return getCategory(data);
  } else {
    return getTheory(data);
  }
};

const getCategory = (data) => {
  const { answerCategory, degree, depth, engText, id, text } = data;
  const category = new Category(text);
  category.setCategoryId(parseInt(answerCategory));
  category.setId(id);
  return category;
};

const getTheory = (data) => {
  const { answerCategory, degree, depth, engText, id, text } = data;
  const theory = new Theory(
    parseInt(id),
    text,
    parseInt(degree),
    parseInt(depth)
  );
  theory.setCategoryId(parseInt(answerCategory));
  return theory;
};
