import React from 'react';

import styles from './ButtonList.module.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  refreshStateWhenGetNewData,
  trashedItem,
} from '../../../redux/slice/theory/manageTheory';
import { DBTheory } from '../../../api/theory/theory';
import { useAxiosContext } from '../../../context/AxiosContainer';
import { useLocation } from 'react-router-dom';

export default function ButtonList({ answers, setAnswers }) {
  const { trashedItems, assignedItems, selectedItem, subQuestionId } =
    useSelector((state) => state.manageTheory);
  const { accessToken } = useSelector((state) => state.loginInfo);
  const location = useLocation();
  const { taskId } = location.state;
  const { httpInterface } = useAxiosContext();
  const dispatch = useDispatch();

  console.log(trashedItems, assignedItems, subQuestionId);

  const throwingDataHandler = () => {
    const theory = new DBTheory(selectedItem);
    theory.setDepth(-1);
    theory.setDegree(-1);
    theory.setCategoryId(-1);
    dispatch(trashedItem({ selectedItem: theory }));
  };

  const submitHandler = () => {
    if (trashedItems.length + assignedItems.length < answers.length) {
      alert('모든 답변이 배치되지 않았습니다.');
      return;
    }
    httpInterface
      .postAnswersAndGetNewAnswers(accessToken, {
        subQuestionId,
        batchAnswer: convertBatchAnswer(assignedItems, trashedItems),
      })
      .then((res) => {
        console.log(res, res.status);
        if (res.status === 204) {
          return true;
        } else if (res.status === 200) {
          alert('모든 답변이 배치되었습니다.');
        }
        // res.status
      })
      .then((res) => {
        if (res) {
          httpInterface
            .getFiveAnswers(accessToken, { params: { taskId } })
            .then((res) => {
              const { data } = res.data;
              if (data.length > 0) {
                setAnswers(data);
                dispatch(refreshStateWhenGetNewData());
              }
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className={styles['btn-container']}>
      <button className={`${styles.btn}`} onClick={submitHandler}>
        <span>제출하기</span>
        <span>(배치완료)</span>
      </button>
      <button className={`${styles.btn}`} onClick={throwingDataHandler}>
        <span>휴지통</span>
        <span>(배치하지 않음)</span>
      </button>
    </div>
  );
}

function convertBatchAnswer(assignedItems, trashedItems) {
  const assignedAnswers = assignedItems.map((item) => ({
    psAnswerId: item.getId(),
    answerCategory: item.getCategoryId(),
    degree: item.getDegree(),
    depth: item.getDepth(),
  }));

  const trashedAnswers = trashedItems.map((item) => ({
    psAnswerId: item.getId(),
    answerCategory: item.getCategoryId(),
    degree: item.getDegree(),
    depth: item.getDepth(),
  }));

  return [...assignedAnswers, ...trashedAnswers];
}
