import React, { forwardRef } from "react";
import ConditionInformation from "./ConditionInformation";
import { useLocation } from "react-router-dom";

export default function DetailQuestion({ isPc }) {
  let location = useLocation();
  // const detailQuestion = location.state.data?.subQuestionBody.split(".")[1];

  return (
    <>
      <div
        style={{
          fontWeight: "bold",
          backgroundColor: "#D9D9D9",
          borderRadius: "8px",
          padding: "1%",
        }}
      >
        <div
          style={{
            backgroundColor: "#3D5398",
            color: "white",
            borderRadius: "26px",
            padding: "1%",
            width: isPc ? "15%" : "10vw",
            display: "inline-block",
            textAlign: "center",
            marginRight: "2%",
          }}
        >
          질문
        </div>
        {location.state.data?.subQuestionBody}
      </div>
      <ConditionInformation />
      <hr />
    </>
  );
}
