import * as d3 from '../../../MindMap/js/d3';

export const initGraphColor = d3.scaleOrdinal([
  '#F2F2F2',
  '#E2E2E2',
  '#D0D0D0',
  '#C3C3C3',
  '#ADADAD',
]);
// const color1 = d3.scaleOrdinal([
//   '#FFFFFF',
//   '#FFFFFF',
//   '#FFFFFF',
//   '#FFFFFF',
//   '#FFFFFF',
// ]);
// const color2 = d3.scaleOrdinal([
//   '#EEEEEE',
//   '#EEEEEE',
//   '#EEEEEE',
//   '#EEEEEE',
//   '#EEEEEE',
// ]);
// const color3 = d3.scaleOrdinal([
//   '#DDDDDD',
//   '#DDDDDD',
//   '#DDDDDD',
//   '#DDDDDD',
//   '#DDDDDD',
// ]);
// const color4 = d3.scaleOrdinal([
//   '#CCCCCC',
//   '#CCCCCC',
//   '#CCCCCC',
//   '#CCCCCC',
//   '#CCCCCC',
// ]);
// const color5 = d3.scaleOrdinal([
//   '#BBBBBB',
//   '#BBBBBB',
//   '#BBBBBB',
//   '#BBBBBB',
//   '#BBBBBB',
// ]);

// export const gradientColor = [color1, color2, color3, color4, color5];
const color1 = d3.scaleOrdinal(['#459ADC', '#EA9B0E', '#6EBD3D', '#D660FF', '#3a4019']);
const color2 = d3.scaleOrdinal(['#4E9FDE', '#ECA326', '#84CC57', '#DE80FF', '#7d8936']);
const color3 = d3.scaleOrdinal(['#62AAE1', '#EDAB39', '#9BE36E', '#E28EFF', '#bac670']);
const color4 = d3.scaleOrdinal(['#94C6EC', '#F3C676', '#B6F192', '#E7A2FF', '#d7dead']);
const color5 = d3.scaleOrdinal(['#C9E2F5', '#F9E1B8', '#DBFAC8', '#F2CEFF', '#f5f6ea']);
export const gradientColor = [color5, color4, color3, color2, color1];
