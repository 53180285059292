import React, { useState } from 'react';

import * as xlsx from 'xlsx';
import { useDispatch, useSelector } from 'react-redux';
import { setXlsxData } from '../../../redux/slice/theory/xlsxCreateTheory';
import { useLocation } from 'react-router-dom';
import { useAxiosContext } from '../../../context/AxiosContainer';
import {
  signOut,
  updateAccessToken,
} from '../../../redux/slice/login/loginInfo';
import { classification, convertTheoriesForPost } from './xlsxInputUtils';
import styles from './XlsxInput.module.css';

export default function XlsxInput() {
  const dispatch = useDispatch();
  const { state: subQuestionId } = useLocation();
  const { accessToken } = useSelector((state) => state.loginInfo);
  const { httpInterface } = useAxiosContext();
  const { theoryList, categoryList } = useSelector((state) => state.xlsxTheory);
  const [canSubmit, setCanSubmit] = useState(true);

  const fileUploadHandler = (e) => {
    const input = e.target;
    const reader = new FileReader();
    reader.onload = function () {
      const data = reader.result;
      const workBook = xlsx.read(data, { type: 'binary' });

      const sheetName = workBook.SheetNames[0];
      const rows = xlsx.utils.sheet_to_json(workBook.Sheets[sheetName]);
      const xlsxData = classification(rows);
      console.log(xlsxData);
      dispatch(
        setXlsxData({
          xlsxCategory: xlsxData.category,
          xlsxTheory: xlsxData.theory,
        })
      );
    };
    reader.readAsBinaryString(input.files[0]);
  };

  const submitHandler = (e) => {
    if (!theoryList.length) return;
    if (!categoryList.length) return;

    setCanSubmit(false);
    e.preventDefault();
    httpInterface
      .postTheoryData(accessToken, {
        subQuestionId,
        defaultBatch: convertTheoriesForPost(categoryList, theoryList),
      })
      .then((res) => {
        console.log(res);
        alert('생성이 완료되었습니다!');
      })
      .catch((error) => {
        const { status } = error.response;
        console.log('Test Component Error', error);
        if (status === 401) {
          // reissue Token 관련 코드 작성할 것
          httpInterface
            .reissueToken(accessToken)
            .then((response) => {
              const accessToken = response.data.data[0].accessToken;
              httpInterface.postTheoryData(accessToken, {
                subQuestionId,
                defaultBatch: convertTheoriesForPost(categoryList, theoryList),
              });
              dispatch(
                updateAccessToken({
                  accessToken,
                })
              );
            })
            .catch((error) => {
              console.log('Refresh Token Error');
              alert('유효정보가 만료되어 로그아웃되었습니다.');
              dispatch(signOut());
            });
        } else {
          console.log('Validation Token Error');
          alert('유효정보가 만료되어 로그아웃되었습니다.');
          dispatch(signOut());
        }
      })
      .finally(() => {
        setCanSubmit(true);
      });
    console.log(convertTheoriesForPost(categoryList, theoryList));
  };

  return (
    <section>
      <input
        className={styles['file-input']}
        type='file'
        onChange={fileUploadHandler}
      />
      <button
        className={styles.button}
        onClick={submitHandler}
        disabled={!canSubmit}
      >
        제출
      </button>
    </section>
  );
}
