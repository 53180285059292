import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import styles from "../../styles/ManagementResearcher.module.css";

export default function ResearcherList({ data }) {
  useEffect(() => {}, [data]);
  return (
    <>
      <thead style={{ textAlign: "center", backgroundColor: "#F1F1F1" }}>
        <tr style={{ textAlign: "center", backgroundColor: "#F1F1F1" }}>
          <th style={{ textAlign: "center", backgroundColor: "#F1F1F1" }}>
            유형
          </th>
          <th>회원번호</th>
          <th>이메일</th>
          <th>이름</th>
          <th>전화번호</th>
          <th>담당업무</th>
          <th>수행이력</th>
          <th>회원관리</th>
        </tr>
      </thead>

      {data.length != 0 &&
        data.map((a, i) => {
          return (
            <>
              <tbody key={i}>
                <tr className={styles.QuizTable}>
                  <td>일반</td>
                  <td>2</td>
                  <td>{a.email}</td>
                  <td>{a.name}</td>
                  <td>010-0000-0000</td>
                  <td style={{ width: "40%", textAlign: "center" }}>
                    <Button
                      className={
                        a.assignedTask.ps
                          ? styles.TaskToggleBtnOn
                          : styles.TaskToggleBtn
                      }
                    >
                      테스트 풀기
                    </Button>
                    <Button
                      className={
                        a.assignedTask.createPs
                          ? styles.TaskToggleBtnOn
                          : styles.TaskToggleBtn
                      }
                    >
                      케이스 생성
                    </Button>
                    <Button
                      className={
                        a.assignedTask.batch
                          ? styles.TaskToggleBtnOn
                          : styles.TaskToggleBtn
                      }
                    >
                      좌표 배치
                    </Button>
                    <Button
                      className={
                        a.assignedTask.createBatch
                          ? styles.TaskToggleBtnOn
                          : styles.TaskToggleBtn
                      }
                    >
                      좌표 생성
                    </Button>
                  </td>
                  <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                    보기
                  </td>
                  <td style={{ textAlign: "center" }}>
                    수정
                    <br />
                    삭제
                  </td>
                </tr>
              </tbody>
            </>
          );
        })}
    </>
  );
}
