import { isEmptyObj } from '../common/common';

export class TheoryCalculator {
  #circleAngle = 360;
  #width = window.innerWidth * 0.7;
  #height = window.innerHeight * 0.9;
  #degreeToPi = Math.PI / 180;
  #piToDegree = 180 / Math.PI;
  #outerRadius =
    this.#width > this.#height ? this.#height / 2 : this.#width / 2;
  #arcDepth = 5;
  #radiusUnit = this.#outerRadius / this.#arcDepth;
  constructor() {
    console.log(this.#width, this.#height, this.#outerRadius);
  }

  calcStartAngle(categoryId, categoryCount) {
    return (this.#circleAngle * (categoryId - 1)) / categoryCount;
  }
  calcEndAnlge(categoryId, categoryCount) {
    return (this.#circleAngle * categoryId) / categoryCount;
  }

  calcAngles(categoryId, categoryCount) {
    return {
      startAngle: this.calcStartAngle(categoryId, categoryCount),
      endAngle: this.calcEndAnlge(categoryId, categoryCount),
    };
  }

  calculateCategoryScreenCoordByCircleCoord(category) {
    const { depth, degree } = category.getCircleCoord();
    if (depth && (depth < 0 || depth > 100))
      throw new Error('Out of depth range error');
    if (degree && (degree < 0 || degree > 100))
      throw new Error('Out of degree range error');

    const { startAngle, endAngle } = category.getAngles();
    const gradient = this.#calculateGradient(startAngle, endAngle, degree);

    const radian = this.#getRadianByGradient(gradient);
    const outerRadius = this.#outerRadius;
    const spotDepth = outerRadius * (depth / 100);
    const screenCoord = this.#calculateSpaceCoordByRadianAndRadius(
      radian,
      spotDepth
    );
    return screenCoord;
  }

  calculateTheoryScreenCoordByCircleCoord(theory, categoryList) {
    const { degree, depth } = theory.getCircleCoord();
    const categoryId = parseInt(theory.getCategoryId());
    const category = categoryList?.find(
      (cat) => cat.getCategoryId() === categoryId
    );
    const { startAngle, endAngle } = category?.getAngles();
    const gradient = this.#calculateGradient(startAngle, endAngle, degree);
    const radian = this.#getRadianByGradient(gradient);
    // const lengthRange = outerRadius - radius;
    // const spotDepth = radius + lengthRange * (depth / 100);
    const spotDepth = this.#outerRadius * (depth / 100);
    const coord = this.#calculateSpaceCoordByRadianAndRadius(radian, spotDepth);
    return coord;
  }

  getScreenCoordByDegreeAndDepth(category, degree, depth) {
    const { startAngle, endAngle } = category.getAngles();
    const gradient = this.#calculateGradient(startAngle, endAngle, degree);
    const radian = this.#getRadianByGradient(gradient);
    // const lengthRange = outerRadius - radius;
    // const spotDepth = radius + lengthRange * (depth / 100);
    const spotDepth = this.#outerRadius * (depth / 100);
    const coord = this.#calculateSpaceCoordByRadianAndRadius(radian, spotDepth);
    return coord;
  }

  #calculateGradient(startAngle, endAngle, degree) {
    return startAngle + (endAngle - startAngle) * (degree / 100);
  }
  #getRadianByGradient(gradient) {
    const radian = (gradient - 90) * this.#degreeToPi;
    return radian;
  }

  #calculateSpaceCoordByRadianAndRadius(radian, radius) {
    const x = radius * Math.cos(radian);
    const y = radius * Math.sin(radian);
    return { x, y };
  }

  getClickCoordInfo(coord, categoryList) {
    const categoryId = this.getCategoryIdByClickCoord(coord, categoryList);
    const category = categoryList.find(
      (cat) => cat.getCategoryId() === categoryId
    );
    const depth = this.calculateDepth(coord);
    const degree = this.caluclateDegree(coord, category);
    const screenCoord = this.getScreenCoordByDegreeAndDepth(
      category,
      degree,
      depth
    );

    return {
      categoryId,
      depth,
      degree,
      screenCoord,
    };
  }

  calculateDistance(coord) {
    return Math.sqrt(coord.x ** 2 + coord.y ** 2);
  }

  calculateDepth(coord) {
    return (this.calculateDistance(coord) / this.#outerRadius) * 100;
  }
  caluclateDegree(coord, category) {
    const { startAngle, endAngle } = category.getAngles();
    const standardAngle = endAngle - startAngle;
    const spotAngle = this.getAngleByScreenSystem(coord) - startAngle;
    const degree = (spotAngle / standardAngle) * 100;
    return degree;
  }

  calcCategoryAngles(cur, acc, total) {
    const startAngle = (acc / total) * 360;
    const endAngle = ((cur + acc) / total) * 360;
    return {
      startAngle,
      endAngle,
    };
  }

  getCategoryIdByClickCoord(coord, categoryList) {
    const isInMyArea = (coord, category) => {
      const { startAngle, endAngle } = category.getAngles();
      const angle = this.getAngleByScreenSystem(coord);
      if (angle >= startAngle && angle <= endAngle) {
        return true;
      }
      return false;
    };
    for (const category of categoryList) {
      if (isInMyArea(coord, category)) return category.getCategoryId();
    }
  }

  getAngleByScreenSystem(coord) {
    return -Math.atan2(coord.x, coord.y) * this.#piToDegree + 180;
  }
}

export class RealTimeCalculator {
  #circleAngle = 360;
  #degreeToPi = Math.PI / 180;
  #height = window.innerHeight * 0.5;
  #width = window.innerWidth;
  #outerRadius = this.#height / 2;

  isExistTheoryManager(theoryManager) {
    if (!theoryManager) return false;
    if (theoryManager && isEmptyObj(theoryManager)) return false;
    return true;
  }

  calculateScreenCoordByCircleCoord(theoryManager, theoryCanvas) {
    const theories = theoryManager?.getTheoryList();

    if (!theories || !theories?.length) return;

    for (const theory of theories) {
      const { depth, degree } = theory?.getCircleCoord();
      if (depth && (depth < 0 || depth > 100))
        throw new Error('Out of depth range error');
      if (degree && (degree < 0 || degree > 100))
        throw new Error('Out of degree range error');
      const { startAngle, endAngle } = this.getAngleRangeByCategoryId(
        theory?.getCategoryId(),
        theoryManager.getMaxCategoryId()
      );
      const gradient = this.#calculateGradient(startAngle, endAngle, degree);

      const radian = this.#getRadianByGradient(gradient);
      const outerRadius = theoryCanvas?.getOuterRadius();
      const radiusUnit = theoryCanvas?.getRadiusUnit();
      const spotRange = outerRadius - radiusUnit;
      const spotDepth = radiusUnit + spotRange * (depth / 100);
      const screenCoord = this.#calculateSpaceCoordByRadianAndRadius(
        radian,
        spotDepth
      );
      theory.setScreenCoord(screenCoord);
    }
  }

  getAngleRangeByCategoryId(theoryCategoryId, maxCategoryId) {
    return {
      startAngle: this.#calculateStartAngle(theoryCategoryId, maxCategoryId),
      endAngle: this.#calculateEndAngle(theoryCategoryId, maxCategoryId),
    };
  }
  #calculateStartAngle(theoryCategoryId, maxCategoryId) {
    const categoryCount = maxCategoryId + 1;
    return (this.#circleAngle * theoryCategoryId) / categoryCount;
  }

  #calculateEndAngle(theoryCategoryId, maxCategoryId) {
    const categoryCount = maxCategoryId + 1;
    return (this.#circleAngle * (theoryCategoryId + 1)) / categoryCount;
  }

  #calculateGradient(startAngle, endAngle, degree) {
    return startAngle + (endAngle - startAngle) * (degree / 100);
  }

  #getRadianByGradient(gradient) {
    const radian = (gradient - 90) * this.#degreeToPi;
    return radian;
  }
  #calculateSpaceCoordByRadianAndRadius(radian, radius) {
    const x = radius * Math.cos(radian);
    const y = radius * Math.sin(radian);
    return { x, y };
  }

  calculationCenterCoord(theoryManager) {
    const defaultCenterCoord = { x: this.#width / 2, y: this.#height / 2 };
    if (!this.isExistTheoryManager(theoryManager)) {
      return defaultCenterCoord;
    }

    const theoryList = theoryManager?.getTheoryList();
    const theoryCount = theoryList?.length;
    if (!theoryCount) return defaultCenterCoord;

    let centerX = 0;
    let centerY = 0;

    for (const theory of theoryList) {
      const { x, y } = theory?.getScreenCoord();
      centerX += x;
      centerY += y;
    }

    centerX = centerX / theoryCount;
    centerY = centerY / theoryCount;

    if (centerX === 0 || centerY === 0) {
      return defaultCenterCoord;
    } else {
      return { x: centerX, y: centerY };
    }
  }

  getMaxiumDistanceByCoords(theoryManager) {
    if (!this.isExistTheoryManager(theoryManager)) return this.#width;

    const theoryList = theoryManager?.getTheoryList();
    const theoryCount = theoryList?.length;
    if (!theoryCount) return this.#width;
    if (theoryCount === 1) return this.#width / 2;

    const distanceList = [];
    for (let i = 0; i < theoryCount - 1; ++i) {
      for (let j = i + 1; j < theoryCount; ++j) {
        const distance = this.#calculationDistance(
          theoryList[i],
          theoryList[j]
        );

        distanceList.push(distance);
      }
    }
    const maxDist = Math.max(...distanceList);
    return maxDist;
  }

  getMaxiumXYDistance(theoryManager, centerCoord) {
    if (!this.isExistTheoryManager(theoryManager)) return this.#width;

    const theoryList = theoryManager?.getTheoryList();
    const theoryCount = theoryList?.length;
    if (!theoryCount) return this.#width;
    if (theoryCount === 1) return { x: this.#width / 5, y: this.#height / 5 };

    const xDirections = [];
    const yDirections = [];
    for (let i = 0; i < theoryCount; ++i) {
      // const dist = this.#calculationDistance(
      //   centerCoord,
      //   theoryList[i].getScreenCoord()
      // );
      const { x: x1, y: y1 } = theoryList[i].getScreenCoord();
      const { x: x2, y: y2 } = centerCoord;
      xDirections.push(Math.abs(x2 - x1));
      yDirections.push(Math.abs(y2 - y1));
    }

    const padding = 15; // 약간의 여백을 위함
    const maxX = Math.max(...xDirections);
    const maxY = Math.max(...yDirections);
    return { x: maxX + padding, y: maxY + padding };
  }

  #calculationDistance(theory1, theory2) {
    const { x: x1, y: y1 } = theory1.getScreenCoord();
    const { x: x2, y: y2 } = theory2.getScreenCoord();

    return Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
  }

  calculateWindowCoordBySpaceCoord(spaceCoord) {
    const windowCoord = {
      x: spaceCoord.x + this.#width / 2,
      y: spaceCoord.y + this.#height / 2,
    };

    return windowCoord;
  }
}
