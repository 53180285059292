import React from "react";
import Form from "react-bootstrap/Form";
import styles from "../../styles/Admin.module.css";

export default function TableHeader() {
  return (
    <header className={`${styles["grid-design"]} ${styles["table-header"]}`}>
      <div>
        <Form.Check aria-label="option 1" />
      </div>
      <div>유형</div>
      <div>난이도</div>
      <div>케이스명</div>
      <div colSpan={3}>질문</div>
      <div>현황</div>
      <div>가설좌표</div>
      <div>답변재배치</div>
      <div>분석결과지</div>
    </header>
  );
}
