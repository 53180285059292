export class Theory {
  #id;
  #degree;
  #depth;
  #text;
  #categoryId; // 일단은 0~2
  #screenCoord;

  constructor(id, text, degree, depth) {
    this.#id = id;
    this.#degree = degree;
    this.#depth = depth;
    this.#text = text;
  }

  setDegree(degree) {
    if (degree > 100 || degree < 0) {
      throw new Error(`Out Of Range Error`);
    }
    this.#degree = degree;
  }

  getDegree() {
    return this.#degree;
  }

  setDepth(depth) {
    if (depth > 100 || depth < 0) {
      throw new Error(`Out Of Range Error`);
    }
    this.#depth = depth;
  }

  getDepth() {
    return this.#depth;
  }

  setText(text) {
    this.#text = text;
  }
  getText() {
    return this.#text;
  }

  getId() {
    return this.#id;
  }
  setId(id) {
    this.#id = id;
  }
  setCategoryId(categoryId) {
    this.#categoryId = categoryId;
  }

  getCategoryId() {
    return this.#categoryId;
  }

  setScreenCoord(screenCoord) {
    this.#screenCoord = screenCoord;
  }

  getScreenCoord() {
    return this.#screenCoord;
  }

  getCircleCoord() {
    return {
      degree: this.#degree,
      depth: this.#depth,
    };
  }
}

export class DBTheory {
  #id;
  #degree;
  #depth;
  #text;
  #categoryId; // 일단은 0~2
  #screenCoord;

  constructor(dbTheory) {
    this.#id = dbTheory.answerCategory
      ? dbTheory.answerCategory + dbTheory.degree + dbTheory.depth
      : dbTheory.psAnswerId;
    this.#categoryId = dbTheory?.answerCategory;
    this.#text = dbTheory?.text;
    this.#depth = dbTheory?.depth;
    this.#degree = dbTheory?.degree;
  }

  getId() {
    return this.#id;
  }

  getCategoryId() {
    return this.#categoryId;
  }

  setCategoryId(categoryId) {
    this.#categoryId = categoryId;
  }

  getDegree() {
    return this.#degree;
  }

  setDegree(degree) {
    this.#degree = degree;
  }

  getDepth() {
    return this.#depth;
  }
  setDepth(depth) {
    this.#depth = depth;
  }

  getText() {
    return this.#text;
  }

  setScreenCoord(screenCoord) {
    this.#screenCoord = screenCoord;
  }

  getScreenCoord() {
    return this.#screenCoord;
  }

  getCircleCoord() {
    return {
      degree: this.#degree,
      depth: this.#depth,
    };
  }
}
