import React from "react";
import Button from "react-bootstrap/Button";
import styles from "../styles/App.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import LoginLogo from "../components/login/LoginLogo";
import { useMediaQuery } from "react-responsive";

export default function SignUpSendEmail() {
  let navigate = useNavigate();
  let location = useLocation();
  const isPc = useMediaQuery({
    query: "(min-width:768px)",
  });
  return (
    <div
      className={isPc ? styles.AppPc : styles.App}
      style={{ textAlign: "center" }}
    >
      <LoginLogo />
      <div
        style={{ marginTop: "20%", fontSize: "1.5em", fontWeight: "bolder" }}
      >
        회원가입 인증 메일이
        <br /> 발송되었습니다.
      </div>
      <div
        style={{
          marginTop: "30%",
          fontSize: "1.3em",
          fontWeight: "500",
          lineHeight: "20px",
        }}
      >
        <span>
          {location.state.email}로<br /> 전송된 인증 메일을 확인해 주세요.
        </span>
      </div>
      <div style={{ marginTop: "3%", marginBottom: "10%", fontSize: "1em" }}>
        <span>1일 내 인증하셔야 회원가입이 완료 됩니다.</span>
      </div>
      <Button
        onClick={() => {
          navigate("/");
        }}
        className={isPc ? styles.SignOnBtnPc : styles.SignOnBtn}
      >
        로그인
      </Button>
    </div>
  );
}
