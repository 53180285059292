export const sliderSetting = {
  // slide: 'div',
  arrows: false,
  dots: true,
  infinite: false,
  speed: 600,
  slidesToShow: 1,
  slidesToScroll: 1,
  // initialSlide: 0,
  autoplay: false,
  centerMode: true,
  centerPadding: "50px",
};
