import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import styles from "../../../styles/MainResearcher.module.css";
import Table from "react-bootstrap/Table";
import { useSelector } from "react-redux";
import CreateEditTheoryTableRow from "./CreateEditTheoryTableRow";

export default function CreateEditTest() {
  let no = true;
  const { taskInfo } = useSelector((state) => state.taskInfo);

  return (
    <>
      <div className={styles.Content}>
        <span>가설좌표 생성/수정</span>
        <Button className={styles.ExamBtn} onClick={() => {}}>
          예시
        </Button>
      </div>
      <Table hover>
        <thead>
          <tr>
            <th>유형</th>
            <th>난이도</th>
            <th>케이스명</th>
            <th>질문</th>
            <th>데드라인</th>
            <th>상태</th>
            <th style={{ width: "15%" }}> </th>
          </tr>
        </thead>
        <tbody>
          {taskInfo &&
            taskInfo?.map((task, idx) => {
              if (taskInfo[idx].type === "CREATE_BATCH") {
                no = false;
                return (
                  <CreateEditTheoryTableRow
                    key={task.deadLine + idx}
                    taskInfo={task}
                  />
                );
              }
            })}
        </tbody>
      </Table>
      {no && <span>업무가 없습니다</span>}
    </>
  );
}
