import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import AdminHeader from "./components/common/header/AdminHeader";
import Header from "./components/common/header/Header";
import { userTypes } from "./key/userTypes";
import { useSelector } from "react-redux";

const testPagePath = "/StartTest";
const createCasePagePath = "/Admin/createCaseA";

function App() {
  let navigate = useNavigate();
  const { admin } = userTypes;
  const { pathname } = useLocation();
  const { isLogin, roleType } = useSelector((state) => state.loginInfo);
  const [isInTestPage, setIsInTestPage] = useState(pathname === "/StartTest");
  const [isInCreateCasePage, setIsInCreateCasePage] = useState(
    pathname === "/Admin/createCaseA"
  );
  

  useEffect(() => {
    setIsInTestPage(pathname === testPagePath);
    setIsInCreateCasePage(pathname === createCasePagePath);
    pathname.includes("ChangePassword/") && navigate(pathname);
  }, [pathname]);

  return (
    <>
      {isLogin && roleType !== admin && <Header isInTestPage={isInTestPage} />}
      {isLogin && roleType === admin && (
        <AdminHeader isInCreateCasePage={isInCreateCasePage} />
      )}
      <Outlet />
    </>
  );
}

export default App;
