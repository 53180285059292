import React, { useState, useEffect } from "react";
import styles from "../styles/ManagementResearcher.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import DropdownBtn from "../components/managementMember/Dropdown";
import Table from "react-bootstrap/Table";
import { useSelector } from "react-redux";
import ResearcherList from "../components/managementMember/ResearcherList";
import { useAxiosContext } from "../context/AxiosContainer";
import Pagination from "react-bootstrap/Pagination";

export default function ManagementResearcher() {
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  let location = useLocation();
  console.log(location.state);

  const { httpInterface } = useAxiosContext();
  const { accessToken } = useSelector((state) => state.loginInfo);

  let items = [];
  // pagination
  const [activeBtn, setActiveBtn] = useState(0);
  const [page, setPage] = useState();

  for (let i = 0; i < page; i++) {
    items.push(
      <Pagination.Item
        key={i}
        onClick={() => {
          setActiveBtn(i);
        }}
        active={i === activeBtn}
      >
        {i + 1}
      </Pagination.Item>
    );
  }

  useEffect(() => {
    httpInterface
      .getResearcherList(accessToken, {
        page: activeBtn,
        size: 10,
        sort: "user.createdAt,desc",
      })
      .then(async (res) => {
        console.log(res);
        await setData(res.data.data.content);
        await setPage(res.data.data.totalPages);
      })
      .then(() => {
        return httpInterface.getFilterValueData(accessToken);
      })
      .catch((error) => {
        console.log("error : ", error.response);
      });
  }, [activeBtn]);

  return (
    <>
      <div className={styles.Container}>
        <span style={{ marginRight: "2%" }}>
          <b>회원관리</b>
        </span>
        <span
          onClick={() => {
            navigate("/Admin/managementMember");
          }}
          style={{ marginRight: "1%" }}
        >
          일반
        </span>
        <span
          onClick={() => {
            navigate("/Admin/managementResearcher");
          }}
        >
          <b>
            <ins>연구원</ins>
          </b>
        </span>

        <div className={styles.MenuWrap}>
          <DropdownBtn />

          <input type="search" className={styles.Search} />

          <Button className={styles.SearchBtn}>검색</Button>
          <Button className={styles.AddMemberBtn}>+신규 회원 생성</Button>
          <Button
            className={styles.TaskBtn}
            onClick={() => {
              console.log(data);
              data && navigate("/Admin/addTaskResearcher");
            }}
          >
            +업무배정
          </Button>
        </div>

        <Table hover style={{ marginTop: "3%" }}>
          <ResearcherList data={data} />
        </Table>
      </div>
      <Pagination>{items}</Pagination>
    </>
  );
}
