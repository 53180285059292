import React from "react";
import styles from "../../../styles/CreateCase.module.css";

export default function Question4({
  idx,
  temporaryStorage,
  inputCopy,
  setInputText,
}) {
  return (
    <div className={styles.ChoiceTask}>
      <div className={styles.DivBox}>
        <span>질문 4</span>
      </div>
      <div className={styles.Test}>
        <input
          key={idx}
          className={styles.Answer}
          name="text"
          value={
            temporaryStorage?.[idx]?.subQuestionBodyList[3]
              ? temporaryStorage[idx]?.subQuestionBodyList[3]
              : inputCopy[idx]?.question4
          }
          autoComplete="off"
          onChange={(e) => {
            temporaryStorage?.[idx]?.subQuestionBodyList[3]
              ? (temporaryStorage[idx].subQuestionBodyList[3] = e.target.value)
              : (inputCopy[idx].question4 = e.target.value);
            setInputText(inputCopy);
          }}
        />
      </div>
    </div>
  );
}
