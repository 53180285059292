import React, { useRef, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import SideBar from "./SideBar";
import Container from "react-bootstrap/esm/Container";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { userTypes } from "../../../key/userTypes";
import { useAxiosContext } from "../../../context/AxiosContainer";
import {
  signOut,
  updateAccessToken,
} from "../../../redux/slice/login/loginInfo";
import styles from "../../../styles/Header.module.css";
import { RxPerson } from "react-icons/rx";
import { IoEllipsisHorizontalCircle } from "react-icons/io5";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";

export default function Header({ isInTestPage }) {
  const headerRef = useRef();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { httpInterface } = useAxiosContext();
  const [isShowSidebar, setIsShowSidebar] = useState(false);
  const { roleType, accessToken } = useSelector((state) => state.loginInfo);

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Navbar
        className={
          roleType == "USER" ? styles.HeaderWrapUser : styles.HeaderWrap
        }
        ref={headerRef}
      >
        <Container>
          <Navbar.Brand
            className={styles.ListTitle}
            onClick={() => {
              if (isInTestPage) {
                // 모달 뜨도록
                setModalOpen(true);
              } else {
                if (roleType === userTypes.researcher) {
                  navigate("/MainR");
                } else {
                  navigate("/Main");
                }
              }
            }}
          >
            RTW
          </Navbar.Brand>
          <Nav className={`${styles["nav-wrap"]}`}>
            <span
              className={styles.List}
              onClick={() => {
                if (isInTestPage) {
                  // modal 보여주기
                } else {
                }
              }}
            >
              {roleType === userTypes.researcher && t("solveQuiz")}
            </span>
            <span className={styles.List} href="/RTW_Client/Main">
              {roleType === userTypes.researcher && "제안/수정하기"}
            </span>
            <span
              className={styles.List}
              onClick={() => {
                setIsShowSidebar(true);
              }}
            >
              <IoEllipsisHorizontalCircle
                style={{ width: "50px", height: "25px" }}
              />
            </span>
          </Nav>
        </Container>
      </Navbar>
      {isShowSidebar && (
        <SideBar
          setIsShowSidebar={setIsShowSidebar}
          isShowSidebar={isShowSidebar}
        />
      )}

      <Modal
        show={modalOpen}
        onHide={() => setModalOpen(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <span>
            현재 테스트 진행 중입니다.
            <br />
            테스트를 종료하고 이동하시겠어요?
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setModalOpen(false);
            }}
          >
            취소
          </Button>
          <Button
            onClick={() => {
              navigate("/Main");
              setModalOpen(false);
            }}
          >
            종료
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
