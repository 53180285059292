import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  mode: 'assign', // assign, modify, divide, delete
  selectedAnswerId: '',
  assignList: [],
  modifyList: [],
  deleteList: [],
  divideList: [],
};
const adminReassignSlice = createSlice({
  name: 'adminReassign',
  initialState,
  reducers: {
    clearReassignState: (state) => {
      state = initialState;
    },
    selectAnswerId: (state, action) => {
      state.selectedAnswerId = action.payload.psAnswerId;
    },
    changeMode: (state, action) => {
      state.mode = action.payload.mode;
    },
  },
});

export const { clearReassignState, selectAnswerId, changeMode } =
  adminReassignSlice.actions;

export default adminReassignSlice.reducer;
