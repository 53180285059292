import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import styles from "../../styles/ResearcherTask.module.css";
import { useState } from "react";

export default function DropdownTask({ taskType, setTaskType, researcherTF }) {
  const [dropdown, setDropdown] = useState("PS");

  return (
    <Dropdown as={ButtonGroup} className={styles.Dropdown}>
      <Dropdown.Toggle
        id="dropdown-split-basic"
        disabled={researcherTF ? false : true}
        className={styles.Dropdown}
      >
        {dropdown}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {taskType.map((a, i) => {
          return (
            <>
              <Dropdown.Item
                className={styles.Dropdown}
                eventKey={i + 1}
                onClick={() => {
                  setDropdown(taskType[i]);
                  setTaskType(taskType[i]);
                }}
              >
                {taskType[i]}
              </Dropdown.Item>
            </>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}
