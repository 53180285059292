import { createSlice } from "@reduxjs/toolkit";
import { userTypes } from "../../../key/userTypes";

export const loginInfoSlice = createSlice({
  name: "loginInfo",
  initialState: {
    isLogin: false,
    roleType: userTypes.none,
    email: null,
    accessToken: null,
    language: null,
  },
  reducers: {
    signIn: (state, action) => {
      state.isLogin = true;
      state.roleType = action.payload.roleType;
      state.email = action.payload.email;
      state.language = action.payload?.language;
      state.accessToken = action.payload.accessToken;
    },
    signOut: (state) => {
      sessionStorage.removeItem("persist:root");
      sessionStorage.removeItem("refreshToken");
      state.isLogin = false;
      state.roleType = userTypes.none;
      state.email = null;
      state.accessToken = null;
      state.language = null;
    },
    updateAccessToken: (state, action) => {
      state.accessToken = action.payload.accessToken;
    },
  },
});

// Action creators are generated for each case reducer function
export const { signIn, signOut, updateAccessToken } = loginInfoSlice.actions;
export default loginInfoSlice.reducer;
