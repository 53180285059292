import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeMode,
  initializeCurrentTarget,
} from '../../../../redux/slice/theory/createTheory';
import { theoryMode } from '../../../../key/theoryMode';
import styles from './ButtonList.module.css';

export default function ButtonList() {
  const { mode } = useSelector((state) => state.createTheory);
  const dispatch = useDispatch();
  return (
    <div>
      <button
        className={`${mode === theoryMode.modify ? styles.selected : ''} ${
          styles.btn
        }`}
        onClick={() => {
          console.log(mode);
          if (mode === theoryMode.modify) {
            dispatch(changeMode({ mode: theoryMode.createTheory }));
          } else {
            dispatch(changeMode({ mode: theoryMode.modify }));
          }
          dispatch(initializeCurrentTarget());
        }}
      >
        수정
      </button>
      <button
        className={`${mode === theoryMode.delete ? styles.selected : ''} ${
          styles.btn
        }`}
        onClick={() => {
          if (mode === theoryMode.delete) {
            dispatch(changeMode({ mode: theoryMode.createTheory }));
          } else {
            dispatch(changeMode({ mode: theoryMode.delete }));
          }
          dispatch(initializeCurrentTarget());
        }}
      >
        삭제
      </button>
      {mode === theoryMode.move && '좌표이동 모드'}
    </div>
  );
}
