import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../../styles/InfoTest.module.css";

export default function Intro() {
  let navigate = useNavigate();

  return (
    <>
      <div className={styles.TestWrap}>
        <div className={styles.Test}>
          <div className={styles.TestStepWrap}>
            <div className={styles.TestStepNow}>안내</div>
            <div className={styles.TestStep}>진행</div>
            <div className={styles.TestStep}>종료</div>
          </div>
          <div className={styles.TextWrap}>
            <h5>가설 좌표 : 3개 / 제한 시간 : 없음</h5>
            <div className={styles.Text}>
              <p>[테스트] 버튼을 클릭해 테스트를 진행하거나,</p>
              <p>[시작] 버튼을 클릭해 가설 좌표 배치를 진행해주세요.</p>
            </div>
          </div>
          <div
            style={{
              textAlign: "center",
            }}
          >
            {/* <button
              className={styles.StartBtn}
              // onClick={() => navigate("/TutorialB")}
            >
              <b>테스트</b>
            </button>
            <button
              className={styles.StartBtn}
              // onClick={() => navigate("/DeploymentTest")}
            >
              <b>시작</b>
            </button> */}
            <button
              className={styles.StartBtn}
              onClick={() => navigate("/ManageCanvas3")}
            >
              <b>3분할 CANVAS</b>
            </button>
            <button
              className={styles.StartBtn}
              onClick={() => navigate("/ManageCanvas")}
            >
              <b>4분할 CANVAS</b>
            </button>
            <button
              className={styles.StartBtn}
              onClick={() => navigate("/ManageCanvas_polar")}
            >
              <b>극좌표로 시작 CANVAS</b>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
