import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import styles from "../../styles/ResearcherTask.module.css";
import { useState, useEffect } from "react";
import { useAxiosContext } from "../../context/AxiosContainer";
import { useSelector } from "react-redux";

export default function DropdownLevel({
  setLevelChoose,
  researcherTF,
  createCase,
  leave,
  idx,
  levelChoose,
}) {
  const { httpInterface } = useAxiosContext();
  const { accessToken } = useSelector((state) => state.loginInfo);
  //console.log(leave);
  const [dropdown, setDropdown] = useState(
    (leave && leave.level) || (createCase ? "선택" : "전체")
  );
  useEffect(() => {
    httpInterface
      .getFilterValueData(accessToken)
      .then((res) => {
        //console.log(res);
        setLevelList(res.data.data[0].levelList);
      })
      .catch((error) => {
        console.log("error : ", error.response);
      });
  }, []);

  const [levelList, setLevelList] = useState([]);

  return (
    <Dropdown as={ButtonGroup} className={styles.Dropdown}>
      <Dropdown.Toggle
        id="dropdown-split-basic"
        className={styles.Dropdown}
        disabled={researcherTF ? false : true}
      >
        {leave?.level ? levelChoose[idx].level : dropdown}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {levelList.map((a, i) => {
          return (
            <>
              <Dropdown.Item
                className={styles.Dropdown}
                key={i}
                onClick={() => {
                  setDropdown(levelList[i]);
                  leave?.[idx]?.level
                    ? (leave[idx].level = levelList[i])
                    : (levelChoose[idx].level = levelList[i]);
                  setLevelChoose(levelChoose);
                }}
              >
                {levelList[i]}
              </Dropdown.Item>
            </>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}
