import React, { useState, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import styles from "../../../styles/App.module.css";
import data from "./univList.json";
import { style } from "d3";

export default function UniversityList({ userInfo, setIsOpenPost }) {
  const [searchEmail, setSearchEmail] = useState();
  const [keyword, setKeyword] = useState();
  const [results, setResult] = useState([]);
  const [isClickedCheck, setIsClickedCheck] = useState(false);
  const [id, setId] = useState([]);

  useEffect(() => {
    keyword?.length < 0 && setIsClickedCheck(false);
  }, [keyword]);
  // 필드 업데이트
  const updateField = (field, value, update = true) => {
    console.log(field, value);
    if (update) onSearch(value);
    if (field === "keyword") {
      setKeyword(value);
    }
    if (field === "results") {
      setResult(value);
    }
    userInfo.group = value;
  };

  // 입력된 텍스트로 data 배열에서 매칭되는 결과들을 저장
  const onSearch = (text) => {
    let results = data.filter((item) => true === matchName(item.number, text));
    console.log(results);
    setResult({ results });
  };

  // 검색해야할 문자열을 키워드와 비교하여 매칭되는지 체크
  const matchName = (name, keyword) => {
    let keyLen = keyword && keyword.length;
    name = name && name.substring(0, keyLen).trim();
    if (keyword === "") return false;
    else if (name === keyword.toString().toLowerCase()) return true;
  };
  console.log(id);

  const updateText = (text) => {
    updateField("keyword", text, false);
    updateField("results", []);
    userInfo.group = text;
  };

  let renderResults;
  const arr = results["results"];
  console.log(arr);
  if (arr) {
    // arr 에 검색어에 대한 결과가 담기면, SearchView 호출
    renderResults = arr.map((item) => {
      console.log(item);
      return (
        <>
          <SearchView
            updateText={updateText}
            name={item.number}
            key={item.number}
            setId={setId}
            setIsClickedCheck={setIsClickedCheck}
          />
        </>
      );
    });
  }
  console.log(userInfo);

  return (
    <>
      <div className={styles.Researcher}>
        <div className={styles.DivBox}>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="소속 학교를 입력해 주세요."
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
              autoComplete="off"
              id="researcherId"
              value={keyword || ""}
              onChange={(e) => {
                updateField("keyword", e.target.value);
              }}
            />
            <Button
              variant="outline-secondary"
              id="button-addon2"
              onClick={() => setIsOpenPost(false)}
              disabled={isClickedCheck && keyword?.length > 0 ? false : true}
            >
              확인
            </Button>
          </InputGroup>
          <div className={styles.ResultBox}>{renderResults}</div>
        </div>
      </div>
    </>
  );
}

const SearchView = ({ name, index, updateText, setId, setIsClickedCheck }) => {
  return (
    <div
      onClick={() => {
        updateText(name);
        setId(name);
        setIsClickedCheck(true);
      }}
    >
      <div className={styles.SearchResult}>
        <span>{name} </span>
      </div>
    </div>
  );
};
