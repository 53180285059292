import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import Card from "react-bootstrap/Card";
import styles from "./TestSlider.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import START from "../../../../images/start.svg";
import LOCK from "../../../../images/Lock.svg";
import COMPLETE from "../../../../images/complete.svg";
import { useNavigate } from "react-router-dom";
import { sliderSetting } from "../../../../api/componentOptions/slider";
import CaseName from "./CaseName";
import { useAxiosContext } from "../../../../context/AxiosContainer";
import {
  signOut,
  updateAccessToken,
} from "../../../../redux/slice/login/loginInfo";

export default function TestSlider({ caseList, isPc }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { httpInterface } = useAxiosContext();
  const { accessToken } = useSelector((state) => state.loginInfo);

  const sliderClickHandler =
    (caseId, status, nextSubQuestionId, index, caseName) => (e) => {
      console.log(status, nextSubQuestionId, caseId);
      switch (status.casePSStatus) {
        case "OPEN":
          httpInterface
            .getGotoStartTest(
              "/v2/api/caseps/" + status.subQuestionId,
              accessToken
            )
            .then((res) => {
              console.log(res);
              navigate("/StartTest", {
                state: {
                  data: res.data.data[0],
                  caseId: caseId,
                  nextSubQuestionId: nextSubQuestionId?.subQuestionId,
                },
              });
            })
            .catch((error) => {
              const { status } = error.response;
              console.log("Test Component Error", error);
              if (status === 401) {
                // reissue Token 관련 코드 작성할 것
                httpInterface
                  .reissueToken(accessToken)
                  .then((response) => {
                    const accessToken = response.data.data[0].accessToken;
                    httpInterface.getGotoStartTest(
                      "/v2/api/caseps/" + status.subQuestionId,
                      accessToken
                    );
                    dispatch(
                      updateAccessToken({
                        accessToken,
                      })
                    );
                  })
                  .catch((error) => {
                    console.log("Refresh Token Error");
                    alert("유효정보가 만료되어 로그아웃되었습니다.");
                    dispatch(signOut());
                  });
              } else {
                console.log("Validation Token Error");
                alert("유효정보가 만료되어 로그아웃되었습니다.");
                dispatch(signOut());
              }
            });

          break;
        case "COMPLETE":
          navigate("/Rank", {
            state: {
              idx: index,
              caseId: caseId,
              caseName: caseName,
              nextSubQuestionId: nextSubQuestionId?.subQuestionId,
            },
          });
          break;
        default: // ANALYZING, LOCK, ANALYZING_NEW
          alert("잠긴 테스트 입니다.");
      }
    };

  return (
    <>
      {!caseList && navigate("/")}
      {
        <Slider {...sliderSetting}>
          {caseList?.map((status, idx) => (
            <>
              <CaseName caseList={caseList} idx={idx} />
              <div key={idx} style={{ width: isPc ? "85%" : "100%" }}>
                <Card
                  style={{
                    backgroundColor: "#ECECEC",
                    marginTop: isPc ? "3%" : "5%",
                    marginRight: isPc ? "0" : "6%",
                  }}
                >
                  <Card.Body style={{ width: "100%" }}>
                    {caseList[idx]?.casePSInfoList.map((a, i) => (
                      <div
                        key={i}
                        style={{
                          marginTop: isPc ? "5%" : "3%",
                        }}
                      >
                        <div className={`${styles["question-number"]}`}>
                          {`질문 ${i + 1}`}
                        </div>
                        <div className={`${styles["round-text"]}`}>
                          {a.casePSStatus}
                        </div>
                        <img
                          src={getStatusInfo(a.casePSStatus).imageSrc}
                          alt={a.casePSStatus}
                          className={`${styles["round-image"]}`}
                          onClick={
                            caseList[idx].casePSInfoList[i]?.casePSStatus !==
                            "LOCK"
                              ? sliderClickHandler(
                                  caseList[idx].caseId,
                                  caseList[idx].casePSInfoList[i],
                                  caseList[idx].casePSInfoList[
                                    i + 1 !== caseList[idx].length && i + 1
                                  ],
                                  caseList[idx].casePSInfoList.indexOf(
                                    caseList[idx].casePSInfoList[i]
                                  ),
                                  caseList[idx].caseName
                                )
                              : null
                          }
                        />
                      </div>
                    ))}
                  </Card.Body>
                </Card>
              </div>
            </>
          ))}
        </Slider>
      }
    </>
  );
}

function getStatusInfo(status) {
  const results = {
    imageSrc: null,
    text: null,
    state: status,
  };
  switch (status) {
    case "LOCK":
      results.imageSrc = LOCK;
      results.text = "잠김";
      break;
    case "COMPLETE":
      results.imageSrc = COMPLETE;
      results.text = "완료";
      break;
    case "OPEN":
      results.imageSrc = START;
      results.text = "풀기";
      break;
    default:
      break;
  }
  return results;
}
