import { createContext, useContext } from 'react';
import Http from '../api/http/http';
import HttpInterface from '../api/http/httpInterface';

const AxiosContext = createContext();

export const AxiosProvider = ({ children }) => {
  const client = new Http();
  const httpInterface = new HttpInterface(client);

  return (
    <AxiosContext.Provider value={{ httpInterface }}>
      {children}
    </AxiosContext.Provider>
  );
};

export const useAxiosContext = () => {
  return useContext(AxiosContext);
};
