import React from "react";
import styles from "../../styles/UserRank.module.css";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { SlRefresh } from "react-icons/sl";
import { useAxiosContext } from "../../context/AxiosContainer";
import { useDispatch, useSelector } from "react-redux";
import { signOut, updateAccessToken } from "../../redux/slice/login/loginInfo";
import { useNavigate } from "react-router-dom";

export default function TotalRank({
  dropdownCaseNameList,
  dropdownCaseNameSearch,
  totalQuestionRankList,
  caseName,
}) {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  let today = new Date();
  let year = today.getFullYear();
  let month = today.getMonth() + 1;
  let date = today.getDate();

  const { httpInterface } = useAxiosContext();
  const { accessToken } = useSelector((state) => state.loginInfo);
  console.log(totalQuestionRankList);
  const GoSolveTest = () => {
    httpInterface
      .getGotoStartTest(
        "/v2/api/caseps/" + totalQuestionRankList?.nextSubQuestionId,
        accessToken
      )
      .then((res) => {
        console.log(res);
        navigate("/StartTest", {
          state: {
            data: res.data.data[0],
            //  caseId: location.state.caseId,
            nextSubQuestionId: totalQuestionRankList.nextSubQuestionId,
          },
        });
      })
      .catch((error) => {
        const { status } = error.response;
        console.log("Test Component Error", error);
        if (status === 401) {
          httpInterface
            .reissueToken(accessToken)
            .then((response) => {
              const accessToken = response.data.data[0].accessToken;
              httpInterface.getGotoStartTest(
                "/v2/api/caseps/" + totalQuestionRankList.nextSubQuestionId,
                accessToken
              );
              dispatch(
                updateAccessToken({
                  accessToken,
                })
              );
            })
            .catch((error) => {
              console.log("Refresh Token Error");
              alert("유효정보가 만료되어 로그아웃되었습니다.");
              dispatch(signOut());
            });
        } else {
          console.log("Validation Token Error");
          alert("유효정보가 만료되어 로그아웃되었습니다.");
          dispatch(signOut());
        }
      });
  };
  return (
    <div className={styles.CardWrap}>
      <Card
        style={{
          minWidth: "38vh",
          maxWidth: "38vh",
          backgroundColor: "#ECECEC",
          marginTop: "8%",
          minHeight: "45vh",
          marginBottom: "7%",
        }}
      >
        <Card.Body style={{ width: "80%" }}>
          <Card.Title className={styles.QuestionName}>
            {dropdownCaseNameSearch ? dropdownCaseNameSearch : caseName}
          </Card.Title>
          {totalQuestionRankList?.nextSubQuestionId ? (
            <div className={styles.RankBox1}>
              <div className={styles.RankBoxContent1}>
                모든 질문에 답변을 제출해야
                <br />
                랭킹을 확인할 수 있어요
                <div style={{ marginTop: "10%" }}>
                  <Button
                    className={styles.SolveQuestionBtn}
                    onClick={() => GoSolveTest()}
                  >
                    문제 풀기
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <>
              <span style={{ fontSize: "0.875rem" }}>
                {totalQuestionRankList.body}
              </span>

              <div className={styles.RankBox}>
                <div className={styles.RankBoxContent}>
                  나의 점수는?
                  <div style={{ marginTop: "10%" }}>
                    <span style={{ fontWeight: "bolder", fontSize: "1.5rem" }}>
                      {totalQuestionRankList.score}
                    </span>
                    <span style={{ fontSize: "0.875rem" }}> 점</span>
                  </div>
                </div>
              </div>
              <span style={{ fontSize: "0.75rem" }}>
                이 질문을 완료한 {totalQuestionRankList.totalPeople}명 중
              </span>
              <br />

              <span style={{ fontWeight: "bolder", fontSize: "0.875rem" }}>
                {totalQuestionRankList.rank}위 입니다.
              </span>

              <div
                style={{
                  margin: "7% 0",
                  color: "#3D5398",
                  fontSize: "1.25rem",
                  fontWeight: "bolder",
                }}
              >
                <span>
                  상위{" "}
                  {Math.round(
                    totalQuestionRankList.rank /
                      totalQuestionRankList.totalPeople
                  ) * 100}
                  %
                </span>
              </div>

              <span style={{ fontSize: "0.625rem" }}>
                {`*${year}년 ${month}월 ${date}일 기준`}
              </span>
              <div style={{ margin: "6% 0" }}>
                <SlRefresh className={styles.RefreshIcon} />
              </div>
            </>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}
