import React, { useEffect } from 'react';
import ReassignTheory from '../components/dModule/reassignTheory/ReassignTheory';
import { useAxiosContext } from '../context/AxiosContainer';
import { useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearReassignState,
  setReassignData,
} from '../redux/slice/theory/reassignTheory';
// import Sidebar from '../components/dModule/reassignTheory/Sidebar';
// import Sidebar from '../components/bModule/sidebar/Sidebar';
import styles from '../styles/ReassignTheoryPolar.module.css';
import {
  setSubQuestionBody,
  setSubQuestionId,
} from '../redux/slice/theory/createTheory';
import ManageTheoryData from '../components/bModule/manageTheory/ManageTheoryData';
import ReassignTheories from '../components/dModule/reassignTheory/ReassignTheories';
import Sidebar from '../components/dModule/reassignTheory/sidebar/Sidebar';

export default function ReassignTheoryPolar() {
  const { httpInterface } = useAxiosContext();
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state) => state.loginInfo);
  const { subQuestionId, subQuestionBody } = useSelector(
    (state) => state.createTheory
  );
  const { data, isLoading, isError, error } = useQuery(
    ['get-reassignTheory-data'],
    () => {
      return httpInterface.getReassignAnswerList(accessToken, subQuestionId);
    },
    {
      onError: (error) => {
        console.error('ReassignTheoryPolar-Error: ', error);
      },
      onSuccess: (res) => {
        dispatch(setReassignData({ data: res.data.data }));
      },
    }
  );

  useEffect(() => {
    return () => {
      dispatch(clearReassignState());
      dispatch(setSubQuestionBody({ subQuestionBody: null }));
      dispatch(setSubQuestionId({ subQuestionId: null }));
    };
  }, []);

  if (isLoading) return '로딩 중...';
  if (data) {
    console.log(subQuestionId);
    return (
      <section>
        {/* <p>{subQuestionBody}</p> */}
        <section className={`${styles.container}`}>
          {/* <ReassignTheory /> */}
          {/* <Sidebar /> */}
          <ReassignTheories subQuestionId={subQuestionId} />
          <Sidebar subQuestionId={subQuestionId} />
        </section>
      </section>
    );
  }
  if (isError) {
    return <h1>Error</h1>;
  }
}
