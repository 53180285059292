import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useAxiosContext } from "../../../../context/AxiosContainer";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  signOut,
  updateAccessToken,
} from "../../../../redux/slice/login/loginInfo";
import AnswerItem from "./AnswerItem";

export default function AnswerList() {
  const { httpInterface } = useAxiosContext();
  const { accessToken } = useSelector((state) => state.loginInfo);
  const { state: subQuestionId } = useLocation();
  const [answerList, setAnswerList] = useState([]);
  const dispatch = useDispatch();

  const { data, isLoading, isError } = useQuery(
    ["getUserAnswers"],
    () => {
      return httpInterface
        .getHundredAnswers(accessToken, {
          params: { subQuestionId: subQuestionId },
        })
        .catch((error) => {
          const { status } = error.response;
          console.log("Main Component Error", error);
          if (status === 401) {
            // reissue Token 관련 코드 작성할 것
            httpInterface
              .reissueToken(accessToken)
              .then((response) => {
                console.log(response.data.data[0]);
                dispatch(
                  updateAccessToken({
                    accessToken: response.data.data[0].accessToken,
                  })
                );
              })
              .catch((error) => {
                console.log("Refresh Token Error");
                alert("유효정보가 만료되어 로그아웃되었습니다.");
                dispatch(signOut());
              });
          } else {
            console.log("Validation Token Error");
            alert("유효정보가 만료되어 로그아웃되었습니다.");
            dispatch(signOut());
          }
        });
    },
    {
      onSuccess: (res) => {
        setAnswerList(res.data.data);
      },
    }
  );

  console.log(answerList);
  if (isLoading) return <div>로딩 중...</div>;

  if (isError) return <div>로딩 중 오류가 발생했습니다</div>;

  return (
    <div>
      {answerList.length === 0 && "텅~"}
      <ul>
        {answerList.length > 0 &&
          answerList.map((answer) => <AnswerItem answer={answer} />)}
      </ul>
    </div>
  );
}
