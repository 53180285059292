import React from 'react';
import Sidebar from '../components/dModule/sidebar/Sidebar';
import EditTheory from '../components/dModule/editTheory/EditTheory';
import styles from '../styles/EditTheoryPolar.module.css';

export default function CreateNewTheoryPolar() {
  return (
    <main className={styles['edit-theory-container']}>
      <EditTheory />
      <Sidebar className={styles['side-bar']} />
    </main>
  );
}
