import React from "react";
import styles from "../../../styles/CreateCase.module.css";
import DropdownCase from "../../managementMember/DropdownCase";

export default function Category({
  temporaryStorage,
  excelData,
  createClick,
  idx,
  setCategoryChoose,
  categoryChoose,
}) {
  //console.log(temporaryStorage);
  console.log(categoryChoose);
  return (
    <div className={styles.ChoiceTask}>
      <div className={styles.DivBox}>
        <span>유형*</span>
      </div>
      <div className={styles.Test}>
        <DropdownCase
          setCategoryChoose={setCategoryChoose}
          researcherTF={true}
          createCase={true}
          leave={temporaryStorage}
          idx={idx}
          excelData={excelData?.length > 0 && excelData}
          categoryChoose={categoryChoose}
        />

        {createClick && categoryChoose[idx]?.category.length === 0 && (
          <span style={{ color: "red" }}>필수 정보를 입력해주세요.</span>
        )}
      </div>
    </div>
  );
}
