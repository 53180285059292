import React from "react";
import styles from "../../styles/UserRank.module.css";
import Card from "react-bootstrap/Card";
import Nav from "react-bootstrap/Nav";
import { useMediaQuery } from "react-responsive";

export default function RankTabs({ totalOn, setTotalOn }) {
  const isPc = useMediaQuery({
    query: "(min-width:768px)",
  });

  return (
    <Card.Header
      style={{
        width: isPc ? "85%" : "100%",
        backgroundColor: "white",
        borderTop: "none",
        textAlign: "center",
      }}
    >
      <Nav variant="tabs">
        <Nav.Item
          style={{
            width: "50%",
          }}
        >
          <Nav.Link
            onClick={(e) => {
              setTotalOn(true);
            }}
            className={totalOn ? styles.BtnOn : styles.BtnOff}
          >
            <span style={{ fontSize: "0.875rem" }}>종합 랭킹</span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item style={{ width: "50%" }}>
          <Nav.Link
            onClick={() => setTotalOn(false)}
            className={totalOn ? styles.BtnOff : styles.BtnOn}
          >
            <span style={{ fontSize: "0.875rem" }}>질문 별 랭킹</span>
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </Card.Header>
  );
}
