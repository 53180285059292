const color1 = ['#459ADC', '#EA9B0E', '#6EBD3D', '#D660FF', '#3a4019'];
const color2 = ['#4E9FDE', '#ECA326', '#84CC57', '#DE80FF', '#7d8936'];
const color3 = ['#62AAE1', '#EDAB39', '#9BE36E', '#E28EFF', '#bac670'];
const color4 = ['#94C6EC', '#F3C676', '#B6F192', '#E7A2FF', '#d7dead'];
const color5 = ['#C9E2F5', '#F9E1B8', '#DBFAC8', '#F2CEFF', '#f5f6ea'];





// export const gradientColor = [color5, color4, color3, color2, color1];
// export const gradientColor = [color5, color4, color3, color2, color1];
export const gradientColor = [color5, color4, color3, color2, color1];