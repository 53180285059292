import React from 'react';
import Button from 'react-bootstrap/Button';
import styles from '../../../styles/MainResearcher.module.css';
import Table from 'react-bootstrap/Table';
import { useNavigate } from 'react-router-dom';
import { useAxiosContext } from '../../../context/AxiosContainer';
import BatchTableRow from './BatchTableRow';
import { useSelector } from 'react-redux';

export default function BatchTheoryPolar() {
  // let navigate = useNavigate();

  // const { httpInterface } = useAxiosContext();
  const { taskInfo } = useSelector((state) => state.taskInfo);
  console.log(taskInfo);
  let no = true;
  return (
    <>
      <div className={styles.Content}>
        <span>가설좌표 배치</span>
        <Button className={styles.ExamBtn}>예시</Button>
      </div>
      <Table hover>
        <thead>
          <tr>
            <th>유형</th>
            <th>난이도</th>
            <th>케이스명</th>
            <th>질문</th>
            <th>데드라인</th>
            <th>상태</th>
            <th>달성/목표</th>
            <th style={{ width: '15%' }}> </th>
          </tr>
        </thead>
        <tbody>
          {taskInfo &&
            taskInfo?.map((task, idx) => {
              if (taskInfo[idx].type === 'BATCH') {
                no = false;
                return <BatchTableRow key={idx} taskInfo={task} />;
              } else {
                return <></>;
              }
            })}
        </tbody>
      </Table>
      {no && <span>업무가 없습니다</span>}
    </>
  );
}
