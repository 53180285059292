import React, { useState } from 'react';
import Header from './header/Header';
import AnswerList from './answerList/AnswerList';
import NodeList from './nodeList/NodeList';
import ButtonList from './button/ButtonList';
import styles from './Sidebar.module.css';

export default function Sidebar() {
  const [currentState, setCurrentState] = useState(true);
  return (
    <section className={styles['side-bar']}>
      <Header toggleHeaderState={setCurrentState} headerState={currentState} />
      {!currentState && <ButtonList />}
      {currentState && <AnswerList />}
      {!currentState && <NodeList />}
    </section>
  );
}
