import React, { useState } from 'react';
import AnswerItem from './AnswerItem';
import styles from './AnswerLists.module.css';
import { useSelector } from 'react-redux';

export default function AnswerLists({ answers }) {
  const { selectedAnswerId } = useSelector(
    (state) => state.adminReassignTheory
  );
  return (
    <section className={`${styles.container}`}>
      {answers?.map((answer, idx) => (
        <AnswerItem
          answer={answer}
          number={idx + 1}
          selected={answer.psAnswerId === selectedAnswerId}
        />
      ))}
    </section>
  );
}
