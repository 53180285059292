import React, { useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import styles from "../../../styles/CreateCase.module.css";

export default function RegisterExcel({ setExcelData }) {
  const XLSX = require("xlsx");
  const excelRef = useRef();
  let rows;
  const saveImgFile = (e) => {
    let input = e.target;
    let reader = new FileReader();
    reader.onload = () => {
      let data = reader.result;
      let workBook = XLSX.read(data, { type: "binary" });
      workBook.SheetNames.forEach((sheetName) => {
        // console.log("SheetName: " + sheetName);
        rows = XLSX.utils.sheet_to_json(workBook.Sheets[sheetName]);
        console.log(JSON.stringify(rows), rows);
      });
    };

    reader.readAsBinaryString(input.files?.[0]);
  };
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <div className={styles.Researcher}>
        <div className={styles.DivBox}>
          <Button variant="primary" onClick={() => setModalOpen(true)}>
            <span>액셀로 등록하기</span>
          </Button>
          <Modal
            show={modalOpen}
            onHide={() => setModalOpen(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                업로드할 파일을 선택해주세요.
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form>
                <input
                  type="file"
                  name="excelFile"
                  onChange={saveImgFile}
                  ref={excelRef}
                  accept=".xls, .xlsx"
                />
              </form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => {
                  setExcelData(rows);
                  setModalOpen(false);
                }}
              >
                파일 업로드
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
}
