import React from 'react';
import styles from '../../../styles/MainResearcher.module.css';
import Button from 'react-bootstrap/esm/Button';
import { useNavigate } from 'react-router-dom';

export default function BatchTableRow({ taskInfo }) {
  const navigate = useNavigate();

  const {
    subQuestionId,
    category,
    level,
    status,
    title,
    deadLine,
    taskId,
    type,
    caseName,
    body,
  } = taskInfo;
  console.log(taskInfo);
  return (
    <>
      <tr className={styles.QuizTable}>
        <td>{category}</td>
        <td>{level}</td>
        <td>{caseName}</td>
        <td>{body}</td>
        <td>{deadLine}</td>
        <td>{status}</td>
        <td>129/300</td>
        <td>
          <Button
            className={styles.BatchCreateBtn}
            onClick={() =>
              navigate('/manageCanvas_polar', {
                state: {
                  taskId,
                  subQuestionId,
                },
              })
            }
          >
            배치하기
          </Button>
        </td>
      </tr>
    </>
  );
}

// function separateSentence(question) {
//   console.log(question);
//   const data = question.split('-');
//   const results = data[1].split('.');
//   console.log(data, results);
//   return [data[0], results[0], results[1]];
// }
