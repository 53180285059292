import React, { useState } from 'react';
import styles from './AnswerItem.module.css';
import { useDispatch } from 'react-redux';
import { selectAnswerId } from '../../../../redux/slice/theory/adminReassignTheory';

export default function AnswerItem({
  answer: { psAnswerId, text, engText },
  number,
  selected,
}) {
  const [answerState, setAnswerState] = useState('no'); // 'no' | 'yes' | 'trash'
  const dispatch = useDispatch();

  return (
    <div
      className={`${styles.item} ${selected && styles.selected}`}
      onClick={() => {
        dispatch(
          selectAnswerId({
            psAnswerId,
          })
        );
      }}
    >
      <div className={`${styles.number}`}>{number}</div>
      <div className={`${styles.state}`}>
        {answerState === 'yes' && 'O'}
        {answerState === 'trash' && '🗑️'}
      </div>
      <div className={`${styles.text}`}>{text}</div>
    </div>
  );
}
