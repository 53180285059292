import React from "react";
import styles from "../../../styles/App.module.css";
import ValidateEmailText from "./ValidateEmailText";

export default function LoginInput({
  setUserEmailInput,
  isEmail,
  login,
  userEmailInput,
  isPc,
  setUserPasswordInput,
}) {
  const emailInputHandler = (e) => {
    setUserEmailInput(e.target.value);
  };

  const passwordInputHandler = (e) => {
    setUserPasswordInput(e.target.value);
  };

  const keyDownHandler = (e) => {
    if (!isEmail) return;
    if (e.key === "Enter") {
      login();
    }
  };
  return (
    <>
      <div style={{ fontSize: "0.7em", marginTop: "3vh", marginBottom: "5vh" }}>
        <ValidateEmailText isEmail={isEmail} userEmailInput={userEmailInput} />
        <input
          className={isPc ? styles.EmailPc : styles.Email}
          onChange={emailInputHandler}
          onKeyPress={keyDownHandler}
          placeholder="이메일"
          autoFocus
        />
      </div>

      <div style={{ fontSize: "0.7em" }}>
        <input
          className={isPc ? styles.EmailPc : styles.Email}
          onChange={passwordInputHandler}
          onKeyPress={keyDownHandler}
          placeholder="비밀번호"
          type="password"
        />
      </div>
    </>
  );
}
