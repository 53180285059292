import React, { forwardRef, useEffect, useRef, useState } from "react";
import CheckInputLength from "./CheckInputLength";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  addAnswerState,
  finishCurrentAnswer,
  removeOneAnswer,
  updateAnswer,
  updateCurrentNum,
} from "../../../redux/slice/quizAnswer/quizAnswer";
import styles from "./AnswerInput.module.css";
import SubmitButton from "./button/SubmitButton";
import { useLocation } from "react-router-dom";

const AnswerInput = forwardRef((_, ref) => {
  const { submit, next } = ref;
  const addBtn = useRef();
  const dispatch = useDispatch();
  const { answersForOneQuestion: answers, currentNum } = useSelector(
    (state) => state.answers
  );

  const [answer, setAnswer] = useState(answers[currentNum]?.text || "");
  useEffect(() => {
    setAnswer(answers[currentNum]?.text || "");
  }, [answers, currentNum]);

  const answerTypingHandler = (e) => {
    setAnswer(e.target.value);
    dispatch(
      updateAnswer({
        index: currentNum,
        answer: e.target.value,
      })
    );
  };

  const keyDownHandler = (e) => {
    if (e.key !== "Enter") return;
    if (answer.length) {
      // console.log(answer, addBtn.current);
      addBtn.current.click();
    }
  };
  const [idx, setIdx] = useState();

  const RemoveModal = (i) => {
    setModalOpen(true);
    setIdx(i);
  };
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  let location = useLocation();

  return (
    <>
      <div className={styles.Container}>
        {answers.map((a, i) => (
          <>
            <div className={styles.InputWrap}>
              <div className={styles.InputBoxWrap}>
                <div className={styles.AnswerNum}>{a.idx + 1}</div>
                <input
                  className={styles.InputBox}
                  onKeyPress={keyDownHandler}
                  onChange={answerTypingHandler}
                  value={answers[i].text}
                  autoFocus={i != 0 && true}
                  onFocus={() => {
                    dispatch(
                      updateCurrentNum({
                        currentNum: a.idx,
                      })
                    );
                  }}
                  autoComplete="off"
                  maxLength={35}
                  placeholder="답변을 입력하세요."
                />
                <CheckInputLength textLength={a.text?.length} />
                {answers.length !== 1 && (
                  <Button
                    className={styles.RemoveBtn}
                    onClick={() => {
                      answers.length !== 1 &&
                        (answers[i].text.length > 0
                          ? RemoveModal(i)
                          : dispatch(
                              removeOneAnswer({
                                index: i,
                                answer: answers[i].text,
                              })
                            ));
                    }}
                  >
                    X
                  </Button>
                )}
              </div>
            </div>
            <Modal
              show={modalOpen}
              onHide={() => setModalOpen(false)}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              idx={idx}
            >
              <Modal.Header closeButton />
              <Modal.Body>
                <span>정말 답변을 삭제하시겠어요?</span>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    console.log(idx);
                    setModalOpen(false);
                  }}
                >
                  취소
                </Button>
                <Button
                  onClick={() => {
                    dispatch(
                      removeOneAnswer({
                        index: idx,
                        answer: answers[idx].text,
                      })
                    );
                    setModalOpen(false);
                  }}
                >
                  삭제
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        ))}
      </div>
      <div>
        <Button
          className={styles.AddAnswerBtn}
          ref={addBtn}
          onClick={() => {
            const isLastAnswerInputed = !!answers[answers.length - 1]?.text; // 마지막 input에 입력이 된 경우
            const currentNumInputed = !!answers[currentNum]?.text; // 현재 focus된 input에 입력이 된 경우

            !currentNumInputed && setModalOpen2(true);
            dispatch(finishCurrentAnswer());
            dispatch(
              updateCurrentNum({
                currentNum:
                  isLastAnswerInputed || currentNumInputed
                    ? currentNum + 1
                    : currentNum,
              })
            );

            // 마지막 input에 입력을 받은 경우에만 맨 뒤에 answer state 추가
            isLastAnswerInputed && dispatch(addAnswerState());
          }}
        >
          + 답변 추가
        </Button>
        <div style={{ textAlign: "center" }}>
          <SubmitButton
            ref={submit}
            subQuestionId={location.state?.data?.subQuestionId}
            caseId={location.state?.caseId}
          />
        </div>
      </div>
      <Modal
        show={modalOpen2}
        onHide={() => setModalOpen2(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <span>답변 입력 후 추가 답변을 작성할 수 있어요.</span>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setModalOpen2(false);
            }}
          >
            확인
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
});
export default AnswerInput;
