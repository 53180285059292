import React from "react";
import TestSlider from "./testInfoContainer/TestSlider";
import styles from "../../../styles/Main.module.css";
// import "~slick-carousel/slick/slick.css";
// import "~slick-carousel/slick/slick-theme.css";

export default function TestInfoContainer({ caseList, isPc }) {
  return (
    <div className={styles.ContentWrap}>
      <TestSlider caseList={caseList} isPc={isPc} />
    </div>
  );
}
