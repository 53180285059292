import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeMode } from '../../../../redux/slice/theory/adminReassignTheory';

import styles from './ButtonList.module.css';
// import { useAxiosContext } from '../../../../context/AxiosContainer';

export default function ButtonList() {
  // const { httpInterface } = useAxiosContext();
  const dispatch = useDispatch();
  const { mode } = useSelector((state) => state.adminReassignTheory);
  console.log(mode);

  return (
    <div className={`${styles['btn-container']}`}>
      <button
        className={`${mode === 'assign' && styles.active}`}
        onClick={() =>
          dispatch(
            changeMode({
              mode: 'assign',
            })
          )
        }
      >
        배치(생성)
      </button>
      <button
        className={`${mode === 'modify' && styles.active}`}
        onClick={() => dispatch(changeMode({ mode: 'modify' }))}
      >
        답변 수정
      </button>
      <button
        className={`${mode === 'divide' && styles.active}`}
        onClick={() => dispatch(changeMode({ mode: 'divide' }))}
      >
        분할
      </button>
      <button
        className={`${mode === 'delete' && styles.active}`}
        onClick={() => dispatch(changeMode({ mode: 'delete' }))}
      >
        삭제
      </button>
    </div>
  );
}
