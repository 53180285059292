import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import loginInfoReducer from "../slice/login/loginInfo";
import quizAnswerReducer from "../slice/quizAnswer/quizAnswer";
import modalReducer from "../slice/modal/modal";
import createTheoryReducer from "../slice/theory/createTheory";
import reassignTheoryReducer from "../slice/theory/reassignTheory";
import xlsxTheoryReducer from "../slice/theory/xlsxCreateTheory";
import taskInfoReducer from "../slice/taskInfo/taskInfo";
import manageTheoryReducer from "../slice/theory/manageTheory";
import storageSession from "redux-persist/lib/storage/session";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
} from "redux-persist";
import persistStore from "redux-persist/es/persistStore";
import adminReassignTheoryReducer from "../slice/theory/adminReassignTheory";

// export const store = createStore
const persistConfig = {
  key: "root",
  version: 1,
  storage: storageSession,
  whitelist: ["loginInfo", "xlsxTheory", "createTheory"],
};

export const rootReducer = combineReducers({
  loginInfo: loginInfoReducer,
  modal: modalReducer,
  answers: quizAnswerReducer,
  reassignTheory: reassignTheoryReducer,
  taskInfo: taskInfoReducer,
  createTheory: createTheoryReducer,
  xlsxTheory: xlsxTheoryReducer,
  manageTheory: manageTheoryReducer,
  adminReassignTheory: adminReassignTheoryReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware({
  //     serializableCheck: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  //   }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export const persistor = persistStore(store);
export default store;
