import { useTranslation } from "react-i18next";
import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "../styles/InfoTest.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useAxiosContext } from "../context/AxiosContainer";
import { userTypes } from "../key/userTypes";
import { closeModal } from "../redux/slice/modal/modal";
import { signOut, updateAccessToken } from "../redux/slice/login/loginInfo";

export default function InfoTest() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { httpInterface } = useAxiosContext();
  const { roleType, accessToken } = useSelector((state) => state.loginInfo);
  const { t } = useTranslation();

  const startTestHandler = () => {
    httpInterface
      .getQuizData(accessToken)
      .then((res) => {
        console.log(res);
        navigate("/StartTest");
        dispatch(closeModal());
      })
      .catch((error) => {
        dispatch(closeModal());
        const { status } = error.response;
        console.log("TestPage Component Error", error);
        if (status === 401) {
          // reissue Token 관련 코드 작성할 것
          httpInterface
            .reissueToken(accessToken)
            .then((response) => {
              console.log(response.data.data[0]);
              dispatch(
                updateAccessToken({
                  accessToken: response.data.data[0].accessToken,
                })
              );
            })
            .catch((error) => {
              console.log("Refresh Token Error");
              alert("유효정보가 만료되어 로그아웃되었습니다.");
              dispatch(signOut());
              navigate("/");
            });
        }
      });
  };

  const startExampleHandler = () => {
    navigate("/TestPage");
  };

  return (
    <>
      <div className={styles.TestWrap}>
        <div className={styles.Test}>
          <div className={styles.TestStepWrap}>
            <span>{t("infoTest")}</span>
          </div>
          <div className={styles.TextWrap}>
            <h3 style={{ fontSize: "1em", fontWeight: "bolder" }}>
              {t("infoText1")}
            </h3>
            <div className={styles.Text}>
              <p> {t("infoText2")}</p>
              <p> {t("infoText3")}</p>
            </div>
          </div>

          <div style={{ textAlign: "center" }}>
            <button className={styles.StartBtn} onClick={startExampleHandler}>
              <b> {t("example")}</b>
            </button>
            <button className={styles.StartBtn} onClick={startTestHandler}>
              <b> {t("start")}</b>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
