import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import styles from "../../styles/ManagementResearcher.module.css";
import { useState } from "react";

export default function DropdownBtn() {
  const [dropdown, setDropdown] = useState("이메일");
  return (
    <Dropdown as={ButtonGroup} className={styles.Dropdown}>
      <Dropdown.Toggle id="dropdown-split-basic" className={styles.Dropdown}>
        {dropdown}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item
          eventKey="1"
          onClick={() => {
            setDropdown("이메일");
          }}
        >
          이메일
        </Dropdown.Item>
        <Dropdown.Item
          eventKey="2"
          onClick={() => {
            setDropdown("회원번호");
          }}
        >
          회원번호
        </Dropdown.Item>
        <Dropdown.Item
          eventKey="3"
          onClick={() => {
            setDropdown("이름");
          }}
        >
          이름
        </Dropdown.Item>
        <Dropdown.Item
          eventKey="4"
          onClick={() => {
            setDropdown("전화번호");
          }}
        >
          전화번호
        </Dropdown.Item>
        <Dropdown.Item
          eventKey="4"
          onClick={() => {
            setDropdown("담당업무");
          }}
        >
          담당업무
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
