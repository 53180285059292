import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearState } from '../redux/slice/theory/manageTheory';
import Sidebar from '../components/bModule/sidebar/Sidebar';

import styles from '../styles/ManageCanvasContainer.module.css';
import ManageTheoryData from '../components/bModule/manageTheory/ManageTheoryData';

const ManagaCanvasContainer = () => {
  const location = useLocation();
  const { accessToken } = useSelector((state) => state.loginInfo);
  const dispatch = useDispatch();
  const { taskId } = location.state;
  // const [question, setQuestion] = useState('');

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  });

  return (
    <main>
      {/* <div>{question}</div> */}
      <section className={styles.main}>
        {/* <CanvasOptionProvider>
        <QuestionContextProvider taskId={location.state.taskId}>
          <ManageCanvasPolar taskId={location.state.taskId} />
        </QuestionContextProvider>
      </CanvasOptionProvider> */}

        <ManageTheoryData taskId={taskId}/*  setQuestion={setQuestion} */ />
        <Sidebar taskId={taskId} />
      </section>
    </main>
  );
};
export default ManagaCanvasContainer;
