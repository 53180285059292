import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { clearXlsxCreate } from '../redux/slice/theory/xlsxCreateTheory';
import XlsxTheoryData from '../components/dModule/createTheory/XlsxTheoryData';
import XlsxInput from '../components/dModule/createTheory/XlsxInput';
import styles from '../styles/CreateNewThoeryPolar.module.css';

export default function CreateNewTheoryPolar() {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(clearXlsxCreate());
    };
  }, []);
  return (
    <main className={styles['create-theory-container']}>
      <XlsxTheoryData />
      <XlsxInput />
    </main>
  );
}
