import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import styles from "../styles/UserRank.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useAxiosContext } from "../context/AxiosContainer";
import RankDropdown from "../components/rank/RankDropdown";
import { signOut, updateAccessToken } from "../redux/slice/login/loginInfo";
import { useLocation } from "react-router-dom";
import TotalRank from "../components/rank/TotalRank";
import SubQuestionRank from "../components/rank/SubQuestionRank";
import RankTabs from "../components/rank/RankTabs";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";

export default function UserRank() {
  const isPc = useMediaQuery({
    query: "(min-width:768px)",
  });

  let location = useLocation();
  const dispatch = useDispatch();

  const [totalOn, setTotalOn] = useState(
    location.state?.totalOn ? true : false
  );

  console.log(location.state);

  const { httpInterface } = useAxiosContext();
  const { accessToken } = useSelector((state) => state.loginInfo);

  const [dropdownCaseNameList, setDropdownCaseNameList] = useState();
  const [dropdownCaseNameSearch, setDropdownCaseNameSearch] = useState();

  const [subQuestionRankList, setSubQuestionRankList] = useState([]);
  const [totalQuestionRankList, setTotalQuestionRankList] = useState([]);

  useEffect(() => {
    httpInterface
      .getRankCaseDropdown(accessToken)
      .then(async (res) => {
        console.log(res);
        await setDropdownCaseNameList(res.data.data);
        httpInterface
          .getSubQuestionRankList(
            "/v2/api/rank/case/" + res.data.data[0].caseId,
            accessToken
          )
          .then(async (res) => {
            console.log("랭킹", res);
            await setSubQuestionRankList(res.data.data);
          })
          .catch((error) => {
            console.log("error : ", error.response);
            const { status } = error.response;

            if (status === 401) {
              httpInterface
                .reissueToken(accessToken)
                .then((response) => {
                  const accessToken = response.data.data[0].accessToken;
                  httpInterface.getSubQuestionRankList(
                    "/v2/api/rank/case/" + res.data.data[0].caseId,
                    accessToken
                  );
                  dispatch(
                    updateAccessToken({
                      accessToken,
                    })
                  );
                })
                .catch((error) => {
                  console.log("Refresh Token Error");
                  alert("유효정보가 만료되어 로그아웃되었습니다.");
                  dispatch(signOut());
                });
            } else {
              console.log("Validation Token Error");
              alert("유효정보가 만료되어 로그아웃되었습니다.");
              dispatch(signOut());
            }
          });
      })
      .catch((error) => {
        console.log("error : ", error.response);
        const { status } = error.response;

        if (status === 401) {
          httpInterface
            .reissueToken(accessToken)
            .then((response) => {
              const accessToken = response.data.data[0].accessToken;
              httpInterface.getRankCaseDropdown(accessToken);
              dispatch(
                updateAccessToken({
                  accessToken,
                })
              );
            })
            .catch((error) => {
              console.log("Refresh Token Error");
              alert("유효정보가 만료되어 로그아웃되었습니다.");
              dispatch(signOut());
            });
        } else {
          console.log("Validation Token Error");
          alert("유효정보가 만료되어 로그아웃되었습니다.");
          dispatch(signOut());
        }
      });

    httpInterface
      .getTotalRankView(
        "/v2/api/rank/synthesize/case/" + location.state.caseId,
        accessToken
      )
      .then(async (res) => {
        console.log("total", res);
        await setTotalQuestionRankList(res.data?.data[0]);
      })
      .catch((error) => {
        const { status } = error.response;
        console.log("Test Component Error", error);
        if (status === 401) {
          httpInterface
            .reissueToken(accessToken)
            .then((response) => {
              const accessToken = response.data.data[0].accessToken;
              httpInterface.getTotalRankView(
                "/v2/api/rank/synthesize/case/" + location.state.caseId,
                accessToken
              );
              dispatch(
                updateAccessToken({
                  accessToken,
                })
              );
            })
            .catch((error) => {
              console.log("Refresh Token Error");
              alert("유효정보가 만료되어 로그아웃되었습니다.");
              dispatch(signOut());
            });
        } else {
          console.log("Validation Token Error");
          alert("유효정보가 만료되어 로그아웃되었습니다.");
          dispatch(signOut());
        }
      });
  }, []);

  return (
    <div
      style={{
        width: isPc ? "28rem" : "100vw",
        height: "90vh",
        overflowY: "hidden",
      }}
    >
      <RankDropdown
        className={styles.Dropdown}
        dropdownCaseNameList={dropdownCaseNameList}
        setDropdownCaseNameSearch={setDropdownCaseNameSearch}
      />
      <Card style={{ border: "none" }}>
        <RankTabs totalOn={totalOn} setTotalOn={setTotalOn} />
        {totalOn ? (
          <TotalRank
            dropdownCaseNameList={dropdownCaseNameList}
            dropdownCaseNameSearch={dropdownCaseNameSearch}
            totalQuestionRankList={totalQuestionRankList}
            caseName={location.state?.caseName}
          />
        ) : (
          <SubQuestionRank
            subQuestionRankList={subQuestionRankList}
            idx={location.state?.idx && location.state.idx}
          />
        )}
      </Card>
    </div>
  );
}
