import React, { useEffect, useRef, useState } from "react";
import StartTestHeader from "../components/aModule/startTest/StartTestHeader";
import UpperClassQuestion from "../components/aModule/startTest/ContextQuestion";
import DetailQuestion from "../components/aModule/startTest/DetailQuestion";
import AnswerInput from "../components/aModule/startTest/AnswerInput";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAnswersState,
  setStartTime,
  setTheoryManager,
} from "../redux/slice/quizAnswer/quizAnswer";
import RealTimeAssign from "../components/aModule/startTest/realtimeAssignment/RealTimeAssign";
import { RealTimeAssignProvider } from "../context/RealTimeAssignContext";
import { TheoryManagement } from "../api/theory/theoryManager";
import { isEmptyObj } from "../api/common/common";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

// const initTime = 60 * 5; // 300초(5분)
function isExistTheoryManager(theoryManager) {
  if (!theoryManager) return false;
  if (theoryManager && isEmptyObj(theoryManager)) return false;
  return true;
}

export default function StartTest() {
  const isPc = useMediaQuery({
    query: "(min-width:768px)",
  });
  let location = useLocation();
  console.log(location.state);
  const btnRefs = {
    submit: useRef(null),
    next: useRef(null),
  };
  const dispatch = useDispatch();
  const { theoryManager } = useSelector((state) => state.answers);

  useEffect(() => {
    // 처음 렌더링 시에 시작 시간 체크
    dispatch(setTheoryManager({ theoryManager: new TheoryManagement() }));
    dispatch(setStartTime());
    return () => {
      // 페이지를 나가면 대답 상태 초기화
      dispatch(clearAnswersState());
    };
  }, [location.state.data.subQuestionId]);
  const [keyOn, setKeyOn] = useState(false);

  return (
    <>
      {isExistTheoryManager(theoryManager) && (
        <RealTimeAssignProvider>
          <div
            style={{
              width: isPc ? "28rem" : "100vw",
              padding: "1%",
              margin: "0 auto",
            }}
          >
            <StartTestHeader />
            <UpperClassQuestion ref={btnRefs} />
            <DetailQuestion isPc={isPc} />
            <AnswerInput ref={btnRefs} keyOn={keyOn} setKeyOn={setKeyOn} />
          </div>
        </RealTimeAssignProvider>
      )}
    </>
  );
}
