import { createContext, useContext, useState, useEffect } from "react";
import { userTypes } from "../key/userTypes";
import { useNavigate } from "react-router-dom";

const LoginContext = createContext();

export const LoginProvider = ({ children }) => {
  const [notFirstRender, setNotFirstRender] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [userEmail, setUserEmail] = useState("");

  // 특정 type의 사용자가 다른 page로 이동 못하도록 type을 기억하도록
  const [userType, setUserType] = useState(userTypes.none);
  const navigate = useNavigate();

  useEffect(() => {
    setNotFirstRender(true);
  }, []);

  useEffect(() => {
    if (isLogin) {
      setUserEmail(localStorage.getItem("rtwEmail"));
      setUserType(localStorage.getItem("userType"));
    }
    if (!isLogin && notFirstRender) {
      setUserEmail("");
      setUserType(userTypes.none);
      removeUserInfoFromLocalStorage();
    }

    if (!localStorage.getItem("rtwEmail")) {
      removeUserInfoFromLocalStorage();
      navigate("/");
    }
  }, [isLogin]);

  // 새로고침
  useEffect(() => {
    if (localStorage.getItem("rtwEmail")) {
      setUserEmail(localStorage.getItem("rtwEmail"));
      setUserType(localStorage.getItem("userType"));
      setIsLogin(true);
    }
  }, []);

  return (
    <LoginContext.Provider
      value={{
        isLogin,
        setIsLogin,
        userEmail,
        userType,
        setUserType,
        setUserEmail,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export const useLoginContext = () => {
  return useContext(LoginContext);
};

export function removeUserInfoFromLocalStorage() {
  localStorage.removeItem("rtwEmail");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("userToken");
  localStorage.removeItem("userType");
}

/**
 * 로그인 로직
 * 1. 로그인 했으면 userEmail과 userType, isLogin 데이터 추가 + localStorage에 token 2개, rtwEmail 관련 정보 추가
 * 2. 로그아웃시 토큰정보 + isLogin 정보 삭제 -> isLogin이 false인 경우, userEmail+userType 삭제
 * */
