import * as d3 from '../../../../MindMap/js/d3';

export const initGraphColor = d3.scaleOrdinal([
  '#F2F2F2',
  '#E2E2E2',
  '#D0D0D0',
  '#C3C3C3',
  '#ADADAD',
]);
const color1 = d3.scaleOrdinal([
  '#FFFFFF',
  '#FFFFFF',
  '#FFFFFF',
  '#FFFFFF',
  '#FFFFFF',
]);
const color2 = d3.scaleOrdinal([
  '#EEEEEE',
  '#EEEEEE',
  '#EEEEEE',
  '#EEEEEE',
  '#EEEEEE',
]);
const color3 = d3.scaleOrdinal([
  '#DDDDDD',
  '#DDDDDD',
  '#DDDDDD',
  '#DDDDDD',
  '#DDDDDD',
]);
const color4 = d3.scaleOrdinal([
  '#CCCCCC',
  '#CCCCCC',
  '#CCCCCC',
  '#CCCCCC',
  '#CCCCCC',
]);
const color5 = d3.scaleOrdinal([
  '#BBBBBB',
  '#BBBBBB',
  '#BBBBBB',
  '#BBBBBB',
  '#BBBBBB',
]);
const color6 = d3.scaleOrdinal([
  '#AAAAAA',
  '#AAAAAA',
  '#AAAAAA',
  '#AAAAAA',
  '#AAAAAA',
]);

export const gradientColor = [color1, color2, color3, color4, color5];
export const color = [color6, color6, color6, color6, color6];
