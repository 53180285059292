import React, { useEffect, useState } from "react";
import styles from "../../styles/ManagementResearcher.module.css";

export default function SearchResearcher({
  keyword,
  results,
  updateField,
  setId,
  remove,
  setRemove,
  researcherTF,
  setResearcherTF,
}) {
  const updateText = (text) => {
    updateField("keyword", text, false);
    updateField("results", []);
  };

  useEffect(() => {
    if (remove) {
      updateField("keyword", "");
      setId("");
      setResearcherTF(false);
    }
  }, [remove]);

  let renderResults;
  const arr = results["results"];
  console.log(arr);
  if (arr) {
    // arr 에 검색어에 대한 결과가 담기면, SearchView 호출
    renderResults = arr.map((item) => {
      console.log("실행", item);
      return (
        <>
          <SearchView
            updateText={updateText}
            name={item.name}
            email={item.email}
            key={item.email}
            setId={setId}
            remove={remove}
            setRemove={setRemove}
            researcherTF={researcherTF}
          />
        </>
      );
    });
  }
  // onChange를 사용하여 글자를 입력할때마다 updateField호출하고 renderResults를 그린다.

  return (
    <div className={styles.SearchBox}>
      <input
        autoComplete="off"
        className={styles.SearchInput}
        disabled={researcherTF ? true : false}
        placeholder="Search"
        id="researcherId"
        value={keyword || ""}
        onChange={(e) => updateField("keyword", e.target.value)}
      />
      <div className="search-results">{renderResults}</div>
    </div>
  );
}

// 검색된 아이템 "name" "code" 출력
// 결과값을 클릭하면 updateText를 호출하여 input에 name을 표시
const SearchView = ({ name, index, updateText, setId, setRemove, email }) => {
  return (
    <div
      onClick={() => {
        setRemove(false);
        updateText(email + name);
        setId(email);
      }}
      className={`search-preview ${index === 0 ? "start" : ""}`}
    >
      <div className={styles.ResultBox}>
        <span className={styles.SearchResult}>{name} </span>
        <span className={styles.SearchResult}> {email}</span>
      </div>
    </div>
  );
};
