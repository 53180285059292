import React from 'react';

import styles from './Header.module.css';

export default function Header({ toggleHeaderState, headerState }) {
  return (
    <nav className={styles['sidebar-header']}>
      <span
        className={headerState ? styles['active'] : ''}
        onClick={() => toggleHeaderState(true)}
      >
        답변 리스트
      </span>
      <span
        className={!headerState ? styles['active'] : ''}
        onClick={() => toggleHeaderState(false)}
      >
        노드 리스트
      </span>
    </nav>
  );
}
