import { Calculator } from '../../../api/theory/theoryTree';

export const getTheories = (tree) => {
  let theoryList = [];
  const parent = tree.getCategory();
  console.log(tree, parent);
  // const category = tree.getCategory();
  const theories = parent.getChildren();
  if (!theories.length) {
    return;
  }
  theories.forEach((item) => {
    if (!item.isFakeTheory()) {
      theoryList.push(item);
    }
    const children = item.getChildren();

    theoryList = [...theoryList, ...getTheory(children)];
  });
  console.log(theoryList);
  return theoryList;
};

const getTheory = (theories) => {
  if (!theories.length) return [];
  let results = [];
  theories.forEach((theory) => {
    if (!theory.isFakeTheory()) {
      results.push(theory);
    }
    const children = theory.getChildren();
    results = [...results, ...getTheory(children)];
  });
  return results;
};

export function calcTheoriesDegreeAndDepth(tree) {
  const calc = new Calculator();
  const category = tree.getCategory();
  const children = category.getChildren();
  let acc = 0;
  let prevDegree = 0;
  let totalCount = tree.getTheoriesCount();
  children.forEach((item) => {
    const childrenCount = item.getTotalCount();
    //   // console.log(acc, childrenCount, totalCount);
    const endDegree = calc.calcDegree(acc, childrenCount, totalCount);
    // console.log(degree);
    const depth = calc.calcDepth(item.getDepthNum());
    item.setStartDegree(prevDegree);
    item.setEndDegree(endDegree);
    item.setDegree((prevDegree + endDegree) / 2);
    item.setDepth(depth);
    acc += childrenCount;
    dfs(item);
    prevDegree = endDegree;
  });
}

function dfs(subTheoryTree) {
  // 자식 트리 개수 구하기
  const calc = new Calculator();
  const children = subTheoryTree.getChildren();
  let totalCount = subTheoryTree.getChildrenCount();
  let acc = 0;

  const range = [subTheoryTree.getStartDegree(), subTheoryTree.getEndDegree()];
  let prevDegree = subTheoryTree.getStartDegree();

  children.forEach((item) => {
    if (item.isFakeTheory()) {
      item.setStartDegree(subTheoryTree.getStartDegree());
      item.setEndDegree(subTheoryTree.getEndDegree());
      item.setDegree(subTheoryTree.getDegree());
    } else {
      const childrenCount = item.getTotalCount();
      const depth = calc.calcDepth(item.getDepthNum());
      const endDegree = calc.calcDegree(acc, childrenCount, totalCount, range);
      acc += childrenCount;
      item.setDepth(depth);
      item.setStartDegree(prevDegree);
      item.setDegree((prevDegree + endDegree) / 2);
      item.setEndDegree(endDegree);
      prevDegree = endDegree;
    }
    if (children.length) {
      dfs(item);
    }
    // console.log(item, range);
  });
}

export function calcCategoryList(categoryList) {
  const calculator = new Calculator();
  categoryList.forEach((category) => {
    const screenCoord =
      calculator.calculateCategoryScreenCoordByCircleCoord(category);
    category.setScreenCoord(screenCoord);
  });
  return categoryList;
}

const width = window.innerWidth * 0.7;
const height = window.innerHeight * 0.9;
// const height = window.innerHeight * 0.8;
const outerRadius = width > height ? height / 2 : width / 2;
const arcDepth = 5;
const radiusUnit = outerRadius / arcDepth;

export function calculateScreenCoordByCircleCoord(theoryList, categoryList) {
  if (!theoryList || !theoryList?.length) return;

  for (const theory of theoryList) {
    const { depth, degree } = theory?.getCircleCoord();
    if (depth && (depth < 0 || depth > 100))
      throw new Error('Out of depth range error');
    if (degree && (degree < 0 || degree > 100))
      throw new Error('Out of degree range error');
    const category = categoryList.find((category) => {
      return category.getCategoryId() === theory.getCategoryId();
    });

    console.log(category, categoryList, theory);

    const { startAngle, endAngle } = category.getAngles();
    const gradient = calculateGradient(startAngle, endAngle, degree);

    const radian = getRadianByGradient(gradient);
    // const outerRadius = theoryCanvas?.getOuterRadius();
    // const radiusUnit = theoryCanvas?.getRadiusUnit();
    // const spotRange = outerRadius - radiusUnit;
    const spotRange = outerRadius;
    // const spotDepth = radiusUnit + spotRange * (depth / 100);
    const spotDepth = spotRange * (depth / 100);
    const screenCoord = calculateSpaceCoordByRadianAndRadius(radian, spotDepth);
    theory.setScreenCoord(screenCoord);
  }

  return theoryList;
}

function calculateGradient(startAngle, endAngle, degree) {
  return startAngle + (endAngle - startAngle) * (degree / 100);
}

const degreeToPi = Math.PI / 180;
function getRadianByGradient(gradient) {
  const radian = (gradient - 90) * degreeToPi;
  return radian;
}

function calculateSpaceCoordByRadianAndRadius(radian, radius) {
  const x = radius * Math.cos(radian);
  const y = radius * Math.sin(radian);
  return { x, y };
}
