import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import Main from "./pages/Main";
import App from "./App";
import InfoTest from "./pages/InfoTest";
import StartTest from "./pages/StartTest";
import TestPage from "./pages/TestPage";
import Intro from "./MindMap/Intro/Intro";
//import ManageCanvas from "./MindMap/ManageCanvas/ManageCanvas";
//import ManageCanvas3 from "./MindMap/ManageCanvas/ManageCanvas3";
import AdminPage from "./pages/AdminPage";
import Login from "./pages/Login";
import { AxiosProvider } from "./context/AxiosContainer";
import { LoginProvider } from "./context/LoginContext";
import EditTheoryPolar from "./pages/EditTheoryPolar";
import ManagementMember from "./pages/ManagementMember";
import ManagementResearcher from "./pages/ManagementResearcher";
import ResearcherTask from "./pages/ResearcherTask";
import MainResearcher from "./pages/MainResearcher";
import ManagaCanvasContainer from "./pages/ManageCavnasContainer";
import AuthProvider from "./context/AuthContext";
import { userTypes } from "./key/userTypes";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { signOut } from "./redux/slice/login/loginInfo";
import CreateCaseAdmin from "./pages/CreateCaseAdmin";
import CreateCaseResearcher from "./pages/CreateCaseResearcher";
import CreateNewTheoryPolar from "./pages/CreateNewTheoryPolar";
import Member from "./pages/Member";
import ReassignTheoryPolar from "./pages/ReassignTheoryPolar";
import UserRank from "./pages/UserRank";
import SignUpFinishPage from "./pages/SignUpFinishPage";
import SignUpPage from "./pages/SignUpPage";
import SignUpSendEmail from "./pages/SignUpSendEmail";
import ChangePassword from "./components/login/ChangePassword";
import ChangePasswordSendEmail from "./components/login/ChangePasswordSendEmail";
import ResendEmail from "./components/login/ResendEmail";
// import ManagaCanvasContainer from './MindMap/ManageCanvas/ManageCanvasContainer';

const AppRouter = () => {
  const navigate = useNavigate();
  const { isLogin, roleType } = useSelector((state) => state.loginInfo);
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  // C모듈 점수 확인 페이지에서 새로고침하면 Main 으로 라우팅 되는 현상때문에 주석처리
  useEffect(() => {
    // const isLogin = sessionStorage.getItem('persist:root') ? true : false;
    if (roleType === userTypes.none || !roleType) {
      pathname.includes("/SignUpFinish")
        ? navigate(getRedirectUrl(roleType, pathname))
        : dispatch(signOut());
    }
    if (isLogin) {
      navigate(getRedirectUrl(roleType));
    } else {
      dispatch(signOut());
    }
  }, []);

  return (
    <AxiosProvider>
      <LoginProvider>
        <Routes>
          <Route path="/" element={<App />}>
            <Route exact path="/" element={<Login />} />
            <Route path="/SignUpFinish/:id" element={<SignUpFinishPage />} />
            <Route exact path="/SignUp" element={<SignUpPage />} />
            <Route
              exact
              path="/SignUpSendEmail"
              element={<SignUpSendEmail />}
            />
            <Route
              exact
              path="/ChangePasswordEmail"
              element={<ChangePasswordSendEmail />}
            />
            <Route
              exact
              path="/ChangePassword/:id"
              element={<ChangePassword />}
            />

            <Route exact path="/ResendEmail" element={<ResendEmail />} />
            <Route exact path="/Main" element={<Main />} />
            {/* <Route exact path="/Main" element={<FinishTest />} /> */}
            <Route exact path="/MainR" element={<MainResearcher />} />

            <Route exact path="/Test" element={<InfoTest />} />
            <Route exact path="/StartTest" element={<StartTest />} />

            <Route exact path="/Intro" element={<Intro />} />
            <Route exact path="/Rank" element={<UserRank />} />
            <Route exact path="/TestPage" element={<TestPage />} />
            {/* <Route exact path='/ManageCanvas' element={<ManageCanvas />} /> */}
            {/* <Route exact path='/ManageCanvas3' element={<ManageCanvas3 />} /> */}

            <Route
              exact
              path="/manageCanvas_polar"
              element={<ManagaCanvasContainer />}
            />
            <Route exact path="/admin" element={<AdminPage />} />
            {/* <Route exact path='/Trans' element={<Trans_deg_to_polar />} /> */}
            <Route path="/admin">
              <Route exact path="edit" element={<EditTheoryPolar />} />
              <Route exact path="create" element={<CreateNewTheoryPolar />} />
              <Route exact path="rearrange" element={<ReassignTheoryPolar />} />
              <Route
                exact
                path="managementMember"
                element={<ManagementMember />}
              />
              <Route
                exact
                path="managementResearcher"
                element={<ManagementResearcher />}
              />
              <Route
                exact
                path="addTaskResearcher"
                element={<ResearcherTask />}
              />
              <Route exact path="createCaseA" element={<CreateCaseAdmin />} />
              <Route
                exact
                path="createCaseR"
                element={<CreateCaseResearcher />}
              />
              <Route exact path="member/:id" element={<Member />} />
            </Route>
          </Route>
        </Routes>
      </LoginProvider>
    </AxiosProvider>
  );
};

export default AppRouter;

function getRedirectUrl(userType, pathname) {
  let url;
  switch (userType) {
    case userTypes.user:
      url = pathname;
      break;
    case userTypes.tester:
      url = pathname;
      break;
    case userTypes.admin:
      url = pathname;
      break;
    case userTypes.none:
      url = pathname;
      break;
    default:
      alert("허용된 경로가 아닙니다. 로그인 페이지로 이동합니다.");
      break;
  }
  return url;
}
