export class Category {
  #id;
  #degree;
  #depth;
  #text;
  #categoryId; // 1~
  #screenCoord; // 모니터 좌표
  #startAngle;
  #endAngle;

  constructor(text) {
    this.#id = Date.now() + Math.random();
    this.#depth = 10;
    this.#degree = 50;
    this.#text = text;
  }

  // Getter & Setter
  getId() {
    return this.#id;
  }
  setId(id) {
    this.#id = id;
  }
  setCategoryId(categoryId) {
    this.#categoryId = categoryId;
  }

  getCategoryId() {
    return this.#categoryId;
  }

  getDepth() {
    return this.#depth;
  }

  setDegree(degree) {
    this.#degree = degree;
  }

  getDegree() {
    return this.#degree;
  }

  setText(text) {
    this.#text = text;
  }
  getText() {
    return this.#text;
  }

  setScreenCoord(screenCoord) {
    this.#screenCoord = screenCoord;
  }

  getScreenCoord() {
    return this.#screenCoord;
  }

  getCircleCoord() {
    return {
      degree: this.#degree,
      depth: this.#depth,
    };
  }

  getAngles() {
    return {
      startAngle: this.#startAngle,
      endAngle: this.#endAngle,
    };
  }

  setAngles(angles) {
    this.#startAngle = angles.startAngle;
    this.#endAngle = angles.endAngle;
  }
}

export class DBCategory {
  #id;
  #degree;
  #depth;
  #text;
  #categoryId; // 1~
  #screenCoord; // 모니터 좌표
  #startAngle;
  #endAngle;

  constructor(dbCategory) {
    this.#id = dbCategory.answerCategory + dbCategory.degree + dbCategory.depth;
    this.#categoryId = dbCategory.answerCategory;
    this.#text = dbCategory.text;
    this.#depth = dbCategory.depth;
    this.#degree = dbCategory.degree;
  }

  getId() {
    return this.#id;
  }

  getText() {
    return this.#text;
  }

  getCategoryId() {
    return this.#categoryId;
  }

  getDepth() {
    return this.#depth;
  }

  getDegree() {
    return this.#degree;
  }

  getCircleCoord() {
    return {
      degree: this.#degree,
      depth: this.#depth,
    };
  }

  getAngles() {
    return {
      startAngle: this.#startAngle,
      endAngle: this.#endAngle,
    };
  }

  setAngles(angles) {
    this.#startAngle = angles.startAngle;
    this.#endAngle = angles.endAngle;
  }

  setScreenCoord(screenCoord) {
    this.#screenCoord = screenCoord;
  }

  getScreenCoord() {
    return this.#screenCoord;
  }
}
