import React from "react";
import styles from "./CaseName.module.css";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

export default function CaseName({ caseList, idx }) {
  const navigate = useNavigate();
  return (
    <header className={`${styles.header}`}>
      <p>
        <span style={{ color: "#3D5398", fontSize: "0.875rem" }}>
          TEST {caseList?.[idx]?.caseIndex + 1}.{" "}
        </span>
        <span style={{ fontSize: "0.875rem" }}>
          {caseList && caseList[idx]?.caseName}
        </span>
        <Button
          className={`${styles.resultBtn}`}
          onClick={() => {
            navigate("/Rank", {
              state: {
                totalOn: true,
                caseId: caseList?.[idx]?.caseId,
              },
            });
            console.log(caseList?.[idx]?.caseId);
          }}
        >
          결과 보기
        </Button>
      </p>
    </header>
  );
}
