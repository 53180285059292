export function classification(xlsxData) {
  const range = [];

  const theories = [];
  const category = xlsxData
    .filter((cell) => cell.hasOwnProperty('카테고리'))
    .map(refineCategory);
  const theory = xlsxData.filter((cell) => !cell.hasOwnProperty('카테고리'));
  category.forEach((c, idx) => {
    range[idx] = { start: c.rowNum };
    if (idx - 1 >= 0)
      range[idx - 1] = {
        ...range[idx - 1],
        end: c.rowNum - 1,
      };
  });

  theory.forEach((t) => {
    const refinedTheory = refineTheory(t); // 2, 3, 4, 5
    const categoryId = getCategoryId(t, range); // 0 1 2
    if (!theories[categoryId]) theories[categoryId] = [];
    // if (!theories[categoryId][depthNum - 2])
    // theories[categoryId][depthNum - 2] = [];
    // if (!refineTheory.text) {
    // return;
    // }
    // console.log(refinedTheory);
    if (!refinedTheory?.text) return;
    theories[categoryId] = [
      ...theories[categoryId],
      { ...refinedTheory, categoryId },
    ];
  });

  return {
    theory: theories,
    category,
  };
}

export function refineCategory(category, idx) {
  return {
    text: category['카테고리'],
    rowNum: category.__rowNum__,
    categoryId: idx,
    // categoryId: idx + 1,
  };
}

export function refineTheory(data) {
  const defaults = {
    depth: 2,
    rowNum: data.__rowNum__,
  };
  if (data.hasOwnProperty('__EMPTY')) {
    return {
      ...defaults,
      depth: 2,
      text: data.__EMPTY.trim(),
    };
  }
  if (data.hasOwnProperty('__EMPTY_1')) {
    return {
      ...defaults,
      depth: 3,
      text: data.__EMPTY_1.trim(),
    };
  }
  if (data.hasOwnProperty('__EMPTY_2')) {
    return {
      ...defaults,
      depth: 4,
      text: data.__EMPTY_2.trim(),
    };
  }
  if (data.hasOwnProperty('__EMPTY_3')) {
    return {
      ...defaults,
      depth: 5,
      text: data.__EMPTY_3.trim(),
    };
  }
}

export function getCategoryId(data, range) {
  let result;
  range.forEach((r, idx) => {
    const rowNum = data.__rowNum__;
    if (r.start <= rowNum) {
      if (r.end && rowNum <= r.end) {
        result = idx;
        return;
      }
      result = idx;
    }
  });
  return result;
}

export function convertTheoriesForPost(categories, theories) {
  const categoryData = categories.map((category) => ({
    answerCategory: category.getCategoryId() + 1,
    degree: category.getDegree(),
    depth: category.getDepth(),
    text: category.getText(),
  }));
  const theoryData = theories.map((theory) => ({
    answerCategory: theory.getCategoryId() + 1,
    degree: theory.getDegree(),
    depth: theory.getDepth(),
    text: theory.getText(),
  }));
  return [...categoryData, ...theoryData];
}
