import React, { forwardRef } from "react";
import { useMutation } from "@tanstack/react-query";
import { useAxiosContext } from "../../../../context/AxiosContainer";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAnswersState,
  finishLastAnswer,
} from "../../../../redux/slice/quizAnswer/quizAnswer";
import {
  signOut,
  updateAccessToken,
} from "../../../../redux/slice/login/loginInfo";
import { useNavigate } from "react-router-dom";

const NextButton = forwardRef((_, ref) => {
  const { httpInterface } = useAxiosContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accessToken } = useSelector((state) => state.loginInfo);
  const { answersForOneQuestion } = useSelector((state) => state.answers);
  const answerList = setAnswerList(answersForOneQuestion);
  console.log(_);

  return (
    <button
      ref={ref}
      disabled={answerList.length < 10}
      onClick={() => {
        if (answerList.length < 10) {
          alert("최소 10개의 답변이 필요합니다.");
          //Modal로 바꿀까?
          return;
        }
        dispatch(finishLastAnswer());
        dispatch(clearAnswersState());
        /* console.log(quiz);
        console.log(setSolvedTime(answersForOneQuestion)); */

        // mutate(accessToken, quiz?.subQuestionId, answersForOneQuestion);
      }}
    >
      제출
    </button>
  );
});
export default NextButton;

export function setAnswerList(answersData) {
  return answersData.filter((data) => !!data.text).map((data) => data.text);
}

export function setSolvedTime(answersData) {
  return answersData
    .filter((data) => !!data.endTime)
    .map((data) => (data.endTime - data.startTime) / 1000);
}
