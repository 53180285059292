import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import styles from "../../styles/RankDropdown.module.css";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

export default function RankDropdown({
  dropdownCaseNameList,
  setDropdownCaseNameSearch,
}) {
  const isPc = useMediaQuery({
    query: "(min-width:768px)",
  });

  const [dropdown, setDropdown] = useState(
    dropdownCaseNameList && dropdownCaseNameList[0].caseName
  );
  //console.log(dropdown, dropdownCaseName);

  return (
    <>
      <Dropdown as={ButtonGroup} className={styles.Dropdown}>
        <Dropdown.Toggle
          id="dropdown-split-basic"
          className={styles.DropdownContent}
        >
          {dropdown ? dropdown : dropdownCaseNameList?.[0].caseName}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {dropdownCaseNameList?.map((a, i) => {
            return (
              <>
                <Dropdown.Item
                  className={styles.Dropdown}
                  eventKey={i + 1}
                  key={i}
                  onClick={() => {
                    console.log(dropdownCaseNameList[i]);
                    setDropdownCaseNameSearch(dropdownCaseNameList[i].caseName);
                  }}
                >
                  {dropdownCaseNameList[i].caseName}
                </Dropdown.Item>
              </>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
