import Button from 'react-bootstrap/esm/Button';
import styles from '../../../styles/MainResearcher.module.css';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { updateCurrentTaskInfo } from '../../../redux/slice/taskInfo/taskInfo';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { useAxiosContext } from '../../../context/AxiosContainer';
import {
  signOut,
  updateAccessToken,
} from '../../../redux/slice/login/loginInfo';

export default function TestTableRow({ taskInfo }) {
  const dispatch = useDispatch();
  const {
    category,
    level,
    status,
    title,
    deadLine,
    taskId,
    finishCount,
    reqAnswers,
  } = taskInfo;
  console.log(taskInfo);
  const { accessToken } = useSelector((state) => state.loginInfo);
  let navigate = useNavigate();
  const { httpInterface } = useAxiosContext();

  const { mutate } = useMutation(
    () => {
      return httpInterface.getQuizData(accessToken, { params: { taskId } });
    },
    {
      onSuccess: (res) => {
        console.log(res.data.data[0]);
      },
      onError: (error) => {
        const { status } = error.response;
        console.log('Main Component Error', error);
        if (status === 401) {
          // reissue Token 관련 코드 작성할 것
          httpInterface
            .reissueToken(accessToken)
            .then((response) => {
              console.log(response.data.data[0]);
              dispatch(
                updateAccessToken({
                  accessToken: response.data.data[0].accessToken,
                })
              );
            })
            .catch((error) => {
              console.log('Refresh Token Error');
              alert('유효정보가 만료되어 로그아웃되었습니다.');
              dispatch(signOut());
            });
        } else {
          console.log('Validation Token Error');
          alert('유효정보가 만료되어 로그아웃되었습니다.');
          dispatch(signOut());
        }
      },
    }
  );

  return (
    <tr className={styles.QuizTable}>
      <td>{category}</td>
      <td>{level}</td>
      <td>{title}</td>
      <td>{deadLine}</td>
      <td>{status}</td>
      <td>
        {finishCount}/{reqAnswers}
      </td>
      <td>
        <Button
          className={styles.BatchCreateBtn}
          onClick={() => {
            mutate();
            dispatch(updateCurrentTaskInfo({ currentTaskInfo: taskInfo }));
            navigate('/StartTest');
          }}
        >
          테스트 시작
        </Button>
      </td>
    </tr>
  );
}
