import React from 'react';
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import styles from './AddPopup.module.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  addCategory,
  addTheory,
  clearCurrentData,
  hidePopup,
} from '../../../redux/slice/theory/createTheory';
import { theoryMode } from '../../../key/theoryMode';
import { Category } from '../../../api/theory/category';
import { Theory } from '../../../api/theory/theory';
import { useAxiosContext } from '../../../context/AxiosContainer';
import {
  signOut,
  updateAccessToken,
} from '../../../redux/slice/login/loginInfo';

// TODO: 실시간 좌표 저장 로직 구현
export default function AddPopup({ show }) {
  const [text, setText] = useState('');
  const { mode, currentData, subQuestionId } = useSelector(
    (state) => state.createTheory
  );
  const dispatch = useDispatch();
  const { httpInterface } = useAxiosContext();
  const { accessToken } = useSelector((state) => state.loginInfo);

  const onSubmitCategory = (e) => {
    e.preventDefault();
    if (!text.trim().length) {
      alert('1글자 이상 입력해주세요');
      return;
    }
    // setPopUpInput(text.trim());
    if (mode === theoryMode.createCategory) {
      // 카테고리 저장 로직
      const category = new Category(text.trim());
      dispatch(addCategory({ category }));
      console.log(setTheoryRequest(category));
      httpInterface
        .postTheoryData(accessToken, {
          subQuestionId,
          defaultBatch: [setTheoryRequest(category)],
        })
        .then((res) => {
          const id = res.data.data[0].id;
          category.setId(id);
        })
        .catch((error) => {
          const { status } = error.response;
          console.log('Main Component Error', error);
          if (status === 401) {
            // reissue Token 관련 코드 작성할 것
            httpInterface
              .reissueToken(accessToken)
              .then((response) => {
                const accessToken = response.data.data[0].accessToken;
                httpInterface.postTheoryData(accessToken, {
                  subQuestionId,
                  defaultBatch: [setTheoryRequest(category)],
                });
                dispatch(
                  updateAccessToken({
                    accessToken,
                  })
                );
              })
              .catch((error) => {
                console.log('Refresh Token Error');
                alert('유효정보가 만료되어 로그아웃되었습니다.');
                dispatch(signOut());
              });
          } else {
            console.log('Validation Token Error');
            alert('유효정보가 만료되어 로그아웃되었습니다.');
            dispatch(signOut());
          }
        });
    } else if (mode === theoryMode.createTheory) {
      // 가설좌표 저장 로직
      const theory = new Theory(Math.random(), text.trim());
      theory.setCategoryId(currentData.categoryId);
      theory.setDegree(currentData.degree);
      theory.setDepth(currentData.depth);
      theory.setScreenCoord(currentData.screenCoord);
      console.log(setTheoryRequest(theory));
      // Test
      // dispatch(addTheory({ theory }));
      // dispatch(clearCurrentData());

      // RealCode
      httpInterface
        .postTheoryData(accessToken, {
          subQuestionId,
          defaultBatch: [setTheoryRequest(theory)],
        })
        .then((res) => {
          console.log(res);
          const id = res.data.data[0].id;
          theory.setId(id);
          dispatch(addTheory({ theory }));
          dispatch(clearCurrentData());
        })
        .catch((error) => {
          console.log(error);
          const status = error.response.status;
          console.log('Main Component Error', error);
          if (status === 401) {
            // reissue Token 관련 코드 작성할 것
            httpInterface
              .reissueToken(accessToken)
              .then((response) => {
                const accessToken = response.data.data[0].accessToken;
                httpInterface.postTheoryData(accessToken, {
                  subQuestionId,
                  defaultBatch: [setTheoryRequest(theory)],
                });
                dispatch(
                  updateAccessToken({
                    accessToken,
                  })
                );
              })
              .catch((error) => {
                console.log('Refresh Token Error');
                alert('유효정보가 만료되어 로그아웃되었습니다.');
                dispatch(signOut());
              });
          } else {
            console.log('Validation Token Error');
            alert('유효정보가 만료되어 로그아웃되었습니다.');
            dispatch(signOut());
          }
        });
    }
    dispatch(hidePopup());
  };

  const handleChange = (e) => {
    e.preventDefault();
    setText(e.target.value);
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        dispatch(hidePopup());
      }}
      style={{
        display: 'block',
        transform: `translate(200px, 200px)`,
        width: '300px',
      }}
    >
      <Modal.Body>
        <Form>
          <Form.Control
            as='textarea'
            rows={2}
            className={styles['popup-input']}
            autoFocus
            onChange={handleChange}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer className={styles['button-container']}>
        <Button variant='secondary' onClick={onSubmitCategory}>
          확인
        </Button>
        <Button
          variant='primary'
          onClick={() => {
            dispatch(hidePopup());
          }}
        >
          취소
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const setTheoryRequest = (catOrTheory) => {
  console.log(catOrTheory);
  const { degree, depth } = catOrTheory.getCircleCoord();
  return {
    answerCategory: catOrTheory.getCategoryId(),
    degree,
    depth,
    text: catOrTheory.getText(),
  };
};
