import { createSlice } from '@reduxjs/toolkit';

const xlsxCreateTheory = createSlice({
  name: 'xlsxCreate',
  initialState: {
    xlsxTheory: [],
    xlsxCategory: [],
    theoryTreeManager: null,
    theoryList: [],
    categoryList: [],
  },
  reducers: {
    setXlsxData: (state, action) => {
      state.xlsxTheory = action.payload.xlsxTheory;
      state.xlsxCategory = action.payload.xlsxCategory;
    },
    setTheoryTreeManager: (state, action) => {
      state.theoryTreeManager = action.payload.theoryTreeManager;
    },
    setTheoryList: (state, action) => {
      state.theoryList = action.payload.theoryList;
    },
    setCategoryList: (state, action) => {
      state.categoryList = action.payload.categoryList;
    },
    clearXlsxCreate: (state) => {
      state.xlsxTheory = [];
      state.xlsxCategory = [];
      state.theoryTreeManager = null;
      state.theoryList = [];
      state.categoryList = [];
    },
  },
});

export const {
  setXlsxData,
  clearXlsxCreate,
  setTheoryTreeManager,
  setTheoryList,
  setCategoryList,
} = xlsxCreateTheory.actions;

export default xlsxCreateTheory.reducer;
