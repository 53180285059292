import React, { useCallback, useEffect } from 'react';
import TheoryGraph from './theoryGraph/TheoryGraph';
import { ManageTheoryCanvas } from '../../../api/d3/d3';
import { gradientColor } from './color';
import * as d3 from '../../../MindMap/js/d3';
import { useDispatch, useSelector } from 'react-redux';
import { TheoryCalculator } from '../../../api/theory/theoryCalculator';
import {
  assignItem,
  reassignItem,
} from '../../../redux/slice/theory/manageTheory';
import { DBTheory } from '../../../api/theory/theory';

export default function ManageTheory({ categoryList, theoryList }) {
  const { selectedItem, assignedItemIds, assignedItems } = useSelector(
    (state) => state.manageTheory
  );

  const dispatch = useDispatch();
  const drawCanvas = new ManageTheoryCanvas();

  useEffect(() => {
    initSetting();
    drawCanvas.drawCategories(categoryList);
    drawCanvas.drawTheories([...theoryList, ...assignedItems]);
  }, [categoryList, theoryList, selectedItem, assignedItems]);

  const initSetting = () => {
    // 화면 지우고 다시 그리는 기본적인 함수
    drawCanvas.removeCanvas();
    drawCanvas.drawGraph(gradientColor, clickTheory, categoryList);
  };

  const clickTheory = (e) => {
    if (!selectedItem) {
      alert('선택된 아이템이 없습니다.');
      return;
    }
    const calculator = new TheoryCalculator();
    e.preventDefault();
    const pointer = d3.pointers(e, this);
    const [x, y] = pointer[0];
    const { categoryId, depth, degree, screenCoord } =
      calculator.getClickCoordInfo({ x, y }, categoryList);

    if (isReassignData(selectedItem, assignedItemIds)) {
      // 재배치
      console.log(depth, degree, screenCoord);
      const theory = new DBTheory(selectedItem);
      theory.setDepth(depth);
      theory.setDegree(degree);
      theory.setCategoryId(categoryId);
      theory.setScreenCoord(screenCoord);
      dispatch(
        reassignItem({
          selectedItem: theory,
        })
      );
    } else {
      // 배치
      const theory = new DBTheory(selectedItem);
      theory.setDepth(depth);
      theory.setDegree(degree);
      theory.setCategoryId(categoryId);
      theory.setScreenCoord(screenCoord);
      dispatch(
        assignItem({
          selectedItem: theory,
        })
      );
    }
  };

  if (categoryList.length < 0) return;

  return (
    <>
      <TheoryGraph />
    </>
  );
}

function isReassignData(selectedItem, assignItemIds) {
  if (!selectedItem) return false;
  const id = assignItemIds.find((id) => id === selectedItem.psAnswerId);
  if (id) {
    return true;
  } else {
    return false;
  }
}
