import React, { forwardRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setAnswerList, setSolvedTime } from "./NextButton";
import { useAxiosContext } from "../../../../context/AxiosContainer";
import { useMutation } from "@tanstack/react-query";
import { Button } from "react-bootstrap";
import styles from "../AnswerInput.module.css";
import Modal from "react-bootstrap/Modal";

const SubmitButton = forwardRef((props, ref) => {
  const { httpInterface } = useAxiosContext();
  const navigate = useNavigate();
  const { accessToken } = useSelector((state) => state.loginInfo);
  const { answersForOneQuestion } = useSelector((state) => state.answers);
  const answerList = setAnswerList(answersForOneQuestion);

  const [modalOpen, setModalOpen] = useState(false);

  const { mutate, isLoading, isError } = useMutation((goRank, goNextTest) => {
    console.log(goRank, goNextTest, mutate);
    httpInterface
      .getNextQuizData(accessToken, {
        subQuestionId: props.subQuestionId,
        answerList: answerList.map((a, i) => ({
          solvedTime: setSolvedTime(answersForOneQuestion)[i],
          body: answerList[i],
        })),
      })
      .then((res) => {
        console.log(res);
        goRank
          ? navigate("/Rank", {
              state: {
                idx: location.state.data.subNumber,
                caseId: props.caseId,
                nextSubQuestionId: res.data.data[0].nextSubQuestionId,
              },
            })
          : httpInterface
              .getGotoStartTest(
                "/v2/api/caseps/" + res.data.data[0].nextSubQuestionId,
                accessToken
              )
              .then((res) => {
                console.log(res);
                console.log(location.state);
                navigate("/StartTest", {
                  state: {
                    data: res.data.data[0],
                    caseId: location.state.nextCaseId,
                    continuity: true,
                  },
                });
              })
              .catch((error) => {
                console.log("error : ", error.response);
              });
      })
      .catch((error) => {
        console.log("error : ", error.response);
      });
  });
  let location = useLocation();
  return (
    <>
      <Button
        className={
          answerList.length < 10 ? styles.SubmitBtnOff : styles.SubmitBtn
        }
        disabled={answerList.length < 10}
        ref={ref}
        onClick={() => {
          console.log("제출버튼");
          setModalOpen(true);
          //dispatch(finishLastAnswer());
          //dispatch(clearAnswersState());
        }}
      >
        제출
      </Button>
      <Modal
        show={modalOpen}
        onHide={() => setModalOpen(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <span>
            제출한 답변의 분석 결과를 확인하거나
            <br />
            계속해서 문제를 풀 수 있어요.
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={async () => {
              mutate(true);
              setModalOpen(false);
            }}
          >
            결과 보기
          </Button>
          <Button
            onClick={() => {
              mutate(false);
              setModalOpen(false);
            }}
          >
            다음 문제 풀기
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
});
export default SubmitButton;
