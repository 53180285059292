import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { modifyTheory } from '../../../../redux/slice/theory/createTheory';
import styles from './NodeList.module.css';
import { useAxiosContext } from '../../../../context/AxiosContainer';

export default function EditTheoryInput() {
  const {
    currentTarget: { theory },
    subQuestionId,
  } = useSelector((state) => state.createTheory);
  const { accessToken } = useSelector((state) => state.loginInfo);
  const { httpInterface } = useAxiosContext();
  const [text, setText] = useState(theory.getText());
  const dispatch = useDispatch();
  //TODO: 실시간 좌표 변경
  const changeHandler = (e) => {
    e.preventDefault();
    setText(e.target.value);
  };

  return (
    <>
      <input
        className={`${styles.edit}`}
        value={text}
        onChange={changeHandler}
        autoFocus
      />
      <button
        onClick={() => {
          theory.setText(text);
          dispatch(modifyTheory());
          // TODO: 실사간 좌표 변경 하기
          console.log('edit theory input');
          httpInterface
            .putModifyTheoryData(
              accessToken,
              [
                {
                  id: theory.getId(),
                  text: theory.getText(),
                },
              ],
              {
                params: { subQuestionId },
              }
            )
            .then((res) => {
              console.log(res);
            })
            .catch((error) => {
              console.log('EditTheoryInput Error' - error);
            });
        }}
      >
        변경
      </button>
    </>
  );
}
