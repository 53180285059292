import { TheoryCalculator } from './theoryCalculator';
import { Calculator } from './theoryTree';

export class TheoryManagement {
  #theoryList = [];
  #maxCategoryId = 2; //0, 1, 2

  getMaxCategoryId() {
    return this.#maxCategoryId;
  }

  createNewCategoryId() {
    this.#maxCategoryId += 1;
  }

  getTheoryList() {
    return this.#theoryList;
  }

  setTheoryList(theoryList) {
    this.#theoryList = theoryList;
  }

  addTheory(theory) {
    this.#theoryList.push(theory);
  }

  #getTheoryIdx(theory) {
    const idx = this.#theoryList.findIndex((item) => {
      return item.getId() === theory.getId();
    });
    return idx;
  }

  removeTheory(theory) {
    const removedIdx = this.#getTheoryIdx(theory);
    this.#theoryList = this.#theoryList.filter((_, idx) => idx !== removedIdx);
  }
}

export class CreateTheoryManager extends TheoryManagement {
  #categoryList = [];
  #categoryCount = 0;
  #calculator = new TheoryCalculator();

  getCategoryList() {
    return this.#categoryList;
  }
  setCategoryList(categoryList) {
    this.#categoryList = categoryList;
    this.#categoryCount = categoryList.length;
  }

  getCategoryCount() {
    return this.#categoryCount;
  }

  getSortedTheoryListByCategoryId() {
    return this.getTheoryList().reduce((acc, cur) => {
      const categoryId = cur.getCategoryId();
      if (acc[categoryId - 1])
        acc[categoryId - 1] = [...acc[categoryId - 1], cur];
      else acc[categoryId - 1] = [cur];
      return acc;
    }, []);
  }

  addCategory(category) {
    category.setCategoryId(this.#categoryCount + 1);
    this.#categoryCount += 1;
    console.log(this.#categoryList);
    this.#categoryList.push(category);
    console.log(this.#categoryList);
    // console.log(category, this.#categoryList);
    this.#categoryList.forEach((category) => {
      category.setAngles(
        this.#calculator.calcAngles(
          category.getCategoryId(),
          this.#categoryCount
        )
      );
      category.setScreenCoord(
        this.#calculator.calculateCategoryScreenCoordByCircleCoord(
          category,
          this.#categoryCount
        )
      );
    });

    // 가설좌표도 따라서 변하게 하기
    this.getTheoryList().forEach((theory) => {
      const category = this.#categoryList.find(
        (cat) => cat.getCategoryId() === theory.getCategoryId()
      );

      theory.setScreenCoord(
        this.#calculator.getScreenCoordByDegreeAndDepth(
          category,
          theory.getDegree(),
          theory.getDepth()
        )
      );
    });
  }

  getRemovedTheoryIds(categoryId) {
    return this.getTheoryList()
      .filter((theory) => theory.getCategoryId() === categoryId)
      .map((theory) => theory.getId());
  }

  getChangedCategoriesTheoriesByRemovingCategory(removedCategoryId) {
    const changedCategoryLists = this.#categoryList
      .filter((category, idx) => category.getCategoryId() > removedCategoryId)
      .map((category) => ({
        id: category.getId(),
        answerCategory: category.getCategoryId() - 1,
      }));
    const changedTheoryLists = this.getTheoryList()
      .filter((theory) => theory.getCategoryId() > removedCategoryId)
      .map((theory) => ({
        id: theory.getId(),
        answerCategory: theory.getCategoryId() - 1,
      }));

    console.log(changedCategoryLists);
    console.log(changedTheoryLists);
  }

  removeCategory(categoryId) {
    const removedId = this.#categoryList
      .find((category) => category.getCategoryId() === categoryId)
      ?.getCategoryId();

    // TODO: 삭제 로직
    this.#categoryList = this.#categoryList.filter(
      (category) => category.getCategoryId() !== removedId
    );

    const filteredTheory = this.getTheoryList().filter((theory) => {
      return theory.getCategoryId() !== removedId;
    });

    this.#categoryCount -= 1;

    this.#categoryList.forEach((category, idx) => {
      const categoryId = category.getCategoryId();
      if (categoryId < removedId) return;
      category.setCategoryId(categoryId - 1);
    });

    this.#categoryList.forEach((category) => {
      const circleCoord = this.#calculator.calcAngles(
        category.getCategoryId(),
        this.#categoryCount
      );
      category.setAngles(circleCoord);
      const screenCoord =
        this.#calculator.calculateCategoryScreenCoordByCircleCoord(category);
      category.setScreenCoord(screenCoord);
    });
    // this.#categoryList = [...this.#categoryList];

    filteredTheory.forEach((theory, idx) => {
      const categoryId = theory.getCategoryId();
      if (categoryId < removedId) return;
      theory.setCategoryId(categoryId - 1);
    });

    filteredTheory.forEach((theory) => {
      const screenCoord =
        this.#calculator.calculateTheoryScreenCoordByCircleCoord(
          theory,
          this.#categoryList
        );
      theory.setScreenCoord(screenCoord);
    });
    this.setTheoryList(filteredTheory);
  }
}

export class XlsxTheoryManager extends TheoryManagement {
  #categoryList = [];
  #categoryCount = 0;
  #theoryList = [];
  #calculator = new Calculator();

  setCategoryList(categoryList) {
    this.#categoryList.forEach((category) => {
      const screenCoord =
        this.#calculator.calculateCategoryScreenCoordByCircleCoord(category);
      category.setScreenCoord(screenCoord);
    });
    this.#categoryList = categoryList;
  }
}

export class ManageTheoryManager extends TheoryManagement {
  #categoryList = [];
  #categoryCount = 0;
  #calculator = new TheoryCalculator();

  getCategoryList() {
    return this.#categoryList;
  }
  setCategoryList(categoryList) {
    this.#categoryList = categoryList;
    this.#categoryCount = categoryList.length;
  }

  getSortedTheoryListByCategoryId() {
    return this.getTheoryList().reduce((acc, cur) => {
      const categoryId = cur.getCategoryId();
      if (acc[categoryId - 1])
        acc[categoryId - 1] = [...acc[categoryId - 1], cur];
      else acc[categoryId - 1] = [cur];
      return acc;
    }, []);
  }
}
