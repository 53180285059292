import React, { useState } from "react";
import styles from "../styles/Member.module.css";
import { useLocation } from "react-router-dom";

export default function Member() {
  let location = useLocation();
  const [data, setDate] = useState(location.state.data);
  console.log(data);

  return (
    <>
      <div className={styles.Container}>
        <span style={{ marginRight: "5%", fontSize: "1.5em" }}>
          <b>{data?.name}</b>
        </span>
        <span style={{ marginRight: "5%" }}>
          <b>
            <ins>상세조회</ins>
          </b>
        </span>
        <span>세부활동내역</span>
      </div>
    </>
  );
}
