import React, { useEffect } from 'react';
import TheoryGraph from '../theoryGraph/TheoryGraph';
import { useDispatch, useSelector } from 'react-redux';
import { TheoryTree, TheoryTreeManager } from '../../../api/theory/theoryTree';
import {
  clearXlsxCreate,
  setTheoryTreeManager,
} from '../../../redux/slice/theory/xlsxCreateTheory';
import XlsxTheory from './XlsxTheory';

export default function XlsxTheoryData() {
  const { xlsxCategory, xlsxTheory } = useSelector((state) => state.xlsxTheory);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!xlsxCategory?.length) return;
    if (!xlsxTheory?.length) return;

    // 엑셀 좌표 데이터 -> 가설좌표 관리 데이터로
    const theoryTreeManager = new TheoryTreeManager();
    xlsxCategory.forEach((c) => {
      const tree = new TheoryTree(c);
      theoryTreeManager.addTheoryTree(tree);
    });
    xlsxTheory.forEach((items, idx) => {
      const tree = theoryTreeManager.getTheoryTree(idx);
      items.forEach((theory) => {
        tree.addTheory(theory);
      });
    });
    theoryTreeManager.calculateCategoryProportion();
    dispatch(setTheoryTreeManager({ theoryTreeManager }));

    return () => {
      dispatch(clearXlsxCreate());
    };
  }, [xlsxCategory, xlsxTheory]);

  return (
    <>
      <XlsxTheory />
    </>
  );
}

// function convertXlsxToInstance(xlsxData) {}
