import "../language/i18n";
import styles from "../styles/App.module.css";
import LoginLogo from "../components/login/LoginLogo";
import LoginForm from "../components/login/LoginForm";
import { useMediaQuery } from "react-responsive";

export default function Login() {
  const isPc = useMediaQuery({
    query: "(min-width:768px)",
  });
  return (
    <>
      <div className={isPc ? styles.AppPc : styles.App}>
        <LoginLogo />
        <LoginForm isPc={isPc} />
      </div>
    </>
  );
}
