import React, { useState } from "react";
import styles from "../../../styles/CreateCase.module.css";
import DropdownCountry from "../../managementMember/DropdownCountry";

export default function Language({
  temporaryStorage,
  setLanguageChoose,
  languageChoose,
  excelData,
  idx,
}) {
  return (
    <div className={styles.ChoiceTask}>
      <div className={styles.DivBox}>
        <span>언어*</span>
      </div>
      <div className={styles.Test}>
        <DropdownCountry
          setLanguageChoose={setLanguageChoose}
          researcherTF={true}
          createCase={true}
          leave={temporaryStorage && temporaryStorage[0]}
          excelData={excelData.length > 0 && excelData}
          idx={idx}
          languageChoose={languageChoose}
        />
      </div>
    </div>
  );
}
