import { useTranslation } from "react-i18next";
import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import styles from "../../../styles/Header.module.css";
import Nav from "react-bootstrap/Nav";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signOut } from "../../../redux/slice/login/loginInfo";

export default function SideBar({
  navigateToSolveProblem,
  isShowSidebar,
  setIsShowSidebar,
}) {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const closeSidebarHandler = () => {
    setIsShowSidebar(false);
  };
  const signOutHandler = (e) => {
    closeSidebarHandler();
    navigate("/");
    dispatch(signOut());
  };

  const navigateToTestPage = (e) => {
    navigateToSolveProblem();
    closeSidebarHandler();
  };

  return (
    <Offcanvas
      show={isShowSidebar}
      onHide={closeSidebarHandler}
      placement="end"
    >
      <Offcanvas.Header className={styles.Close} closeButton></Offcanvas.Header>
      <Offcanvas.Body>
        <Nav className="flex-column">
          <Nav.Link className={styles.SideList} onClick={signOutHandler}>
            {t("logout")}
          </Nav.Link>
          <Nav.Link className={styles.SideList}>{t("changingInfo")}</Nav.Link>
          <br />
          <Nav.Link className={styles.SideList} onClick={navigateToTestPage}>
            {t("solveQuiz")}
          </Nav.Link>
          <Nav.Link className={styles.SideList}>{t("myPage")}</Nav.Link>
          <br />
          <Nav.Link className={styles.SideList}>{t("announcement")}</Nav.Link>
          <Nav.Link className={styles.SideList}>{t("introduce")}</Nav.Link>
        </Nav>
      </Offcanvas.Body>
    </Offcanvas>
  );
}
