import React from "react";
import styles from "../../../styles/App.module.css";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function LoginButton({ isEmail, login, isPc }) {
  const { t } = useTranslation();
  let navigate = useNavigate();

  return (
    <div className={styles.Login}>
      <Button
        className={isPc ? styles.SignOnBtnPc : styles.SignOnBtn}
        //  disabled={isEmail ? false : true}
        onClick={() => {
          // setClick(true);
          // sendData();
          navigate("/SignUp", { state: { isPc } });
        }}
      >
        회원가입
      </Button>

      <Button
        className={isPc ? styles.LoginBtnPc : styles.LoginBtn}
        disabled={isEmail ? false : true}
        onClick={() => {
          // setClick(true);
          // sendData();
          login();
        }}
      >
        로그인
      </Button>
    </div>
  );
}
