import React from "react";
import styles from "../../../styles/App.module.css";
import { CgDanger } from "react-icons/cg";
import { useTranslation } from "react-i18next";

export default function ValidateEmailText({ isEmail, userEmailInput }) {
  const { t } = useTranslation();
  return (
    <>
      {isEmail ? (
        <div className={styles.FalseEmailNone}>{t("emailError")}</div>
      ) : (
        <div className={styles.FalseEmailContainer}>
          <span
            className={
              userEmailInput.length > 0
                ? styles.FalseEmail
                : styles.FalseEmailNone
            }
          >
            메일주소를 정확하게 입력해 주세요!
          </span>
        </div>
      )}
    </>
  );
}
