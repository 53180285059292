import { createSlice } from '@reduxjs/toolkit';
import { theoryMode } from '../../../key/theoryMode';
import { CreateTheoryManager } from '../../../api/theory/theoryManager';
import { popupMode } from '../../../key/popupMode';

const theorySlice = createSlice({
  name: 'createTheory',
  initialState: {
    subQuestionId: null,
    subQuestionBody: null,
    theoryManager: new CreateTheoryManager(),
    popup: popupMode.invisible,
    mode: theoryMode.createCategory,
    theoryList: [],
    categoryList: [],
    currentData: {
      degree: null,
      depth: null,
      categoryId: null,
      screenCoord: null,
    },
    currentTarget: {
      theory: null,
      category: null,
    },
  },
  reducers: {
    clearAll: (state) => {
      state.subQuestionId = null;
      state.subQuestionBody = null;
      state.theoryManager = new CreateTheoryManager();
      state.popup = popupMode.invisible;
      state.mode = theoryMode.createCategory;
      state.theoryList = [];
      state.categoryList = [];
      state.currentData = {
        degree: null,
        depth: null,
        categoryId: null,
        screenCoord: null,
      };
      state.currentTarget = {
        theory: null,
        category: null,
      };
    },
    setSubQuestionId: (state, action) => {
      state.subQuestionId = action.payload.subQuestionId;
    },
    setSubQuestionBody: (state, action) => {
      state.subQuestionBody = action.payload.subQuestionBody;
    },
    hidePopup: (state) => {
      state.popup = popupMode.invisible;
    },
    changePopupMode: (state, action) => {
      state.popup = action.payload.popupMode;
    },
    changeMode: (state, action) => {
      state.mode = action.payload.mode;
    },
    setTheoryManager: (state, action) => {
      const theoryManager = action.payload.theoryManager;
      state.theoryManager = theoryManager;
      if (theoryManager instanceof CreateTheoryManager) {
        state.categoryList = [...theoryManager.getCategoryList()];
        state.theoryList = [...theoryManager.getTheoryList()];
      } else {
        state.categoryList = [];
        state.theoryList = [];
      }
    },
    updateCurrentData: (state, action) => {
      state.currentData = action.payload.currentData;
    },
    clearCurrentData: (state) => {
      state.currentData = {
        degree: null,
        depth: null,
        categoryId: null,
        screenCoord: null,
      };
    },

    // moveTheory: (state, action) => {},
    setCurrentTheory: (state, action) => {
      state.currentTarget.category = null;
      state.currentTarget.theory = action.payload.theory;
    },
    setCurrentCategory: (state, action) => {
      state.currentTarget.theory = null;
      state.currentTarget.category = action.payload.category;
    },
    initializeCurrentTarget: (state) => {
      state.currentTarget.theory = null;
      state.currentTarget.category = null;
    },
    addCategory: (state, action) => {
      state.theoryManager.addCategory(action.payload.category);
      state.categoryList = [...state.theoryManager.getCategoryList()];
    },
    addTheory: (state, action) => {
      state.theoryManager.addTheory(action.payload.theory);
      state.theoryList = [...state.theoryManager.getTheoryList()];
    },
    modifyTheory: (state, action) => {
      state.theoryList = [...state.theoryManager.getTheoryList()];
      state.currentTarget.theory = null;
      state.currentTarget.category = null;
    },
    modifyCategory: (state, action) => {
      state.categoryList = [...state.theoryManager.getCategoryList()];
      state.currentTarget.theory = null;
      state.currentTarget.category = null;
    },
    removeTheory: (state, action) => {
      const removedTheory = action.payload.theory;
      state.theoryManager.removeTheory(removedTheory);
      state.theoryList = [...state.theoryManager.getTheoryList()];
      console.log(state.theoryList);
    },
    removeCategory: (state, action) => {
      const removedCategory = action.payload.category;
      state.theoryManager.removeCategory(removedCategory.getCategoryId());
      state.categoryList = [...state.theoryManager.getCategoryList()];
      state.theoryList = [...state.theoryManager.getTheoryList()];
      console.log(state.theoryList, state.categoryList);
    },
    updateTheoryCategoryList: (state, action) => {
      const { categoryList, theoryList } = action.payload;
      state.theoryManager.setCategoryList(categoryList);
      state.theoryManager.setTheoryList(theoryList);
      state.categoryList = [...categoryList];
      state.theoryList = [...theoryList];
    },
  },
});

export const {
  setSubQuestionBody,
  updateCurrentData,
  changeMode,
  setTheoryManager,
  addCategory,
  addTheory,
  clearCurrentData,
  setCurrentTheory,
  setCurrentCategory,
  changePopupMode,
  hidePopup,
  initializeCurrentTarget,
  modifyTheory,
  modifyCategory,
  removeTheory,
  removeCategory,
  setSubQuestionId,
  updateTheoryCategoryList,
  clearAll,
} = theorySlice.actions;
export default theorySlice.reducer;
