import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import styles from "../../../styles/Modal.module.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAxiosContext } from "../../../context/AxiosContainer";

export default function CreateCaseResearcherBtn({
  closeModal,
  data,
  inputText,
  taskId,
}) {
  const navigate = useNavigate();

  const { httpInterface } = useAxiosContext();
  console.log(data, inputText);
  const [question, setQuestion] = useState([]);
  const [caseName, setCaseName] = useState();
  const [subcase, setSubCase] = useState();
  const { accessToken } = useSelector((state) => state.loginInfo);
  useEffect(() => {
    inputText.map((a, i) => {
      setQuestion([
        data?.subQuestionBodyList[0]
          ? data.subQuestionBodyList[0]
          : a.question1,
        data?.subQuestionBodyList[1]
          ? data.subQuestionBodyList[1]
          : a.question2,
        data?.subQuestionBodyList[2]
          ? data.subQuestionBodyList[2]
          : a.question3,
        data?.subQuestionBodyList[3]
          ? data.subQuestionBodyList[3]
          : a.question4,
      ]);
      setCaseName(a.caseName);
      setSubCase(a.case);
    });
  }, []);
  const { t } = useTranslation();
  const gotoOtherPage = async (url) => {
    console.log(question);
    await httpInterface
      .postCreateCase(accessToken, [
        {
          quizId: data.quizId,
          caseName: data?.caseName ? data.caseName : caseName,
          quizBody: data?.quizBody ? data.quizBody : subcase,
          subQuestionBodyList: question,
        },
      ])
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log("error : ", error.response);
      });

    httpInterface
      .postCreateCaseResearcher(accessToken, null, {
        params: { taskId: taskId },
      })
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log("error : ", error.response);
      });
    await navigate(url);
  };

  return (
    <>
      <button
        className={styles.CloseBtn}
        onClick={async () => {
          closeModal();
        }}
        style={{ marginRight: "3%" }}
      >
        <b style={{ color: "white" }}>{t("cancellation")}</b>
      </button>
      <button
        className={styles.CloseBtn}
        onClick={async () => {
          await gotoOtherPage("/MainR");
          closeModal();
        }}
      >
        <b style={{ color: "white" }}>생성하기</b>
      </button>
    </>
  );
}
