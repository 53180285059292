import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "../../styles/App.module.css";
import Button from "react-bootstrap/Button";

export default function ChangePassword() {
  const { pathname } = useLocation();
  console.log(pathname, pathname.split("/")[2]);
  let navigate = useNavigate();
  useEffect(() => {
    navigate(pathname);
  }, []);
  return (
    <div className={styles.Container}>
      <div className={styles.Title}>RTW 비밀번호 변경</div>
      <div className={styles.Content}>
        <span>이메일</span>
        <br />
        <span></span>
      </div>
      <div className={styles.LoginBtn}>
        <Button
          style={{ width: "60%" }}
          onClick={() => {
            // navigate("/");
          }}
        ></Button>
      </div>
    </div>
  );
}
