import React, { useEffect, useState } from 'react';
import { useAxiosContext } from '../../../context/AxiosContainer';
import { useSelector } from 'react-redux';

import styles from './Sidebar.module.css';
import AnswerList from './AnswerList';
import ButtonList from './ButtonList';

export default function Sidebar({ taskId }) {
  const { httpInterface } = useAxiosContext();
  const { accessToken } = useSelector((state) => state.loginInfo);
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    httpInterface
      .getFiveAnswers(accessToken, { params: { taskId } })
      .then((res) => {
        const { data } = res.data;
        if (data.length > 0) setAnswers(data);
      });
  }, []);

  if (!answers.length) return;

  return (
    <section className={styles.sidebar}>
      <div className={styles.header}>답변 리스트</div>
      <AnswerList answers={answers} />
      <ButtonList answers={answers} setAnswers={setAnswers} />
    </section>
  );
}
