import { createSlice } from '@reduxjs/toolkit';
import { reassignMenu, reassignMode } from '../../../key/reassignMode';

const reassignTheory = createSlice({
  name: 'reassignTheory',
  initialState: {
    // subQuestionId: null,
    reassignData: [],
    menu: reassignMenu.trash,
    mode: reassignMode.none,
    selectedItem: null,
    removeIds: [],
    addLists: [],
  },
  reducers: {
    /*     setSubQuestionId: (state, action) => {
      state.subQuestionId = action.payload.subQuestionId;
    }, */
    setReassignData: (state, action) => {
      state.reassignData = action.payload.data;
    },
    setReassignMode: (state, action) => {
      state.mode = action.payload.mode;
    },
    setReassignMenu: (state, action) => {
      state.menu = action.payload.menu;
    },
    setSelectedItem: (state, action) => {
      state.selectedItem = action.payload.item;
    },
    clearReassignState: (state, action) => {
      state.reassignData = [];
      state.menu = reassignMenu.trash;
      state.mode = reassignMode.none;
      state.selectedItem = null;
      state.removeIds = [];
      state.addLists = [];
    },
    setModifyData: (state, action) => {
      const { id: modifedTargetId, text } = action.payload;
      state.reassignData = state.reassignData.map((data) => {
        if (data.psAnswerId === modifedTargetId) {
          return {
            ...data,
            text: text,
          };
        } else {
          return data;
        }
      });
      state.mode = reassignMode.none;
      state.selectedItem = null;
    },
    addRemovedId: (state, action) => {
      const { id } = action.payload;
      state.removeIds = [...state.removeIds, id];
      state.mode = reassignMode.none;
    },
    addItem: (state, action) => {
      const { item } = action.payload;
      state.addLists = [...state.addLists, item];
      state.mode = reassignMode.none;
    },
  },
});

export const {
  setReassignData,
  setReassignMode,
  setReassignMenu,
  setSelectedItem,
  clearReassignState,
  setModifyData,
  addRemovedId,
  addItem,
} = reassignTheory.actions;

export default reassignTheory.reducer;
