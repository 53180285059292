type Props = {
  answer: {
    psAnswerId: string;
    text: string;
    engText: string;
  }
};

const AnswerItem = ({answer: {psAnswerId, text, engText}}: Props) => {
  console.log(text)
  return <li>
    {text}
  </li>
}

export default AnswerItem;