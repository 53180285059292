import { createContext, useContext, useEffect } from 'react';
import { TheoryManagement } from '../api/theory/theoryManager';
import { RealTimeCanvas } from '../api/d3/d3';
import { RealTimeCalculator } from '../api/theory/theoryCalculator';

const RealTimeAssignContext = createContext();

export const RealTimeAssignProvider = ({ children }) => {
  // const theoryManager = new TheoryManagement();
  const realTimeCanvas = new RealTimeCanvas();
  const realTimeCalculator = new RealTimeCalculator();

  return (
    <RealTimeAssignContext.Provider
      value={{ realTimeCanvas, realTimeCalculator }}
    >
      {children}
    </RealTimeAssignContext.Provider>
  );
};

export const useRealTimeAssignContext = () => {
  return useContext(RealTimeAssignContext);
};
