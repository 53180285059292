import { createSlice } from '@reduxjs/toolkit';

const manageTheory = createSlice({
  name: 'manageTheory',
  initialState: {
    selectedItem: null,
    assignedItemIds: [],
    assignedItems: [],
    trashedItemIds: [],
    trashedItems: [],
    subQuestionId: null,
    subQuestionText: null,
  },
  reducers: {
    clearState: (state, action) => {
      state.selectedItem = null;
      state.assignedItemIds = [];
      state.assignedItems = [];
      state.trashedItemIds = [];
      state.trashedItems = [];
      state.subQuestionId = null;
      state.subQuestionText = null;
    },
    setSelectedItem: (state, action) => {
      state.selectedItem = action.payload.selectedItem;
    },
    assignItem: (state, action) => {
      const id = action.payload.selectedItem.getId();

      state.assignedItemIds = [...state.assignedItemIds, id];
      state.assignedItems = [
        ...state.assignedItems,
        action.payload.selectedItem,
      ];
      state.selectedItem = null;
    },
    reassignItem: (state, action) => {
      const id = action.payload.selectedItem.getId();
      state.assignedItems = state.assignedItems.map((item) => {
        if (item.getId() === id) {
          console.log(item);
          return action.payload.selectedItem;
        } else {
          console.log(item);
          return item;
        }
      });
      state.selectedItem = null;
    },
    trashedItem: (state, action) => {
      const id = action.payload.selectedItem.getId();
      const isAssigned = state.assignedItemIds.includes(id);
      // 아이템이 assignedItemIds 내에 있는 경우?
      if (isAssigned) {
        state.assignedItemIds = state.assignedItemIds.filter(
          (itemId) => itemId !== id
        );
        state.assignedItems = state.assignedItems.filter(
          (item) => item.getId() !== id
        );
        state.trashedItemIds = [...state.trashedItemIds, id];
        state.trashedItems = [
          ...state.trashedItems,
          action.payload.selectedItem,
        ];
      } else {
        // 없는 경우
        const isTrashed = state.trashedItemIds.includes(id);
        if (!isTrashed) {
          state.trashedItemIds = [...state.trashedItemIds, id];
          state.trashedItems = [
            ...state.trashedItems,
            action.payload.selectedItem,
          ];
        }
      }
      state.selectedItem = null;
    },
    setSubQuestionData: (state, action) => {
      state.subQuestionId = action.payload.subQuestionId;
      state.subQuestionText = action.payload.subQuestionText;
    },
    refreshStateWhenGetNewData: (state, action) => {
      state.selectedItem = null;
      state.assignedItemIds = [];
      state.assignedItems = [];
      state.trashedItemIds = [];
      state.trashedItems = [];
    },
  },
});

export const {
  clearState,
  setSelectedItem,
  assignItem,
  reassignItem,
  trashedItem,
  setSubQuestionData,
  refreshStateWhenGetNewData,
} = manageTheory.actions;

export default manageTheory.reducer;
