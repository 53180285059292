import React from "react";
import styles from "../../styles/App.module.css";
import Logo from "../../images/logo.svg";
import { useTranslation } from "react-i18next";
export default function LoginLogo() {
  const { t } = useTranslation();
  return (
    <div className={styles.LogoWrap}>
      <img src={Logo} alt="RTW" />
    </div>
  );
}
