import React, { useEffect } from 'react';
import TheoryGraph from '../theoryGraph/TheoryGraph';
import { CreateTheoryCanvas, XlsxTheoryCanvas } from '../../../api/d3/d3';
import { gradientColor } from './color';
import * as d3 from '../../../MindMap/js/d3';
import { popupMode } from '../../../key/popupMode';
import { useDispatch, useSelector } from 'react-redux';
import { CreateTheoryManager } from '../../../api/theory/theoryManager';
import {
  changeMode,
  changePopupMode,
  clearAll,
  hidePopup,
  modifyTheory,
  setSubQuestionId,
  setTheoryManager,
  updateCurrentData,
  updateTheoryCategoryList,
} from '../../../redux/slice/theory/createTheory';
import Popup from '../popup/Popup';
import { theoryMode } from '../../../key/theoryMode';
import { TheoryCalculator } from '../../../api/theory/theoryCalculator';
import { Theory } from '../../../api/theory/theory';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useAxiosContext } from '../../../context/AxiosContainer';
import { getTheoryOrCategory } from './check';
import {
  signOut,
  updateAccessToken,
} from '../../../redux/slice/login/loginInfo';
import { Category } from '../../../api/theory/category';
import { Calculator } from '../../../api/theory/theoryTree';

// TODO: 실시간 저장 로직 구현하기

export default function EditTheory() {
  const { httpInterface } = useAxiosContext();
  const { state: subQuestionId } = useLocation();
  const { accessToken } = useSelector((state) => state.loginInfo);
  const {
    theoryManager,
    theoryList,
    categoryList,
    mode,
    popup,
    currentTarget,
  } = useSelector((state) => state.createTheory);
  const dispatch = useDispatch();
  // const createCanvas = new CreateTheoryCanvas();
  const createCanvas = new CreateTheoryCanvas();
  // const createCanvas = new XlsxTheoryCanvas();
  // const calculate = new TheoryCalculator();
  const calculate = new Calculator();

  const { isLoading, isError } = useQuery(
    ['getTheoryData'],
    () => {
      return httpInterface
        .getAssignTheoryData(accessToken, {
          params: {
            subQuestionId,
          },
        })
        .catch((error) => {
          const { status } = error.response;
          console.log('EditTheory Component Error', error);
          if (status === 401) {
            // reissue Token 관련 코드 작성할 것
            httpInterface
              .reissueToken(accessToken)
              .then((response) => {
                console.log(response.data.data[0]);
                dispatch(
                  updateAccessToken({
                    accessToken: response.data.data[0].accessToken,
                  })
                );
              })
              .catch((error) => {
                console.log('Refresh Token Error');
                alert('유효정보가 만료되어 로그아웃되었습니다.');
                dispatch(signOut());
              });
          } else {
            console.log('Validation Token Error');
            alert('유효정보가 만료되어 로그아웃되었습니다.');
            dispatch(signOut());
          }
        });
    },
    {
      onSuccess: (res) => {
        console.log(res);
        const caseGrade = res.data.data[0].caseGrade;
        const spots = res.data.data[0].cardList;
        const theoryAndCategory = spots.map((spot) =>
          getTheoryOrCategory(spot)
        );
        const categoryList = theoryAndCategory.filter(
          (data) => data instanceof Category
        );
        const theoryList = theoryAndCategory.filter(
          (data) => data instanceof Theory
        );

        const theoryListSortByCategoryId = theoryList.reduce((acc, theory) => {
          const idx = theory.getCategoryId() - 1;
          if (!acc[idx]) {
            acc[idx] = [theory];
          } else {
            acc[idx] = [...acc[idx], theory];
          }
          return acc;
        }, []);

        // console.log(theoryListSortByCategoryId);
        let acc = 0;
        let total = theoryListSortByCategoryId.reduce((acc, cur) => {
          return acc + cur.length;
        }, 0);
        categoryList.forEach((category, idx) => {
          const angles = calculate.calcAngles(
            category.getCategoryId(),
            categoryList.length
          );
          const cur = theoryListSortByCategoryId[idx]?.length || 0;
          const angle = calculate.calcCategoryAngles(cur, acc, total);
          acc += theoryListSortByCategoryId[idx]?.length;
          category.setAngles(angle);
          const screenCoord =
            calculate.calculateCategoryScreenCoordByCircleCoord(category);
          category.setScreenCoord(screenCoord);
        });
        theoryList.forEach((theory) => {
          const screenCoord = calculate.calculateTheoryScreenCoordByCircleCoord(
            theory,
            categoryList
          );
          theory.setScreenCoord(screenCoord);
        });

        if (!(theoryManager instanceof CreateTheoryManager)) {
          const theoryManager = new CreateTheoryManager();
          theoryManager.setCategoryList(categoryList);
          theoryManager.setTheoryList(theoryList);
          dispatch(setTheoryManager({ theoryManager }));
        } else {
          dispatch(updateTheoryCategoryList({ categoryList, theoryList }));
          theoryManager.setCategoryList(categoryList);
          theoryManager.setTheoryList(theoryList);
        }
      },
    }
  );

  useEffect(() => {
    dispatch(setSubQuestionId({ subQuestionId }));
    // 마운트되었을 때 theoryManager 인스턴스가 없으면 다시 생성하는 로직
    if (!(theoryManager instanceof CreateTheoryManager)) {
      dispatch(setTheoryManager({ theoryManager: new CreateTheoryManager() }));
    }

    // 언마운트시 createTheory store 초기화 작업
    return () => {
      dispatch(setTheoryManager({ theoryManager: null }));
      dispatch(hidePopup());
      dispatch(setSubQuestionId({ subQuestionId: null }));
      dispatch(clearAll());
    };
  }, []);

  useEffect(() => {
    if (theoryManager instanceof CreateTheoryManager) {
      console.log(theoryManager);
      createCanvas.setTheoryManager(theoryManager);
      initSetting();
    }
  }, [theoryManager]);

  useEffect(() => {
    // 가설좌표와 카테고리에 변경이 있으면 화면을 다시 그리도록
    if (!(theoryManager instanceof CreateTheoryManager)) {
      return;
    }
    if (!categoryList.length) return;
    // setClickCoord(initCoord);
    initSetting();
    createCanvas.drawCategories(categoryList);
    createCanvas.drawTheories(theoryList, currentTarget);
  }, [categoryList, theoryList, mode, currentTarget]);

  // 카테고리 팝업 함수
  const createCategoryPopup = (e) => {
    e.preventDefault();
    dispatch(changePopupMode({ popupMode: popupMode.createCategory }));
    dispatch(changeMode({ mode: theoryMode.createCategory }));
  };

  // 가설좌표 팝업 함수
  const clickTheoryPopup = (e) => {
    console.log(e);
    if (!categoryList.length) {
      alert('원 중앙을 클릭해서 카테고리부터 생성해주세요!');
      return;
    }

    e.preventDefault();
    const pointer = d3.pointers(e, this);
    const [x, y] = pointer[0];

    // 가설좌표 생성하려는 경우
    if (mode !== theoryMode.modify && mode !== theoryMode.move) {
      // 찍은 좌표를 기준으로 depth, degree 등을 계산, 팝업 창 띄우고 값 입력하도록 유도
      const currentData = calculate.getClickCoordInfo({ x, y }, categoryList);
      dispatch(changePopupMode({ popupMode: popupMode.createTheory }));
      dispatch(changeMode({ mode: theoryMode.createTheory }));
      dispatch(updateCurrentData({ currentData }));
      return;
    }

    if (mode === theoryMode.move && currentTarget.theory instanceof Theory) {
      const { categoryId, depth, degree, screenCoord } =
        calculate.getClickCoordInfo({ x, y }, categoryList);
      const theory = currentTarget.theory;
      theory.setCategoryId(categoryId);
      theory.setDepth(depth);
      theory.setDegree(degree);
      theory.setScreenCoord(screenCoord);
      // 실시간 저장
      httpInterface
        .putModifyTheoryData(
          accessToken,
          [
            {
              id: theory.getId(),
              answerCategory: categoryId,
              degree,
              depth,
            },
          ],
          {
            params: { subQuestionId },
          }
        )
        .catch((error) => {
          console.log('CreateTheory Error - move theory' - error);
        });
      dispatch(modifyTheory());
      dispatch(changeMode({ mode: theoryMode.createTheory }));
      return;
    } else {
      alert('현재 수정 모드입니다');
      return;
    }
  };

  const initSetting = () => {
    // 화면 지우고 다시 그리는 기본적인 함수
    // console.log(gradientColor[0][1])
    createCanvas.removeCanvas();
    createCanvas.drawGraph(
      gradientColor,
      createCategoryPopup,
      clickTheoryPopup,
      categoryList
    );
  };

  return (
    <>
      <TheoryGraph />
      {popup !== popupMode.invisible && <Popup mode={popup} />}
    </>
  );
}
