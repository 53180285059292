import React, { useState } from "react";
import styles from "../../styles/App.module.css";
import { useMediaQuery } from "react-responsive";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useAxiosContext } from "../../context/AxiosContainer";

export default function ChangePasswordSendEmail() {
  const isPc = useMediaQuery({
    query: "(min-width:768px)",
  });

  const [userEmailInput, setUserEmailInput] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const { httpInterface } = useAxiosContext();

  const ChangePasswordEmail = () => {
    httpInterface
      .postChangePasswordEmail({
        email: userEmailInput,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className={isPc ? styles.ChangePasswordPc : styles.ChangePassword}>
      <div style={{ paddingTop: "50%", fontSize: "1.4em" }}>
        <span>비밀번호 변경</span>
      </div>
      <div style={{ fontSize: "0.7em", marginTop: "6vh", marginBottom: "5vh" }}>
        <input
          className={isPc ? styles.EmailPc : styles.Email}
          onChange={(e) => setUserEmailInput(e.target.value)}
          //   onKeyPress={keyDownHandler}
          placeholder="이메일"
          type="email"
          autoFocus
        />
      </div>
      <Button
        className={isPc ? styles.ChangePasswordBtnPc : styles.ChangePasswordBtn}
        disabled={userEmailInput ? false : true}
        onClick={() => {
          ChangePasswordEmail();
          setModalOpen(true);
        }}
      >
        확인
      </Button>
      <div className={styles.ChangePasswordInfoText}>
        <span>입력한 메일로 비밀번호 변경 메일이 전송됩니다.</span>
        <br />
        <span>전송된 메일에서 비밀번호를 변경해 주세요!</span>
      </div>
      <Modal
        show={modalOpen}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setModalOpen(false)}
      >
        <Modal.Body style={{ textAlign: "center", color: "#3D5398" }}>
          <p
            style={{
              fontSize: "1.3em",
              fontWeight: "bolder",
            }}
          >
            {userEmailInput} 로
            <br />
            비밀번호 변경 메일을 전송했습니다.
            <br />
          </p>
          <span>메일함을 확인해 주세요!</span>
        </Modal.Body>
        <Button
          className={styles.Btn}
          onClick={() => {
            setModalOpen(false);
          }}
        >
          확인
        </Button>
      </Modal>
    </div>
  );
}
