import React from 'react';

import styles from './RemovePopup.module.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
  hidePopup,
  removeCategory,
  removeTheory,
} from '../../../redux/slice/theory/createTheory';
import { Theory } from '../../../api/theory/theory';
import { Category } from '../../../api/theory/category';
import { useAxiosContext } from '../../../context/AxiosContainer';

export default function RemovePopup({ show }) {
  const { currentTarget, theoryManager, subQuestionId } = useSelector(
    (state) => state.createTheory
  );
  const dispatch = useDispatch();
  const { httpInterface } = useAxiosContext();
  const { accessToken } = useSelector((state) => state.loginInfo);

  return (
    <Modal
      show={show}
      onHide={() => {
        dispatch(hidePopup());
      }}
      style={{
        display: 'block',
        transform: `translate(200px, 200px)`,
        width: '300px',
      }}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <p>
          {currentTarget.theory instanceof Theory &&
            `${currentTarget.theory.getText()} 가설을 정말 삭제하시겠습니까?`}
        </p>
        <p>
          {currentTarget.category instanceof Category &&
            `${currentTarget.category.getText()} 카테고리를 정말 삭제하시겠습니까?`}
        </p>
        <p>
          {currentTarget.category instanceof Category &&
            `카테고리 내의 하위 가설좌표는 모두 삭제됩니다. 신중히 결정해주세요 `}
        </p>
        <p>삭제 후에는 복구할 수 없습니다</p>
      </Modal.Body>
      <Modal.Footer className={styles['button-container']}>
        <Button
          variant='secondary'
          onClick={() => {
            dispatch(hidePopup());
          }}
        >
          닫기
        </Button>
        <Button
          variant='primary'
          onClick={() => {
            if (currentTarget.theory instanceof Theory) {
              // console.log(currentTarget.theory);
              dispatch(removeTheory({ theory: currentTarget.theory }));
              httpInterface
                .deleteTheoryData(accessToken, {
                  data: {
                    idList: [`${currentTarget.theory.getId()}`],
                  },
                })
                .then((res) => console.log(res))
                .catch((error) => console.log(error));
            } else if (currentTarget.category instanceof Category) {
              // 삭제할 카테고리 및 가설좌표 삭제 (id값 필요)
              const removedIdInCat = currentTarget.category.getId();
              const removedCategoryId = currentTarget.category.getCategoryId();
              const removedIdsInTheory =
                theoryManager.getRemovedTheoryIds(removedCategoryId);
              const removedIdList = [removedIdInCat, ...removedIdsInTheory];

              // 이로인해 변경된 카테고리 및 가설좌표 변경 (category id 값 변경, id 값 필요 - [{id, categoryId}, {...}])
              const changedList =
                theoryManager.getChangedCategoriesTheoriesByRemovingCategory(
                  removedCategoryId
                );

              dispatch(removeCategory({ category: currentTarget.category }));
              httpInterface
                .deleteTheoryData(accessToken, {
                  data: {
                    idList: removedIdList,
                  },
                })
                .then((res) => {
                  const status = res.data.status;
                  if (status !== 'SUCCESS') {
                    throw new Error('Removed Error');
                  }
                  httpInterface
                    .putModifyTheoryData(accessToken, changedList, {
                      params: { subQuestionId },
                    })
                    .then((res) => {
                      const status = res.data.status;
                      if (status !== 'SUCCESS') {
                        throw new Error('Removed Error');
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              alert('에러가 발생했습니다. ');
            }
            dispatch(hidePopup());
          }}
        >
          삭제
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const getRemovedTheoryIds = (theoryList, removedCategoryId) => {};
