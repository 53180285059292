import React from "react";
import Button from "react-bootstrap/esm/Button";
import { FaRegQuestionCircle } from "react-icons/fa";
import styles from "./ConditionInformation.module.css";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

export default function ConditionInformation() {
  return (
    <div
      style={{
        color: "#454545",
        fontSize: "0.6em",
        marginTop: "2%",
        textAlign: "left",
      }}
    >
      <p style={{ marginBottom: "0" }}>
        조건 1. 한 개의 답변에는 한 가지 요소만 입력하고, 다섯 단어 이내로
        작성해 주세요.
        {
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Popover
                id={`popover-positioned-bottom`}
                style={{ backgroundColor: "#F0F0F0" }}
              >
                <Popover.Body>
                  <span>
                    (예시 1) 하나의 답변에는 하나의 개념만 들어가야 함
                  </span>
                  <ul>
                    <li>틀린 답변: (답변 1) 효과적이고 효율적인 수단</li>
                    <li>
                      <b>
                        옳은 답변: (답변 1) 효과적인 수단 (답변 2) 효율적인 수단
                      </b>
                    </li>
                    <li>틀린 답변: (답변 1) 자동차와 기차</li>
                    <li>
                      <b>옳은 답변: (답변 1) 자동차 (답변 2) 기차</b>
                    </li>
                  </ul>
                  <span>(예시 2) 답변이 다섯 단어(어절)를 넘지 않아야 함</span>

                  <ul>
                    <li>
                      틀린 답변: 대기업의 기준이란 부모님이 이름만 들어도 잘 알
                      수 있는 유명한 곳
                    </li>
                    <li>
                      <b>옳은 답변: 부모님이 알 수 있는 유명함</b>
                    </li>
                  </ul>
                </Popover.Body>
              </Popover>
            }
          >
            <Button
              style={{
                backgroundColor: "white",
                border: "none",
                height: "20px",
                position: "relative",
              }}
            >
              <FaRegQuestionCircle
                style={{
                  color: "gray",
                  position: "absolute",
                  top: "20%",
                }}
              />
            </Button>
          </OverlayTrigger>
        }
      </p>
      <p>조건 2. 최소 10개의 답변을 작성해 주세요.</p>
    </div>
  );
}
