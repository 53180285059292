import React from "react";
import { useSelector } from "react-redux";

import styles from "./WelcomeText.module.css";

export default function WelcomeText({ isPc }) {
  const { email, roleType } = useSelector((state) => state.loginInfo);
  return (
    <div className={`${styles.container}`}>
      <span
        className={isPc ? styles.welcomeTextPc : `${styles["welcome-text"]}`}
      >
        {email}님 안녕하세요!
      </span>
    </div>
  );
}
