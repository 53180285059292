import React, { useRef } from 'react';
import styles from './TheoryGraph.module.css';

export default function TheoryGraph() {
  const canvasWidth = window.innerWidth * 0.7;
  const canvasHeight = window.innerHeight * 0.9;
  const svgRef = useRef();

  return (
    <article className={styles['canvas-container']}>
      <svg
        className={`${styles['canvas']} ${styles['edit-theory']}`}
        id='svg'
        ref={svgRef}
        width={canvasWidth}
        height={canvasHeight}
      >
        <g>
          <svg></svg>
        </g>
      </svg>
    </article>
  );
}
