import { useTranslation } from "react-i18next";
import React from "react";
import styles from "../styles/StartTest.module.css";

export default function CheckInputLength({ textLength }) {
  const { t } = useTranslation();
  return (
    <span className={styles.TextLength}>
      {textLength >= 0 ? (textLength < 36 ? textLength : textLength - 1) : null}
      {t("number")}
    </span>
  );
}
