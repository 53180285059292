import React from "react";
import styles from "../../../styles/CreateCase.module.css";

export default function Question1({
  idx,
  temporaryStorage,
  inputCopy,
  setInputText,
  createClick,
}) {
  //console.log(inputCopy);
  return (
    <div className={styles.ChoiceTask}>
      <div className={styles.DivBox}>
        <span>질문 1*</span>
      </div>
      <div className={styles.Test}>
        <input
          className={styles.Answer}
          name="question1"
          value={
            temporaryStorage?.[idx]?.subQuestionBodyList[0]
              ? temporaryStorage[idx].subQuestionBodyList[0]
              : inputCopy[idx].question1
          }
          autoComplete="off"
          onChange={(e) => {
            temporaryStorage?.[idx]?.subQuestionBodyList[0]
              ? (temporaryStorage[idx].subQuestionBodyList[0] = e.target.value)
              : (inputCopy[idx].question1 = e.target.value);
            setInputText(inputCopy);
          }}
        />
        {createClick && inputCopy[idx]?.question1.length === 0 && (
          <span style={{ color: "red" }}>필수 정보를 입력해주세요.</span>
        )}
      </div>
    </div>
  );
}
