import React from 'react';
import Button from 'react-bootstrap/Button';
import styles from '../../../styles/MainResearcher.module.css';
import Table from 'react-bootstrap/Table';
import TestTableRow from './TestTableRow';
import { useSelector } from 'react-redux';

export default function TestTable() {
  let no = true;
  const { taskInfo } = useSelector((state) => state.taskInfo);
  console.log(taskInfo);
  return (
    <>
      <div className={styles.Content}>
        <span>문제해결테스트</span>
        <Button className={styles.ExamBtn} onClick={() => {}}>
          예시
        </Button>
      </div>
      <Table hover>
        <thead>
          <tr>
            <th>유형</th>
            <th>난이도</th>
            <th>케이스명</th>
            <th>데드라인</th>
            <th>상태</th>
            <th>달성/목표</th>
            <th style={{ width: '15%' }}> </th>
          </tr>
        </thead>
        <tbody>
          {taskInfo &&
            taskInfo?.map((task, idx) => {
              if (taskInfo[idx].type === 'PS') {
                no = false;
                return (
                  <TestTableRow key={task.deadLine + idx} taskInfo={task} />
                );
              }
            })}
        </tbody>
      </Table>
      {no && <span>업무가 없습니다</span>}
    </>
  );
}
