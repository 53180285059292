export function getRandomValue(min, max, isInteger = false) {
  const randomValue = Math.random() * (max - min + 1) + min;
  return isInteger ? Math.floor(randomValue) : randomValue;
}

export function isEmptyObj(obj) {
  if (obj.constructor === Object && Object.keys(obj).length === 0) {
    return true;
  }

  return false;
}
