import { createSlice } from "@reduxjs/toolkit";
import { signOut } from "../login/loginInfo";

const taskInfoSlice = createSlice({
  name: "taskInfo",
  initialState: {
    taskInfo: null,
    currentTaskInfo: null,
    quiz: null,
  },
  reducers: {
    updateTaskInfo: (state, action) => {
      state.taskInfo = action.payload.taskInfo;
    },
    updateCurrentTaskInfo: (state, action) => {
      state.currentTaskInfo = action.payload.currentTaskInfo;
    },
  },
  extraReducers: {
    [signOut]: (state) => {
      state.taskInfo = null;
    },
  },
});

export const { updateTaskInfo, updateCurrentTaskInfo } = taskInfoSlice.actions;
export default taskInfoSlice.reducer;
