export const reassignMenu = {
  trash: 'Trash',
  largeError: 'LargeError',
};

export const reassignMode = {
  modify: 'modify',
  delete: 'delete',
  none: 'none',
};
