import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './Sidebar.module.css';
import { useAxiosContext } from '../../../../context/AxiosContainer';
import AnswerLists from './AnswerLists';
import ButtonList from './ButtonList';
import { clearReassignState } from '../../../../redux/slice/theory/adminReassignTheory';

export default function Sidebar({ subQuestionId }) {
  const { httpInterface } = useAxiosContext();
  const { accessToken } = useSelector((state) => state.loginInfo);
  const dispatch = useDispatch();
  const [trashMode, toggleTrashMode] = useState(true);
  const [trashAnswers, setTrashAnswers] = useState([]);
  const [errorAnswers, setErrorAnswers] = useState([]);

  useEffect(() => {
    httpInterface
      .getReassignData(accessToken, { subQuestionId })
      .then((res) => {
        const { data: answers } = res.data;
        if (!answers.length) return;

        setTrashAnswers(answers.filter((answer) => answer.note === 'trash'));
        setErrorAnswers(answers.filter((answer) => answer.note !== 'trash'));
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    return () => dispatch(clearReassignState());
  }, []);

  console.log(trashAnswers);
  // if (!answers.length) return;

  return (
    <section className={styles.sidebar}>
      <nav className={styles.header}>
        <span onClick={() => toggleTrashMode(true)}>휴지통 답변</span>
        <span onClick={() => toggleTrashMode(false)}>오차가 큰 답변</span>
      </nav>
      <ButtonList />
      <AnswerLists answers={trashMode ? trashAnswers : errorAnswers} />
    </section>
  );
}
