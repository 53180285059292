import React, { useEffect, useState } from "react";
import styles from "../styles/SignUpPage.module.css";
import Button from "react-bootstrap/Button";
import { AiOutlineCheckCircle } from "react-icons/ai";
import Modal from "react-bootstrap/Modal";
import { useAxiosContext } from "../context/AxiosContainer";
import { useLocation, useNavigate } from "react-router-dom";
import UniversityList from "../components/common/signUp/UniversityList";
import { useMediaQuery } from "react-responsive";

export default function SignUpPage() {
  let reg =
    /^(?=.*[A-Za-zㄱ-ㅎ|ㅏ-ㅣ|가-힣])(?=.*\d)[A-Za-zㄱ-ㅎ|ㅏ-ㅣ|가-힣\d]{8,20}$/;

  const [userInfo, setUserInfo] = useState({
    email: "",
    password: "",
    name: "",
    group: "",
  });

  const isPc = useMediaQuery({
    query: "(min-width:768px)",
  });
  const [isOpenPost, setIsOpenPost] = useState(false);
  const [isUserInfoEmpty, setIsUserInfoEmpty] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);

  const { httpInterface } = useAxiosContext();

  let navigate = useNavigate();

  useEffect(() => {
    userInfo.email !== "" &&
    userInfo.password !== "" &&
    userInfo.name !== "" &&
    userInfo.group !== ""
      ? setIsUserInfoEmpty(false)
      : setIsUserInfoEmpty(true);
  }, [userInfo, userInfo.group]);

  const SignUp = () => {
    httpInterface
      .postSignUp({
        email: userInfo.email,
        name: userInfo.name,
        password: userInfo.password,
        group: userInfo.group,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log("Test Component Error", error);
      });
  };

  return (
    <div className={isPc ? styles.ContainerPc : styles.Container}>
      <div className={styles.Title}>회원가입</div>
      <div className={styles.EmailAndPasswordInput}>
        <input
          className={styles.Email}
          onChange={(e) => {
            setUserInfo((prev) => ({
              ...prev,
              email: e.target.value,
            }));
          }}
          placeholder="이메일"
          autoFocus
        />
      </div>
      <div className={styles.EmailAndPasswordInput}>
        <input
          className={styles.Password}
          type="password"
          onChange={(e) => {
            setUserInfo((prev) => ({
              ...prev,
              password: e.target.value,
            }));
          }}
          placeholder="비밀번호"
        />
        {!reg.test(userInfo.password) ? (
          <p style={{ paddingLeft: "5%" }}>
            영문과 숫자를 포함하여 8글자 이상 입력해 주세요.
          </p>
        ) : (
          <AiOutlineCheckCircle />
        )}
      </div>
      <div style={{ fontSize: "1em" }}>
        <input
          className={styles.Email}
          onChange={(e) => {
            setUserInfo((prev) => ({
              ...prev,
              name: e.target.value,
            }));
          }}
          placeholder="이름"
        />
      </div>
      <div className={styles.Group}>
        <input
          className={styles.GroupText}
          readOnly
          value={userInfo.group}
          placeholder="소속"
        />
        <button
          className={styles.SearchBtn}
          onClick={() => setIsOpenPost(true)}
        >
          검색
        </button>
      </div>
      {isOpenPost ? (
        <div>
          <UniversityList userInfo={userInfo} setIsOpenPost={setIsOpenPost} />
        </div>
      ) : null}
      <div className={styles.SignUpBtnWrap}>
        <Button
          className={styles.SignUpBtn}
          disabled={
            isUserInfoEmpty || !reg.test(userInfo.password) ? true : false
          }
          onClick={() => {
            userInfo.email.includes("@" && ".") && setModalOpen(true);
          }}
        >
          회원가입
        </Button>
      </div>
      <Modal
        show={modalOpen}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setModalOpen(false)}
      >
        <Modal.Body
          style={{
            fontSize: "1.5em",
            height: "35vh",
            paddingTop: "10%",
          }}
        >
          <div style={{ marginBottom: "17%" }}>
            <span
              style={{
                color: "#3D5398",
                fontWeight: "bolder",
                fontSize: "1.4em",
              }}
            >
              입력한 정보를 확인해 주세요!
            </span>
          </div>
          <div
            style={{
              marginBottom: "8%",
              display: "flex",
            }}
          >
            <div className={styles.BoldText}>이메일</div>
            <div className={styles.TextContent}>{userInfo.email}</div>
          </div>
          <div
            style={{
              marginBottom: "8%",
              display: "flex",
            }}
          >
            <div className={styles.BoldText}>이름</div>
            <div className={styles.TextContent}>{userInfo.name}</div>
          </div>
          <div style={{ display: "flex" }}>
            <div className={styles.BoldText}>소속</div>
            <div className={styles.TextContent}>{userInfo.group}</div>
          </div>
        </Modal.Body>
        <div style={{ width: "100%", textAlign: "center", marginBottom: "5%" }}>
          <Button
            className={styles.CancleBtn}
            onClick={() => setModalOpen(false)}
          >
            취소
          </Button>
          <Button
            className={styles.CheckBtn}
            onClick={() => {
              SignUp();
              setModalOpen(false);
              navigate("/SignUpSendEmail", {
                state: { email: userInfo.email },
              });
            }}
          >
            확인
          </Button>
        </div>
      </Modal>
    </div>
  );
}
