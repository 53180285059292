import { useTranslation } from "react-i18next";
import React from "react";
import styles from "../../../styles/Modal.module.css";
import { useNavigate } from "react-router-dom";

export default function ButtonVersion3({ closeModal, userType }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const gotoOtherPage = (url) => {
    navigate(url, {
      state: {
        start: true,
        userType: userType,
      },
    });
  };

  return (
    <>
      <button
        className={styles.CloseBtn}
        onClick={() => {
          closeModal();
        }}
        style={{ marginRight: "3%" }}
      >
        <b style={{ color: "white" }}>{t("close")}</b>
      </button>
      <button
        className={styles.CloseBtn}
        onClick={() => {
          gotoOtherPage("/StartTest");
          closeModal();
        }}
      >
        <b style={{ color: "white" }}>{t("start")}</b>
      </button>
    </>
  );
}
