import React, { useEffect } from "react";
import styles from "../../../styles/CreateCase.module.css";

export default function CaseName({
  idx,
  temporaryStorage,
  inputCopy,
  setInputText,
  createClick,
  excelData,
}) {
  return (
    <div className={styles.ChoiceTask}>
      <div className={styles.DivBox}>
        <span>케이스명*</span>
      </div>
      <div className={styles.Test}>
        <input
          className={styles.Answer}
          name="text"
          value={excelData ? excelData[idx].케이스명 : inputCopy[idx].caseName}
          autoComplete="off"
          onChange={(e) => {
            temporaryStorage?.[idx]?.caseName
              ? (temporaryStorage[idx].caseName = e.target.value)
              : (inputCopy[idx].caseName = e.target.value);

            setInputText(inputCopy);
          }}
        />
        {createClick && inputCopy[idx]?.caseName.length === 0 && (
          <span style={{ color: "red" }}>필수 정보를 입력해주세요.</span>
        )}
      </div>
    </div>
  );
}
