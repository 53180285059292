import { useTranslation } from "react-i18next";
import React from "react";
// import styles from '../styles/StartTest.module.css';

export default function CheckInputLength({ textLength }) {
  return (
    <span
      style={{
        color: "#636363",
        position: "absolute",
        minWidth: "10vw",
        maxWidth: "10vw",
        right: "5%",
        top: "8px",
      }}
    >
      {textLength >= 0 ? (textLength < 36 ? textLength : textLength - 1) : null}{" "}
      / 35
    </span>
  );
}
