import React, { useEffect } from "react";
import styles from "../styles/MainResearcher.module.css";
import TestTable from "../components/researcherMain/showTest/TestTable";
import CreateEditTest from "../components/researcherMain/createEditTest/CreateEditTest";
import BatchTheoryPolar from "../components/researcherMain/assignTheory/BatchTheoryPolar";
import CreateEditTheoryPolar from "../components/researcherMain/createEditTheory/CreateEditTheoryPolar";
import { useAxiosContext } from "../context/AxiosContainer";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { signOut, updateAccessToken } from "../redux/slice/login/loginInfo";
import { updateTaskInfo } from "../redux/slice/taskInfo/taskInfo";

export default function MainResearcher() {
  const { email, roleType } = useSelector((state) => state.loginInfo);
  const { httpInterface } = useAxiosContext();
  const { accessToken } = useSelector((state) => state.loginInfo);
  const dispatch = useDispatch();

  const { data, isLoading } = useQuery(
    ["getTaskInfo"],
    () => {
      return httpInterface.getUserStatus(accessToken).catch((error) => {
        const { status } = error.response;
        console.log("MainResearcher Component Error", error);
        if (status === 401) {
          // reissue Token 관련 코드 작성할 것
          httpInterface
            .reissueToken(accessToken)
            .then((response) => {
              console.log(response.data.data[0]);
              dispatch(
                updateAccessToken({
                  accessToken: response.data.data[0].accessToken,
                })
              );
            })
            .catch((error) => {
              console.log("Refresh Token Error");
              alert("유효정보가 만료되어 로그아웃되었습니다.");
              dispatch(signOut());
            });
        } else {
          console.log("Validation Token Error");
          alert("유효정보가 만료되어 로그아웃되었습니다.");
          dispatch(signOut());
        }
      });
    },
    {
      onSuccess: (res) => {
        console.log(res);
        
        dispatch(updateTaskInfo({ taskInfo: res.data.data[0].taskInfo }));
      },
    }
  );

  return (
    <>
      <div className={styles.Container}>
        <div className={styles.User}>
          <b style={{ float: "left", marginRight: "3%" }}>
            {email} 님 안녕하세요.
          </b>
          <span className={styles.UserRole}>{roleType}</span>
        </div>
        <div className={styles.ContentWrap}>
          <TestTable />
          <CreateEditTest />
          <BatchTheoryPolar />
          <CreateEditTheoryPolar />
        </div>
      </div>
    </>
  );
}
