import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './AnswerItem.module.css';
import { setSelectedItem } from '../../../redux/slice/theory/manageTheory';
export default function AnswerItem({ text, psAnswerId, num }) {
  const { assignedItemIds, selectedItem, trashedItemIds } = useSelector(
    (state) => state.manageTheory
  );
  const dispatch = useDispatch();
  const isAssigned = assignedItemIds.some((id) => id === psAnswerId);
  const isTrashed = trashedItemIds.includes(psAnswerId);

  const itemClickHandler = (e) => {
    dispatch(setSelectedItem({ selectedItem: { text, psAnswerId } }));
  };

  return (
    <div
      className={`${styles['item-container']} ${
        selectedItem?.psAnswerId === psAnswerId ? styles['selected'] : ''
      }`}
      onClick={itemClickHandler}
    >
      <div className={styles['item-num']}>{num}</div>
      <div className={styles['item-assign']}>
        {isAssigned && '✔'}
        {isTrashed && '🗑️'}
      </div>
      <div className={styles['item-text']}>{text}</div>
    </div>
  );
}
