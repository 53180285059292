import React, { useEffect, useState } from "react";
import styles from "../styles/Admin.module.css";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import TableList from "../components/adminMain/TableList";
import { useAxiosContext } from "../context/AxiosContainer";
import TableHeader from "../components/adminMain/TableHeader";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { signOut, updateAccessToken } from "../redux/slice/login/loginInfo";
import { userTypes } from "../key/userTypes";
import Modal from "react-bootstrap/Modal";
import Pagination from "react-bootstrap/Pagination";

function MyVerticallyCenteredModal(props) {
  let navigate = useNavigate();
  const DeleteCase = async () => {
    await props.httpInterface
      .deleteCreateCase(props.accessToken)
      .then((res) => {
        console.log(res);
        navigate("/Admin/createCaseA", {
          state: {
            data: props.data,
          },
        });
      })
      .catch((error) => {
        console.log("error : ", error.response);
      });
  };

  const LoadCase = (leaveData) => {
    navigate("/Admin/createCaseA", {
      state: {
        leave: leaveData,
      },
    });
  };
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            기존에 생성하던 케이스가 있습니다.
            <br />
            기존 케이스를 불러오시겠습니까?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            [새로운 케이스 만들기] 를 누르면 기존 임시저장된 데이터는
            삭제됩니다.
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              LoadCase(props.leaveCreateCase);
              console.log(props.leaveCreateCase);
            }}
          >
            기존 케이스 불러오기
          </Button>
          <Button
            onClick={() => {
              DeleteCase();
            }}
          >
            새로운 케이스 만들기
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default function AdminMain() {

  const [dropdown, setDropdown] = useState("사례");
  const { roleType, accessToken } = useSelector((state) => state.loginInfo);
  const dispatch = useDispatch();
  const { httpInterface } = useAxiosContext();
  // 공백 행 없는 정제된 데이터리스트
  const [dataListComplete, setDataListComplete] = useState([]);
  const navigate = useNavigate();
  const [activeBtn, setActiveBtn] = useState(0);
  const [page, setPage] = useState();
  //console.log(testerDataList);
  let items = [];
  // pagination

  for (let i = 0; i < page; i++) {
    items.push(
      <Pagination.Item
        key={i}
        onClick={() => {
          setActiveBtn(i);
        }}
        active={i === activeBtn}
      >
        {i + 1}
      </Pagination.Item>
    );
  }

  useEffect(() => {
    httpInterface
      .getAdminPageData(accessToken, {
        params: { page: activeBtn, size: 10 },
      })
      .then((res) => {
        setDataListComplete(
          res.data.data.content?.filter((e) => {
            return e.subQuestionBody.length > 0;
          })
        );
        setPage(res.data.data.totalPages);
        console.log(res.data.data);
      })
      .catch((error) => {
        console.log("error : ", error.response);
      });
  }, [activeBtn]);
  const { isSuccess, isError, isLoading } = useQuery(
    ["getTesterData"],
    () => {
      return httpInterface
        .getAdminPageData(accessToken, {
          params: { page: activeBtn, size: 10 },
        })
        .then((res) => {
          setPage(res.data.data.totalPages);
          console.log(res.data.data);
        })
        .then(() => {
          return httpInterface.getFilterValueData(accessToken);
        })
        .catch((error) => {
          const { status } = error.response;
          console.log("AdminPage Component Error", error);
          if (status === 401) {
            // reissue Token 관련 코드 작성할 것
            httpInterface
              .reissueToken(accessToken)
              .then((response) => {
                console.log(response.data.data[0]);
                dispatch(
                  updateAccessToken({
                    accessToken: response.data.data[0].accessToken,
                  })
                );
              })
              .catch((error) => {
                console.log("Refresh Token Error");
                alert("유효정보가 만료되어 로그아웃되었습니다.");
                dispatch(signOut());
              });
          } else {
            console.log("Validation Token Error");
            alert("유효정보가 만료되어 로그아웃되었습니다.");
            dispatch(signOut());
          }
        });
    },
    {
      onError: (error) => {
        console.log("AdminPage Error", error);
      },
      onSuccess: (response) => {
        // setDropdownData(response.data.data[0]);
      },
    }
  );

  if (roleType !== userTypes.admin) {
    alert("관리자만 접근 가능한 페이지입니다.");
    navigate("/");
    dispatch(signOut());
  }
  const [leaveCreateCase, setLeaveCreateCase] = useState([]);
  const [modalShow, setModalShow] = useState(false);

  const checkCase = async () => {
    await httpInterface
      .getViewCase(accessToken)
      .then((res) => {
        console.log(res.data.data.length, res.data.data);
        setLeaveCreateCase(res.data.data);
        res.data.data.length !== 0
          ? setModalShow(true)
          : navigate("/Admin/createCaseA");
      })
      .catch((error) => {
        console.log("error : ", error.response);
      });
  };

  return (
    <>
      {/*<MyVerticallyCenteredModal*/}
      {/*  httpInterface={httpInterface}*/}
      {/*  accessToken={accessToken}*/}
      {/*  show={modalShow}*/}
      {/*  leaveCreateCase={leaveCreateCase}*/}
      {/*  onHide={() => setModalShow(false)}*/}
      {/*/>*/}
      {isLoading && "로딩중"}
      {isError && "에러가 발생했습니다..."}
      {isSuccess && (
        <div className={styles.Container}>
          <div className={styles.Title}>테스트관리</div>

          {/* 표위에 메뉴 */}
          <div className={styles.MenuWrap}>
            <Dropdown as={ButtonGroup} className={styles.Dropdown}>
              <Dropdown.Toggle
                id="dropdown-split-basic"
                className={styles.Dropdown}
              >
                {dropdown}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  eventKey="1"
                  onClick={() => {
                    setDropdown("사례");
                  }}
                >
                  사례
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="2"
                  onClick={() => {
                    setDropdown("2");
                  }}
                >
                  2
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="3"
                  onClick={() => {
                    setDropdown("3");
                  }}
                >
                  3
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="4"
                  onClick={() => {
                    setDropdown("4");
                  }}
                >
                  4
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <input type="search" className={styles.Search} />

            <Button className={styles.SearchBtn}>검색</Button>
            <Button
              className={styles.CreateBtn}
              onClick={() => {
                checkCase();
              }}
            >
              +신규 케이스 생성
            </Button>
          </div>

          <section className={styles["manage-container"]}>
            <TableHeader />
            <ul className={styles["answer-list-container"]}>
              {dataListComplete?.map((data, idx) => (
                <TableList key={data.subQuestionId + idx} batchData={data} />
              ))}
            </ul>
          </section>
        </div>
      )}
      <Pagination>{items}</Pagination>
    </>
  );
}
