import styles from "./StartTestHeader.module.css";
import { useSelector } from "react-redux";

import React from "react";
import { useLocation } from "react-router-dom";

export default function StartTestHeader() {
  let location = useLocation();
  return (
    <section style={{ fontSize: "0.7em", margin: "2% 0" }}>
      <div
        style={{
          display: "inline-block",
          marginRight: "2%",
          borderRadius: "25px",
          border: "2px solid #D9D9D9",
          padding: "1%",
        }}
      >
        {categoryTypes[location.state.data.category]}
      </div>
      <div
        style={{
          display: "inline-block",
          borderRadius: "25px",
          border: "2px solid #D9D9D9",
          padding: "1%",
        }}
      >
        난이도 {levelTypes[location.state.data.level]}
      </div>
    </section>
  );
}

const levelTypes = {
  EASY: 1,
  NORMAL: 2,
  HARD: 3,
};

const categoryTypes = {
  DAILY: "일상 생활 영역",
  BUSINESS: "일반 비즈니스 영역",
  JOB_SPECIFIC: "기업, 직무 특화 영역",
  INDUSTRY_SPECIFIC: "산업/사업 특화 영역",
  COMPANY_SPECIFIC: "회사/고객사 특화 영역",
};
