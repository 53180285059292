import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import styles from "../styles/CreateCaseR.module.css";
import Button from "react-bootstrap/Button";
import { useAxiosContext } from "../context/AxiosContainer";
import Modal from "../components/common/modal/Modal";
import { useSelector } from "react-redux";

export default function CreateCaseResearcher() {
  const { httpInterface } = useAxiosContext();
  const { accessToken } = useSelector((state) => state.loginInfo);
  let location = useLocation();

  const [data, setData] = useState();

  // 케이스 생성 추가 시 늘어나는 것 확인하기 위한 용도
  const [caseNum, setCaseNum] = useState([1]);

  const [createClick, setCreateClick] = useState(false);

  // 케이스 생성 시 필수 항목 확인
  const [emptyCheck, setEmptyCheck] = useState([
    {
      caseName: true,
      case: true,
      question1: true,
      question2: true,
    },
  ]);

  const [inputText, setInputText] = useState([
    {
      caseName: "",
      case: "",
      question1: "",
      question2: "",
      question3: "",
      question4: "",
    },
  ]);
  const [quizId, setQuizId] = useState();

  useEffect(() => {}, [caseNum]);
  useEffect(() => {
    httpInterface
      .getCreateCaseResearcherView(accessToken, {
        params: {
          taskId: location.state.data?.taskId,
        },
      })
      .then(async (res) => {
        console.log(res);
        await setQuizId(res.data.data[0].quizId);
        await setData(res.data.data[0]);
      })
      .catch((error) => {
        console.log("error : ", error.response);
      });
  }, []);

  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };
  // 추가생성 버튼 활성화 여부
  const [addBtnOn, setAddBtnOn] = useState(false);
  const [found, setFound] = useState();

  useEffect(() => {
    let copy = [...emptyCheck];

    if (data) {
      data?.caseName && (copy[0].caseName = false);
      data?.quizBody.length > 0 && (copy[0].case = false);
      data?.subQuestionBodyList[0].length > 0 && (copy[0].question1 = false);
      data?.subQuestionBodyList[1].length > 0 && (copy[0].question2 = false);
    } else {
      inputText[0].caseName.length >= 1
        ? (copy[0].caseName = false)
        : (copy[0].caseName = true);
      inputText[0].case.length >= 1
        ? (copy[0].case = false)
        : (copy[0].case = true);
      inputText[0].question1.length >= 1
        ? (copy[0].question1 = false)
        : (copy[0].question1 = true);
      inputText[0].question2.length >= 1
        ? (copy[0].question2 = false)
        : (copy[0].question2 = true);
    }
    setFound(
      copy.filter((e) => {
        console.log(e);
        return e.case === false &&
          e.caseName === false &&
          e.question1 === false &&
          e.question2 === false
          ? setAddBtnOn(true)
          : setAddBtnOn(false);
      })
    );

    setEmptyCheck(copy);
  }, [inputText, data]);

  let inputCopy = [...inputText];

  // 임시저장시
  const TemporaryStorage = () => {
    console.log(quizId);
    httpInterface
      .postCreateCase(accessToken, [
        {
          quizId: quizId,
          caseName: data?.caseName ? data?.caseName : inputText[0].caseName,
          quizBody: data?.quizBody ? data?.quizBody : inputText[0].case,
          subQuestionBodyList: data?.subQuestionBodyList
            ? data.subQuestionBodyList
            : [
                inputText[0].question1,
                inputText[0].question2,
                inputText[0].question3,
                inputText[0].question4,
              ],
        },
      ])
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log("error : ", error.response);
      });
  };
  return (
    <>
      <div className={styles.Container}>
        <span className={styles.Title}>케이스 생성</span>
        <div className={styles.TaskWrap}>
          <div className={styles.Researcher}></div>
          <div>
            <>
              <div className={styles.Task}>
                <div className={styles.DivBox}>
                  <span style={{ fontWeight: "bolder" }}>케이스 1</span>
                </div>
                <div className={styles.ResearcherBox}></div>
              </div>
              <div className={styles.ChoiceTask}>
                <div className={styles.DivBox}>
                  <span>유형*</span>
                </div>
                <div className={styles.Test}>
                  <span>{location.state.data?.category}</span>
                </div>
              </div>
              <div className={styles.ChoiceTask}>
                <div className={styles.DivBox}>
                  <span>난이도*</span>
                </div>
                <div className={styles.Test}>
                  <span style={{ width: "15%", fontSize: "1.2em" }}>
                    {location.state.data?.level}
                  </span>
                </div>
              </div>
              <div className={styles.ChoiceTask}>
                <div className={styles.DivBox}>
                  <span>언어*</span>
                </div>
                <div className={styles.Test}>
                  <span style={{ width: "15%", fontSize: "1.2em" }}>
                    {data?.language}
                  </span>
                </div>
              </div>
              <div className={styles.ChoiceTask}>
                <div className={styles.DivBox}>
                  <span>케이스명*</span>
                </div>
                <div className={styles.Test}>
                  <input
                    className={styles.Answer}
                    name="text"
                    value={data?.caseName ? data.caseName : inputCopy.caseName}
                    autoComplete="off"
                    onChange={(e) => {
                      data?.caseName
                        ? (data.caseName = e.target.value)
                        : (inputCopy[0].caseName = e.target.value);
                      setInputText(inputCopy);
                    }}
                  />
                  {createClick && emptyCheck[0].caseName && (
                    <span>필수 정보를 입력해주세요.</span>
                  )}
                </div>
              </div>
              <div className={styles.ChoiceTask}>
                <div className={styles.DivBox}>
                  <span>상황*</span>
                </div>
                <div className={styles.Test}>
                  <input
                    className={styles.Answer}
                    name="text"
                    value={data?.quizBody ? data.quizBody : inputCopy.case}
                    autoComplete="off"
                    onChange={(e) => {
                      data?.quizBody
                        ? (data.quizBody = e.target.value)
                        : (inputCopy[0].case = e.target.value);
                      console.log(inputCopy);
                      setInputText(inputCopy);
                    }}
                  />
                  {createClick && emptyCheck[0].case && (
                    <span>필수 정보를 입력해주세요.</span>
                  )}
                </div>
              </div>
              <div className={styles.ChoiceTask}>
                <div className={styles.DivBox}>
                  <span>질문 1*</span>
                </div>
                <div className={styles.Test}>
                  <input
                    className={styles.Answer}
                    name="text"
                    value={
                      data?.subQuestionBodyList[0]
                        ? data.subQuestionBodyList[0]
                        : inputCopy.question1
                    }
                    autoComplete="off"
                    onChange={(e) => {
                      data?.subQuestionBodyList[0]
                        ? (data.subQuestionBodyList[0] = e.target.value)
                        : (inputCopy[0].question1 = e.target.value);
                      setInputText(inputCopy);
                    }}
                  />
                  {createClick && emptyCheck[0].question1 && (
                    <span>필수 정보를 입력해주세요.</span>
                  )}
                </div>
              </div>
              <div className={styles.ChoiceTask}>
                <div className={styles.DivBox}>
                  <span>질문 2*</span>
                </div>
                <div className={styles.Test}>
                  <input
                    className={styles.Answer}
                    name="text"
                    value={
                      data?.subQuestionBodyList[1]
                        ? data.subQuestionBodyList[1]
                        : inputCopy.question2
                    }
                    autoComplete="off"
                    onChange={(e) => {
                      data?.subQuestionBodyList[1]
                        ? (data.subQuestionBodyList[1] = e.target.value)
                        : (inputCopy[0].question2 = e.target.value);

                      setInputText(inputCopy);
                    }}
                  />
                  {createClick && emptyCheck[0].question2 && (
                    <span>필수 정보를 입력해주세요.</span>
                  )}
                </div>
              </div>
              <div className={styles.ChoiceTask}>
                <div className={styles.DivBox}>
                  <span>질문 3</span>
                </div>
                <div className={styles.Test}>
                  <input
                    className={styles.Answer}
                    name="text"
                    value={
                      data?.subQuestionBodyList[2]
                        ? data.subQuestionBodyList[2]
                        : inputCopy.question3
                    }
                    autoComplete="off"
                    onChange={(e) => {
                      data?.subQuestionBodyList[2]
                        ? (data.subQuestionBodyList[2] = e.target.value)
                        : (inputCopy[0].question3 = e.target.value);
                      setInputText(inputCopy);
                    }}
                  />
                </div>
              </div>
              <div className={styles.ChoiceTask}>
                <div className={styles.DivBox}>
                  <span>질문 4</span>
                </div>
                <div className={styles.Test}>
                  <input
                    className={styles.Answer}
                    name="text"
                    value={
                      data?.subQuestionBodyList[3]
                        ? data.subQuestionBodyList[3]
                        : inputCopy.question4
                    }
                    autoComplete="off"
                    onChange={(e) => {
                      data?.subQuestionBodyList[3]
                        ? (data.subQuestionBodyList[3] = e.target.value)
                        : (inputCopy[0].question4 = e.target.value);
                      setInputText(inputCopy);
                    }}
                  />
                </div>
              </div>
            </>

            <div className={styles.FinishBtn}>
              <Button
                onClick={() => {
                  TemporaryStorage();
                }}
              >
                임시저장
              </Button>
              <Button
                onClick={() => {
                  console.log(inputText, addBtnOn);
                  setCreateClick(true);
                  addBtnOn && openModal();
                }}
              >
                생성하기
              </Button>
            </div>
          </div>
          <Modal
            modalId={2}
            id={6}
            open={modalOpen}
            close={closeModal}
            data={data}
            inputText={inputText}
            taskId={location.state.data?.taskId}
            message="해당 케이스를 생성하시겠습니까?"
          />
        </div>
      </div>
    </>
  );
}
