import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import styles from "../../../styles/Modal.module.css";
import ButtonVersion3 from "./ButtonVersion3";
import { useAxiosContext } from "../../../context/AxiosContainer";
import { useLoginContext } from "../../../context/LoginContext";
import { userTypes } from "../../../key/userTypes";
import CreateCaseResearcherBtn from "./CreateCaseResearcherBtn";

const Modal = (props) => {
  const {
    open: isOpen,
    close: closeModal,
    message,
    id: ButtonType,
    modalId,
    data,
    inputText,
    taskId,
  } = props;
  const { t } = useTranslation();
  const { httpInterface } = useAxiosContext();
  const { userType } = useLoginContext();

  return (
    <div className={isOpen ? styles.OpenModal : styles.Modal}>
      {isOpen ? (
        <section
          className={modalId === 1 ? styles.Container : styles.ContainerSmall}
        >
          <div style={{ textAlign: "right" }}>
            <button className={styles.CloseX} onClick={closeModal}>
              &times;
            </button>
          </div>
          <div className={styles.Content}>
            <b>{message}</b>
          </div>
          <div style={{ textAlign: "center" }}>
            {ButtonType === 3 && <ButtonVersion3 closeModal={closeModal} />}

            {ButtonType === 6 && (
              <CreateCaseResearcherBtn
                closeModal={closeModal}
                data={data}
                inputText={inputText}
                taskId={taskId}
              />
            )}
            {ButtonType !== 3 && ButtonType !== 6 && (
              <button className={styles.CloseBtn} onClick={closeModal}>
                {t("check")}
              </button>
            )}
          </div>
        </section>
      ) : null}
    </div>
  );
};

export default Modal;
