import React, { useEffect, useState } from "react";
import styles from "../../styles/App.module.css";

import LoginInput from "./loginForm/LoginInput";
import ValidateEmailText from "./loginForm/ValidateEmailText";
import LoginButton from "./loginForm/LoginButton";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAxiosContext } from "../../context/AxiosContainer";
import { useLoginContext } from "../../context/LoginContext";
import { userTypes } from "../../key/userTypes";
import { useMutation } from "@tanstack/react-query";
import { signIn } from "../../redux/slice/login/loginInfo";
import { updateTaskInfo } from "../../redux/slice/taskInfo/taskInfo";

const regExp =
  /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,6}$/i;

export default function LoginForm({ isPc }) {
  let navigate = useNavigate();
  const { i18n } = useTranslation();
  const [userEmailInput, setUserEmailInput] = useState("");
  const [isEmail, setIsEmail] = useState(false);
  const { httpInterface } = useAxiosContext();
  // const { setAuth } = useAuthContext();
  const dispatch = useDispatch();

  const { setUserEmail, setUserType } = useLoginContext();
  const [userPasswordInput, setUserPasswordInput] = useState("");

  const { mutate } = useMutation(
    () =>
      userPasswordInput.length > 0
        ? httpInterface.signInNew({
            email: userEmailInput,
            password: userPasswordInput,
          })
        : httpInterface.signIn({
            email: userEmailInput,
          }),
    {
      onSuccess: (response) => {
        // 성공했을 때,
        const {
          tokenSet: { accessToken },
          userInfo: { roleType, language, taskInfo },
        } = response.data.data[0];

        // accessToken && setAuth(accessToken);
        isEmail && userEmailInput && setUserEmail(userEmailInput);
        roleType && setUserType(roleType);
        dispatch(
          signIn({ email: userEmailInput, roleType, language, accessToken })
        );

        roleType === userTypes.researcher &&
          taskInfo &&
          dispatch(updateTaskInfo({ taskInfo }));
        language === "ENGLISH" && i18n.changeLanguage("en");
        language === "KOREAN" && i18n.changeLanguage("ko");

        redirectPage(roleType);
      },
      onError: (error) => {
        console.log("LoginForm Error: ", error);
      },
    }
  );

  useEffect(() => {
    setIsEmail(regExp.test(userEmailInput));
  }, [userEmailInput]);

  const submitHandler = (e) => {
    e.preventDefault();
  };

  const redirectPage = (userType) => {
    const { user, tester, admin } = userTypes;
    let url;
    // TODO: 테스터를 연구원으로 변경
    switch (userType) {
      case user:
        url = "/Main";
        break;
      case tester:
        url = "/MainR";
        break;
      case admin:
        url = "/Admin";
        break;
      default:
        alert("발급받은 아이디로만 접속가능합니다.");
        break;
    }
    if ([user, tester, admin].includes(userType)) navigate(url);
  };

  return (
    <form className={styles.EmailForm} onSubmit={submitHandler}>
      <LoginInput
        setUserEmailInput={setUserEmailInput}
        setUserPasswordInput={setUserPasswordInput}
        userEmailInput={userEmailInput}
        isEmail={isEmail}
        login={mutate}
        isPc={isPc}
      />

      <LoginButton isEmail={isEmail} login={mutate} isPc={isPc} />
      <div
        style={{
          marginTop: "10vh",
          textDecoration: "underline",
          fontSize: "1em",
        }}
      >
        <span
          style={{ cursor: "pointer" }}
          onClick={() => navigate("ChangePasswordEmail")}
        >
          비밀번호를 잊었다면?
        </span>
      </div>
      <div
        style={{
          marginTop: "3%",
          textDecoration: "underline",
          fontSize: "1em",
        }}
      >
        <span
          style={{ cursor: "pointer" }}
          onClick={() => navigate("ResendEmail")}
        >
          인증 메일이 오지 않는다면?
        </span>
      </div>
    </form>
  );
}
