import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import styles from "../../styles/ResearcherTask.module.css";
import { useState } from "react";

export default function DropdownCountry({
  setLanguageChoose,
  leave,
  languageChoose,
  idx,
  excelData,
}) {
  const [languageList, setLanguageList] = useState(["KOREAN", "ENGLISH"]);

  const [dropdown, setDropdown] = useState(
    (leave && leave.language) || "KOREAN"
  );

  return (
    <Dropdown as={ButtonGroup} className={styles.Dropdown}>
      <Dropdown.Toggle id="dropdown-split-basic" className={styles.Dropdown}>
        {leave?.language ? languageChoose[idx].language : dropdown}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {languageList.map((a, i) => {
          return (
            <>
              <Dropdown.Item
                className={styles.Dropdown}
                eventKey={i + 1}
                key={i}
                onClick={() => {
                  setDropdown(languageList[i]);
                  leave?.[idx]?.language
                    ? (leave[idx].language = languageList[i])
                    : (languageChoose[idx].language = languageList[i]);
                  setLanguageChoose(languageChoose);
                }}
              >
                {languageList[i]}
              </Dropdown.Item>
            </>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}
