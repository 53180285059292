import React from "react";
import styles from "../../../styles/CreateCase.module.css";

export default function Question3({
  idx,
  temporaryStorage,
  inputCopy,
  setInputText,
}) {
  return (
    <div className={styles.ChoiceTask}>
      <div className={styles.DivBox}>
        <span>질문 3</span>
      </div>
      <div className={styles.Test}>
        <input
          className={styles.Answer}
          name="text"
          value={
            temporaryStorage?.[idx]?.subQuestionBodyList[2]
              ? temporaryStorage[idx].subQuestionBodyList[2]
              : inputCopy.question3
          }
          autoComplete="off"
          onChange={(e) => {
            temporaryStorage?.[idx]?.subQuestionBodyList[2]
              ? (temporaryStorage[idx].subQuestionBodyList[2] = e.target.value)
              : (inputCopy[idx].question3 = e.target.value);
            setInputText(inputCopy);
          }}
        />
      </div>
    </div>
  );
}
