import React, { useState, useEffect } from "react";
import styles from "../styles/ResearcherTask.module.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DropdownTask from "../components/managementMember/DropdownTask";
import DropdownCase from "../components/managementMember/DropdownCase";
import { useAxiosContext } from "../context/AxiosContainer";
import DropdownLevel from "../components/managementMember/DropdownLevel";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import ChoiceTestList from "../components/managementMember/ChoiceTestList";
import { useSelector } from "react-redux";
import SearchResearcher from "../components/managementMember/SearchResearcher";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function ResearcherTask() {
  let location = useLocation();
  const { accessToken } = useSelector((state) => state.loginInfo);
  const { httpInterface } = useAxiosContext();

  const [levelList, setLevelList] = useState([]);
  const [category, setCategory] = useState([]);
  const [taskType, setTaskType] = useState([]);
  const [data, setData] = useState([]);
  const [levelSearch, setLevelSearch] = useState();
  const [categorySearch, setCategorySearch] = useState();
  const [taskSearch, setTaskSearch] = useState();
  const [id, setId] = useState([]);

  const [searchTF, setSearchTF] = useState(false);

  // 연구원 배정 확인 TF
  const [researcherTF, setResearcherTF] = useState(false);
  // 연구원 배정 input 보이게 하는 TF
  const [inputTF, setInputTF] = useState(false);
  const [remove, setRemove] = useState(false);

  const [searchEmail, setSearchEmail] = useState();
  const [keyword, setKeyword] = useState();
  const [results, setResult] = useState([]);

  //케이스 생성 개수
  const [caseNum, setCaseNum] = useState(0);

  // 업무 아이디 선택
  const [choiceTest, setChoiceTest] = useState([]);

  let navigation = useNavigate();

  useEffect(() => {
    httpInterface
      .getTaskFilter(accessToken)
      .then(async (res) => {
        console.log(res);
        await setLevelList(res.data.data[0].levelList);
        await setCategory(res.data.data[0].quizCategoryList);
        await setTaskType(res.data.data[0].taskTypeList);
      })
      .then(() => {
        return httpInterface.getFilterValueData(accessToken);
      })
      .catch((error) => {
        console.log("error : ", error.response);
      });

    httpInterface
      .getResearcherView(accessToken)
      .then(async (res) => {
        console.log(res);
        await setSearchEmail(res.data.data);
      })
      .catch((error) => {
        console.log("error : ", error.response);
      });
  }, []);

  // 업무 검색
  const Search = () => {
    console.log(taskSearch, categorySearch, taskType[0], levelList[0], id);
    if (taskSearch !== "CREATE_PS") {
      httpInterface
        .getTaskSearch(accessToken, {
          researcherIdList: id,
          taskType: taskSearch ? taskSearch : taskType[0],
          level: levelSearch ? levelSearch : null,
          quizCategory: categorySearch ? categorySearch : null,
          searchWord: "",
        })
        .then(async (res) => {
          console.log(res);
          await setData(res.data.data);
        })
        .catch((error) => {
          console.log("error : ", error.response);
        });
    }
  };
  // 필드 업데이트
  const updateField = (field, value, update = true) => {
    if (update) onSearch(value);
    if (field === "keyword") {
      setKeyword(value);
    }
    if (field === "results") {
      setResult(value);
    }
  };

  // 입력된 텍스트로 data 배열에서 매칭되는 결과들을 저장
  const onSearch = (text) => {
    let results = searchEmail.filter(
      (item) => true === matchName(item.email, item.name, text)
    );
    console.log(results);
    setResult({ results });
  };

  // 검색해야할 문자열을 키워드와 비교하여 매칭되는지 체크
  const matchName = (email, name, keyword) => {
    let keyLen = keyword && keyword.length;

    email = email.toLowerCase().substring(0, keyLen);
    name = name && name.substring(0, keyLen).trim();

    //console.log(name === keyword.toString().toLowerCase());

    if (keyword === "") return false;
    else if (name === keyword.toString().toLowerCase()) return true;
    else if (email === keyword.toString().toLowerCase()) return true;
  };
  console.log(id);

  // 마감기한 선택
  const [endDate, setEndDate] = useState(new Date());
  const [year, setYear] = useState();
  const [month, setMonth] = useState();
  const [date, setDate] = useState();

  useEffect(() => {
    setYear(endDate.getFullYear());
    setMonth(endDate.getMonth() + 1);
    console.log(endDate.getMonth() + 1);

    setDate(endDate.getDate());
  }, [endDate]);

  // 업무 할당
  const TaskAssignment = () => {
    console.log(caseNum);
    if (taskSearch === "CREATE_PS") {
      httpInterface
        .postResearcherTask(accessToken, {
          researcherIdList: [id],
          taskType: taskSearch ? taskSearch : taskType[0],
          deadLine: `${year}-${month < 10 ? "0" + month : month}-${
            date < 10 ? "0" + date : date
          }`,
          caseCreateList: [
            {
              quizCategory: categorySearch ? categorySearch : category[0],
              level: levelSearch ? levelSearch : levelList[0],
              numberOfCase: caseNum,
            },
          ],
        })
        .then(async (res) => {
          console.log(res);
        })
        .catch((error) => {
          console.log("error : ", error.response);
        });
    } else {
      httpInterface
        .postResearcherTask(accessToken, {
          researcherIdList: [id],
          testIdList: choiceTest,
          taskType: taskSearch ? taskSearch : taskType[0],
          deadLine: `${year}-${month < 10 ? "0" + month : month}-${
            date < 10 ? "0" + date : date
          }`,
        })
        .catch((error) => {
          console.log("error : ", error.response);
        });
    }
  };

  return (
    <>
      <div className={styles.Container}>
        <span className={styles.Title}>연구원 업무 배정</span>
        <div className={styles.TaskWrap}>
          <div className={styles.Researcher}>
            <div className={styles.DivBox}>
              <span>담당 연구원:</span>
            </div>
            <div className={styles.ResearcherBox}>
              {(inputTF || remove) && (
                <SearchResearcher
                  keyword={keyword}
                  results={results}
                  updateField={updateField}
                  setId={setId}
                  remove={remove ? true : false}
                  setRemove={setRemove}
                  researcherTF={researcherTF}
                  setResearcherTF={setResearcherTF}
                ></SearchResearcher>
              )}
              {
                <Button
                  onClick={() => {
                    setInputTF(true);
                    id.length > 0 ? setRemove(true) : setRemove(false);
                  }}
                >
                  {id.length > 0 ? "-" : "+"}
                </Button>
              }
              <Button
                style={{ float: "right" }}
                onClick={() => {
                  setResearcherTF(true);
                  onSearch();
                }}
                disabled={id.length > 0 ? false : true}
              >
                확인
              </Button>
            </div>
          </div>
          <div className={researcherTF ? styles.TaskBoxOn : styles.TaskBoxOff}>
            <div className={styles.Task}>
              <div className={styles.DivBox}>
                <span>업무 1</span>
              </div>
              <div className={styles.ResearcherBox}>
                <span>
                  <Container style={{ textAlign: "center" }}>
                    <Row style={{ marginBottom: "1.5%" }}>
                      <Col>업무분류</Col>
                      <Col>케이스 유형 분류</Col>
                      <Col>난이도</Col>
                      <Col> </Col>
                    </Row>
                    <Row>
                      <Col>
                        <DropdownTask
                          taskType={taskType}
                          setTaskType={setTaskSearch}
                          researcherTF={researcherTF}
                        />
                      </Col>
                      <Col>
                        <DropdownCase
                          category={category}
                          setCategory={setCategorySearch}
                          researcherTF={researcherTF}
                        />
                      </Col>
                      <Col>
                        <DropdownLevel
                          level={levelList}
                          setLevel={setLevelSearch}
                          researcherTF={researcherTF}
                        />
                      </Col>
                      <Col>
                        <Button
                          disabled={researcherTF ? false : true}
                          onClick={() => {
                            Search();
                            setSearchTF(true);
                          }}
                        >
                          검색
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </span>
              </div>
            </div>
            <div className={styles.ChoiceTask}>
              <div className={styles.DivBox}>
                <span> </span>
              </div>
              <div className={styles.Test}>
                <ChoiceTestList
                  data={data}
                  researcherTF={researcherTF}
                  choice={choiceTest}
                  setChoiceTest={setChoiceTest}
                  createPs={taskSearch == "CREATE_PS" ? true : false}
                  taskType={taskSearch ? taskSearch : taskType[0]}
                  level={levelSearch ? levelSearch : null}
                  category={categorySearch ? categorySearch : null}
                  setCaseNum={setCaseNum}
                  searchTF={searchTF}
                />
              </div>
            </div>
            <div className={styles.ChoiceTask}>
              <div className={styles.DivBox}>
                <span> </span>
              </div>
              <div className={styles.DueDate}>
                <div style={{ display: "flex" }}>
                  <span style={{ width: "15%", fontSize: "1.2em" }}>
                    업무 마감일
                  </span>
                  <DatePicker
                    className={styles.Calender}
                    dateFormat="yyyy-MM-dd"
                    selected={endDate}
                    onChange={(date) => {
                      setEndDate(date);
                    }}
                    minDate={new Date()}
                  />
                </div>
              </div>
            </div>
            <div className={styles.FinishBtn}>
              <Button
                onClick={() => {
                  TaskAssignment();
                  navigation("/Admin/managementResearcher", {
                    state: {
                      taskType: taskSearch ? taskSearch : taskType[0],
                      researcherId: id,
                    },
                  });
                }}
              >
                업무 배정하기
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
