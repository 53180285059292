import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './NodeList.module.css';
import { theoryMode } from '../../../../key/theoryMode';
import {
  changePopupMode,
  setCurrentCategory,
} from '../../../../redux/slice/theory/createTheory';
import { popupMode } from '../../../../key/popupMode';
import EditCategoryInput from './EditCategoryInput';

export default function CategoryData({ category }) {
  // TODO: 실시간 저장 코드 구현
  const { mode, currentTarget } = useSelector((state) => state.createTheory);
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    // 변경하려는 카테고리 이외의 데이터는 editMode를 false로
    if (currentTarget.category !== category) {
      setEdit(false);
    }
  }, [currentTarget.category]);

  if (edit && currentTarget.category === category) return <EditCategoryInput />;

  return (
    !edit && (
      <div
        className={`${styles.default} ${
          category === currentTarget.category ? styles.selected : ''
        }`}
        onClick={() => {
          if (mode === theoryMode.modify || mode === theoryMode.delete) {
            dispatch(setCurrentCategory({ category }));
          } else {
            alert('수정이나 삭제모드가 아닙니다.');
          }
        }}
        onDoubleClick={() => {
          if (currentTarget.category !== category) return;
          if (mode === theoryMode.modify) {
            setEdit(true);
          }
          if (mode === theoryMode.delete) {
            // alert('모달 띄울 예정. 삭제로직 구현- TheoryData 컴포넌트');
            dispatch(changePopupMode({ popupMode: popupMode.deleteCategory }));
          }
        }}
      >
        {category.getText()}
      </div>
    )
  );
}
