import React, { forwardRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useAxiosContext } from "../../../context/AxiosContainer";
import { setAnswerList, setSolvedTime } from "../startTest/button/NextButton";

const initTime = 60 * 5;
const Timer = forwardRef((props, { submit, next }) => {
  const [totalTime, setTotalTime] = useState(initTime);
  const [minute, setMinute] = useState(Math.floor(totalTime / 60));
  const [second, setSecond] = useState(totalTime % 60);

  const [modalOpen, setModalOpen] = useState(false);
  const { httpInterface } = useAxiosContext();
  const { accessToken } = useSelector((state) => state.loginInfo);

  const { answersForOneQuestion } = useSelector((state) => state.answers);
  const answerList = setAnswerList(answersForOneQuestion);

  let location = useLocation();

  useEffect(() => {
    setTotalTime(initTime);
  }, [location.state.data.subQuestionId]);

  const navigate = useNavigate();
  useEffect(() => {
    if (totalTime === 0) return;

    const timerId = setTimeout(() => {
      setTotalTime((prev) => prev - 1);
    }, 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, [totalTime]);

  useEffect(() => {
    setMinute(Math.floor(totalTime / 60));
    setSecond(totalTime % 60);
  }, [totalTime]);

  useEffect(() => {
    let timerId;
    if (totalTime === 0 && second === 0) {
      // submit 버튼 클릭
      timerId = setTimeout(() => {
        httpInterface
          .getNextQuizData(accessToken, {
            subQuestionId: location.state.data.subQuestionId,
            answerList: answerList.map((a, i) => ({
              solvedTime: setSolvedTime(answersForOneQuestion)[i],
              body: answerList[i],
            })),
          })
          .then((res) => {
            console.log(res);
          })
          .catch((error) => {
            console.log("error : ", error.response);
          });
        setModalOpen(true);
      }, 500);
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [second]);

  return (
    <>
      <span
        style={{
          marginRight: "1%",
          float: "right",
        }}
      >
        0{minute}:{second < 10 ? "0" + second : second}
      </span>

      <Modal
        show={modalOpen}
        onHide={() => setModalOpen(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <span>
            제출한 답변의 분석 결과를 확인하거나
            <br />
            계속해서 문제를 풀 수 있어요.
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              navigate("/Rank", {
                state: {
                  idx: location.state.data.subNumber,
                  caseId: location.state.caseId,
                },
              });
              setModalOpen(false);
            }}
          >
            결과 보기
          </Button>
          <Button
            onClick={() => {
              httpInterface
                .getGotoStartTest(
                  "/v2/api/caseps/" + location.state.nextCaseId,
                  accessToken
                )
                .then((res) => {
                  console.log(res);
                  navigate("/StartTest", {
                    state: {
                      data: res.data.data[0],
                      caseId: location.state.nextCaseId,
                    },
                  });
                })
                .catch((error) => {
                  console.log("error : ", error.response);
                });
              setModalOpen(false);
            }}
          >
            다음 문제 풀기
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
});
export default Timer;
