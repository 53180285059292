import { useTranslation } from "react-i18next";
import styles from "../styles/StartTest.module.css";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleMinus } from "@fortawesome/free-solid-svg-icons";
import Modal from "../components/common/modal/Modal";
import { FaPlusCircle } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import CheckInputLength from "../components/CheckInputLength";
import { FaArrowLeft, FaArrowRight, FaRegQuestionCircle } from "react-icons/fa";
import Popover from "react-bootstrap/Popover";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

export default function TestPage() {
  const { t } = useTranslation();
  const location = useLocation();
  console.log(location.state);
  const [modalOpen, setModalOpen] = useState(false);
  const [wholeInputData, setWholeInputData] = useState([{ text: "" }]);
  const [currentInputValue, setCurrentInputValue] = useState("");
  const [modal1, setModal1] = useState(true);
  const [modal2, setModal2] = useState(true);
  const [countInput, setCountInput] = useState(0);
  const [userInputLength, setUserInputLength] = useState(0);

  const openModal = (a) => {
    a === 1 && setModal1(false);
    a === 1 && setModal2(true);
    a === 2 && setModal2(false);
    a === 2 && setModal1(true);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  const handleOnKeyPress = (e) => {
    if (e.key === "Enter") {
      wholeInputData[userInputLength] && wholeInputData[userInputLength].text
        ? handleAddClick()
        : openModal();
      setModal1(true);
    }
  };
  // input 선택이 변경되었을 경우, currentInputValue
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...wholeInputData];
    list[index][name] = value;
    setWholeInputData(list);
    setCurrentInputValue(e.target.value);
  };

  const handleRemoveClick = (i) => {
    const list = wholeInputData.filter((_, idx) => idx !== i);
    setWholeInputData(list);
    setWholeInputData(list);
    setCountInput(countInput - 1);
    setUserInputLength(userInputLength - 1);
  };

  // input 추가 + 전역 input data 관리
  const handleAddClick = () => {
    currentInputValue && setWholeInputData([...wholeInputData, { text: "" }]);
    setCurrentInputValue("");
    setCountInput(countInput + 1);
    setUserInputLength(userInputLength + 1);
  };

  return (
    <>
      <div className={styles.TestWrap}>
        <div className={styles.Test}>
          <div
            style={{ marginLeft: "3%", color: "#878787", marginBottom: "5%" }}
          >
            {t("example")}
          </div>
          <div className={styles.QuestionWrap}>
            <p style={{ color: "#1D1D1D", margin: 0 }}>{t("startTestTitle")}</p>
            <div className={styles.Content}>
              <p
                style={{
                  fontSize: "1em",
                  color: "black",
                  marginTop: "1.5%",
                }}
              >
                {t("testPage_question")}
              </p>
            </div>
            <p>
              <b>{t("testPage_subQuestion")}</b>
            </p>
          </div>
          <div className={styles.AnswerWrap}>
            <p className={styles.SmallText}>
              {t("option1")}
              {
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Popover
                      id={`popover-positioned-bottom`}
                      style={{ backgroundColor: "#F0F0F0" }}
                    >
                      <Popover.Body>
                        <ul>
                          <li>{t("tooltip1")}</li>
                          <span>
                            <b>{t("tooltip2")}</b>
                          </span>
                          <li>{t("tooltip3")}</li>
                          <span>
                            <b>{t("tooltip4")}</b>
                          </span>
                          <li>{t("tooltip5")}</li>
                          <span>
                            <b>{t("tooltip6")}</b>
                          </span>
                        </ul>
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <Button
                    style={{
                      backgroundColor: "white",
                      border: "none",
                    }}
                  >
                    <FaRegQuestionCircle className={styles.QuestionIcon} />
                  </Button>
                </OverlayTrigger>
              }
            </p>
            <p className={styles.SmallText}>{t("option2")}</p>
            {wholeInputData.map((x, i) => {
              return (
                <div key={i} className={styles.InputWrap}>
                  {countInput === i && (
                    <>
                      <div className={styles.AnswerTitle}>
                        <span style={{ marginRight: "65%" }}>
                          <b>
                            {t("answerText")}
                            {countInput + 1}
                          </b>
                        </span>
                        {/* 글자수 체크 */}
                        <CheckInputLength
                          textLength={wholeInputData[i].text.length}
                        />
                      </div>
                      {
                        <Button
                          className={
                            userInputLength > 0
                              ? styles.Arrow
                              : styles.ArrowNone
                          }
                          onClick={() => {
                            countInput > 0 && setCountInput(countInput - 1);
                            userInputLength > 0 &&
                              setUserInputLength(userInputLength - 1);
                          }}
                        >
                          <FaArrowLeft />
                        </Button>
                      }
                      <input
                        id={i}
                        className={styles.Answer}
                        name="text"
                        value={x.text}
                        autoComplete="off"
                        onKeyDown={handleOnKeyPress}
                        autoFocus
                        onChange={(e) => {
                          handleInputChange(e, i);
                        }}
                        placeholder={t("modal_inputText")}
                        maxLength={35}
                      />

                      {
                        <Button
                          className={
                            wholeInputData.length - 1 > userInputLength &&
                            wholeInputData[i].text.length > 0
                              ? styles.Arrow
                              : styles.ArrowNone
                          }
                          onClick={() => {
                            countInput >= 0 && setCountInput(countInput + 1);
                            userInputLength >= 0 &&
                              setUserInputLength(userInputLength + 1);
                          }}
                        >
                          <FaArrowRight />
                        </Button>
                      }
                      {wholeInputData.length !== 1 && i > 9 && (
                        <FontAwesomeIcon
                          className={styles.RemoveBtn}
                          onClick={() => handleRemoveClick(i)}
                          icon={faCircleMinus}
                        />
                      )}
                    </>
                  )}
                </div>
              );
            })}

            {/* 추가 버튼 */}
            <div>
              {userInputLength === countInput && (
                <button
                  className={styles.AddBtn}
                  onClick={() => {
                    wholeInputData[wholeInputData.length - 1].text
                      ? handleAddClick()
                      : openModal(1);
                  }}
                >
                  <FaPlusCircle className={styles.PlusIcon} />
                  <span className={styles.AddText}>{t("addAnswer")}</span>
                </button>
              )}
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            {wholeInputData[countInput].text.length > 0 ? (
              <button
                className={styles.NextBtnOn}
                onClick={() => {
                  openModal(2);
                }}
              >
                <b>{t("testStart")}</b>
              </button>
            ) : (
              <button className={styles.NextBtnOff} disabled>
                <b>{t("testStart")}</b>
              </button>
            )}
            {/* {!modal1 && (
              <Modal
                modalId={2}
                open={modalOpen}
                close={closeModal}
                header="Modal heading"
                message={t("modal_addText")}
              />
            )} */}
            {!modal2 && (
              <Modal
                modalId={1}
                id={3}
                open={modalOpen}
                close={closeModal}
                message={
                  <>
                    <p>{t("modal_startTest1")}</p>
                    <p>{t("modal_startTest2")}</p>
                    <p>{t("modal_startTest3")}</p>
                  </>
                }
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
