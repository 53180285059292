import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import React, { useState } from "react";
import styles from "../../../styles/AdminHeader.module.css";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from "../../../redux/slice/login/loginInfo";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export default function AdminHeader({ isInCreateCasePage }) {
  const { email } = useSelector((state) => state.loginInfo);
  const { t } = useTranslation();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [creating, setCreating] = useState(false);
  const [headerNum, setHeaderNum] = useState();

  return (
    <>
      <Navbar bg="light" variant="light" className={styles.HeaderWrap}>
        <Container>
          <Navbar.Brand className={styles.ListTitle}>
            <span
              className={styles.List}
              onClick={() => {
                isInCreateCasePage ? setCreating(true) : navigate("/Admin");
                isInCreateCasePage && setHeaderNum(1);
                console.log(isInCreateCasePage ? "케이스생성페이지중" : "아님");
              }}
              style={{
                fontWeight:
                  (pathname === "/Admin" ||
                    pathname === "/Admin/createCaseA") &&
                  "bold",
              }}
            >
              {t("managementTest")}
            </span>
            <span
              className={styles.List}
              style={{
                fontWeight:
                  (pathname.includes("management") ||
                    pathname.includes("Researcher") ||
                    pathname.includes("member")) &&
                  "bold",
              }}
              onClick={() => {
                isInCreateCasePage
                  ? setCreating(true)
                  : navigate("/Admin/managementMember");
                isInCreateCasePage && setHeaderNum(2);
              }}
            >
              {t("managementMember")}
            </span>
          </Navbar.Brand>
          <Nav
            className="justify-content-end"
            style={{ width: "30%", marginRight: "0" }}
          >
            <span
              style={{ marginRight: "10%", width: "100%", fontWeight: "bold" }}
            >
              {email}
            </span>
            <span
              style={{ marginRight: "10%", width: "100%", fontWeight: "bold" }}
              onClick={() => {
                dispatch(signOut());
                navigate("/");
              }}
            >
              {t("logout")}
            </span>
          </Nav>
        </Container>
      </Navbar>
      <Modal
        show={creating}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setCreating(false)}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          케이스 생성이 완료되지 않았습니다.
          <br />
          다른 페이지로 이동하시겠습니까?
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setCreating(false);
            }}
          >
            케이스 생성 계속하기
          </Button>
          <Button
            onClick={() => {
              headerNum === 1
                ? navigate("/Admin")
                : navigate("/Admin/managementMember");
              setCreating(false);
            }}
          >
            이동하기
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
