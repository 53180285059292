import React, { useEffect, useState } from 'react';
import TheoryGraph from '../theoryGraph/TheoryGraph';
import { useDispatch, useSelector } from 'react-redux';
import { TheoryTreeManager } from '../../../api/theory/theoryTree';
import { Category } from '../../../api/theory/category';
import { XlsxTheoryCanvas } from '../../../api/d3/d3';
import { gradientColor } from './color';
import {
  setCategoryList,
  setTheoryList,
} from '../../../redux/slice/theory/xlsxCreateTheory';
import {
  calcCategoryList,
  calcTheoriesDegreeAndDepth,
  calculateScreenCoordByCircleCoord,
  getTheories,
} from './xlsxTheoryUtils';

export default function XlsxTheory() {
  const [hasData, setHasData] = useState(false);
  const { theoryTreeManager, theoryList, categoryList } = useSelector(
    (state) => state.xlsxTheory
  );

  const dispatch = useDispatch();
  const createCanvas = new XlsxTheoryCanvas();

  useEffect(() => {
    if (!(theoryTreeManager instanceof TheoryTreeManager)) {
      setHasData(false);
    } else {
      setHasData(true);
    }
  }, [theoryTreeManager]);

  useEffect(() => {
    if (!hasData) return;

    const categories = theoryTreeManager.getTheoryTrees().map((tree) => {
      const xlsxCategory = tree.getCategory();
      const category = new Category(xlsxCategory.getText());
      category.setCategoryId(xlsxCategory.getCategoryId());
      category.setAngles(xlsxCategory.getAngles());
      return category;
    });
    const calcCategories = calcCategoryList(categories);
    dispatch(setCategoryList({ categoryList: calcCategories }));

    theoryTreeManager.getTheoryTrees().forEach((tree) => {
      calcTheoriesDegreeAndDepth(tree);
    });
    let theoryList = [];
    theoryTreeManager.getTheoryTrees().forEach((tree) => {
      const theories = getTheories(tree);
      console.log(theories);
      if (theories) {
        theoryList = [...theoryList, ...theories];
      }
    });

    const calcTheories = calculateScreenCoordByCircleCoord(
      theoryList,
      calcCategories
    );
    dispatch(setTheoryList({ theoryList: calcTheories }));
  }, [hasData]);

  useEffect(() => {
    initSetting();
  }, []);

  useEffect(() => {
    initSetting();
    console.log(categoryList, theoryList);
    createCanvas.drawCategories(categoryList);
    createCanvas.drawTheories(theoryList);
  }, [categoryList, theoryList]);

  const initSetting = () => {
    // 화면 지우고 다시 그리는 기본적인 함수
    createCanvas.removeCanvas();
    createCanvas.drawGraph(
      gradientColor,
      () => {},
      () => {},
      categoryList
    );
  };
  return (
    <>
      <TheoryGraph />
    </>
  );
}
