import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import styles from "../../../styles/CreateCase.module.css";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { useAxiosContext } from "../../../context/AxiosContainer";
import { useSelector } from "react-redux";

export default function CreateCaseCompleteModal({
  TemporaryStorage,
  modalOpen,
  setModalOpen,
}) {
  let navigate = useNavigate();

  const gotoOtherPage = async (url) => {
    await TemporaryStorage(true);
    await navigate(url);
  };

  return (
    <Modal
      show={modalOpen}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => setModalOpen(false)}
    >
      <Modal.Header closeButton className={styles.CloseBtn}></Modal.Header>
      <Modal.Body>
        <span>해당 케이스를 생성하겠습니까?</span>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setModalOpen(false)}>취소</Button>
        <Button
          onClick={async () => {
            await gotoOtherPage("/Admin");
            setModalOpen(false);
          }}
        >
          생성하기
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
