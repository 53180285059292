import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import styles from "../../styles/ResearcherTask.module.css";
import { useEffect, useState } from "react";
import { useAxiosContext } from "../../context/AxiosContainer";
import { useSelector } from "react-redux";

export default function DropdownCase({
  setCategoryChoose,
  researcherTF,
  createCase,
  leave,
  excelData,
  idx,
  categoryChoose,
}) {
  const { httpInterface } = useAxiosContext();
  const { accessToken } = useSelector((state) => state.loginInfo);

  const [dropdown, setDropdown] = useState(
    (leave && leave.category) || (createCase ? "선택" : "전체")
  );
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    httpInterface
      .getFilterValueData(accessToken)
      .then((res) => {
        //  console.log(res);
        setCategoryList(res.data.data[0].quizCategoryList);
      })
      .catch((error) => {
        console.log("error : ", error.response);
      });
  }, []);
  // console.log(leave);
  return (
    <Dropdown as={ButtonGroup} className={styles.Dropdown}>
      <Dropdown.Toggle
        disabled={researcherTF ? false : true}
        id="dropdown-split-basic"
        className={styles.Dropdown}
      >
        {leave?.category ? categoryChoose[idx].category : dropdown}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {categoryList.map((a, i) => {
          return (
            <>
              <Dropdown.Item
                className={styles.Dropdown}
                key={i}
                onClick={() => {
                  console.log(categoryChoose[idx]);
                  setDropdown(categoryList[i]);
                  leave?.[idx]?.category
                    ? (leave[idx].category = categoryList[i])
                    : (categoryChoose[idx].category = categoryList[i]);
                  setCategoryChoose(categoryChoose);
                }}
              >
                {categoryList[i]}
              </Dropdown.Item>
            </>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}
