import { TheoryCalculator } from './theoryCalculator';

export class Calculator extends TheoryCalculator {
  calcDegree(acc, end, total, ranges = [0, 100]) {
    // const dur = end * 0.5 + acc;
    const dur = end + acc;
    const proportion = dur / total;
    const range = ranges[1] - ranges[0];
    const degree = proportion * range + ranges[0];
    return degree;
  }

  calcDepth(depthNum) {
    switch (depthNum) {
      case 2:
        return 30;
      case 3:
        return 50;
      case 4:
        return 70;
      case 5:
        return 90;
      default:
        throw new Error('case must be in range(2,6)');
    }
  }

  calcCategoryAngles(cur, acc, total) {
    const startAngle = (acc / total) * 360;
    const endAngle = ((cur + acc) / total) * 360;
    return {
      startAngle,
      endAngle,
    };
  }
}

export class TheoryTreeManager {
  #theoryTrees = [];
  #theoryCalculator = new Calculator();

  addTheoryTree(theoryTree) {
    if (!(theoryTree instanceof TheoryTree)) {
      throw new Error('theoryTree must be typed by TheoryTree Class');
    }
    this.#theoryTrees = [...this.#theoryTrees, theoryTree];
  }

  getTheoryTree(idx) {
    if (idx > this.#theoryTrees.length - 1) {
      throw new Error('error');
    }
    return this.#theoryTrees[idx];
  }
  getTheoryTrees() {
    return this.#theoryTrees;
  }
  calculateCategoryProportion() {
    // const totalCategoryCount = this.#theoryTrees.length;
    const totalCategoryCount = this.#theoryTrees.reduce((acc, cur) => {
      // const category = cur.getCategory();
      acc += cur.getTotalCount();
      return acc;
    }, 0);

    let acc = 0;
    this.#theoryTrees.forEach((theoryTree) => {
      const category = theoryTree.getCategory();
      const cur = theoryTree.getTotalCount();
      const angles = this.#theoryCalculator.calcCategoryAngles(
        cur,
        acc,
        totalCategoryCount
      );
      console.log(cur, acc, totalCategoryCount, category);
      acc += cur;
      category.setAngles(angles);
    });
  }
}

export class TheoryTree {
  #category;
  #count;
  constructor(category) {
    this.#category = new XlsxCategory(category);
  }

  setCount(count) {
    this.#count = count + 1; // 1은 카테고리개수
  }
  getCount() {
    console.log(this.getTheoriesCount());
    return this.#count;
  }

  getCategory() {
    return this.#category;
  }

  addTheory(xlsxTheory) {
    const theory = new XlsxTheory(xlsxTheory);
    if (xlsxTheory.depth === 2) {
      this.#category.addChildren(theory);
      return;
    }
    const parent = this.findParent(xlsxTheory.rowNum, xlsxTheory.depth);
    if (!parent) {
      let virtualParent = this.makeVirtualParent(theory);
      virtualParent.addChildren(theory);
      while (!virtualParent.getParent()) {
        let temp = virtualParent;
        let link = this.findParent(temp.getRowNum(), temp.getDepthNum());
        if (link) {
          link.addChildren(virtualParent);
          virtualParent = link;
        } else {
          virtualParent = this.makeVirtualParent(virtualParent);
          virtualParent.addChildren(temp);
        }
      }
    } else {
      parent.addChildren(xlsxTheory);
    }
  }

  findParent(rowNum, depth) {
    const theories = this.#category.getChildren();
    const parent = this.bfs(theories, rowNum, depth);
    return parent;
  }

  makeVirtualParent(child) {
    const theory = new XlsxTheory();
    theory.setDepthNum(child.getDepthNum() - 1);
    theory.setRowNum(child.getRowNum());
    theory.setFakeNode();
    return theory;
  }

  bfs(list, targetRow, targetDepth) {
    if (!list.length) return;
    let results;
    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      const parentNext = list[i + 1];
      const parentRow = item.getRowNum();
      const parentDepth = item.getDepthNum();
      if (parentNext) {
        if (
          parentDepth + 1 === targetDepth &&
          parentRow < targetRow &&
          targetRow < parentNext.getRowNum()
        ) {
          results = item;
          break;
        } else if (parentDepth + 1 < targetDepth) {
          results = this.bfs(item.getChildren(), targetRow, targetDepth);
        }
      } else {
        if (parentDepth + 1 === targetDepth && parentRow < targetRow) {
          results = item;
          break;
        } else if (parentDepth + 1 < targetDepth) {
          results = this.bfs(item.getChildren(), targetRow, targetDepth);
        }
      }
    }

    return results;
  }

  getDepthTheoryLists(depth) {
    let result = this.#category;
    console.log(result, depth);
    for (let i = 1; i < depth; i++) {
      result = result?.getChildren();
      console.log(result, depth);
    }
    return result;
  }

  getTotalCount() {
    return this.getTheoriesCount() + 1;
  }

  getTheoriesCount() {
    let count = 0;

    const theories = this.#category.getChildren();
    theories.forEach((t) => {
      if (!t.isFakeTheory()) {
        count += 1;
      }
      count += this.countTheories(t?.getChildren());
    });

    return count;
  }

  countTheories(theories) {
    let count = 0;
    if (!theories) return 0;
    if (!theories.length) return 0;
    theories.forEach((t) => {
      if (!t.isFakeTheory()) {
        count += 1;
      }
      count += this.countTheories(t?.getChildren());
    });
    return count;
  }
}

export class XlsxTheory {
  #degree;
  #depth;
  #depthNum;
  #text = null;
  #categoryId; // 일단은 0~2
  #screenCoord;
  #rowNum = null;
  #parent = null;
  #children = [];
  #fake = false;
  #count = 0;
  #startDegree;
  #endDegree;

  constructor(theory) {
    this.#depthNum = theory?.depth || undefined;
    this.#rowNum = theory?.rowNum || null;
    this.#text = theory?.text || null;
    // console.log(theory);
    this.#categoryId = theory?.categoryId;
  }
  getScreenCoord() {
    return this.#screenCoord;
  }
  getCategoryId() {
    return this.#categoryId;
  }

  getCircleCoord() {
    return {
      degree: this.#degree,
      depth: this.#depth,
    };
  }

  setParent(parentTheory) {
    this.#parent = parentTheory;
  }

  getParent() {
    return this.#parent;
  }

  addChildren(childTheory) {
    const theory =
      childTheory instanceof XlsxTheory
        ? childTheory
        : new XlsxTheory(childTheory);
    theory.setParent(this);
    this.#children = [...this.#children, theory];
  }

  getChildren() {
    return this.#children;
  }

  setRowNum(rowNum) {
    this.#rowNum = rowNum;
  }
  getRowNum() {
    return this.#rowNum;
  }
  setDepthNum(depthNum) {
    this.#depthNum = depthNum;
  }
  getDepthNum() {
    return this.#depthNum;
  }

  setDepth(depth) {
    this.#depth = depth;
  }
  getDepth() {
    return this.#depth;
  }

  setDegree(degree) {
    this.#degree = degree;
  }
  getDegree() {
    return this.#degree;
  }

  setStartDegree(startDegree) {
    this.#startDegree = startDegree;
  }
  getStartDegree() {
    return this.#startDegree;
  }
  setEndDegree(endDegree) {
    this.#endDegree = endDegree;
  }
  getEndDegree() {
    return this.#endDegree;
  }
  setFakeNode() {
    this.#fake = true;
  }
  isFakeTheory() {
    return this.#fake;
  }

  getTotalCount() {
    let count = 1 + this.getChildrenCount();
    return count;
  }

  getChildrenCount() {
    return this.calcChildrenCount(this.getChildren());
  }

  calcChildrenCount(children) {
    if (!children.length) return 0;
    let count = 0;
    children.forEach((item) => {
      if (!item.isFakeTheory()) {
        count += 1;
      }
      // console.log(this.calcChildrenCount(item.getChildren()));
      count += this.calcChildrenCount(item.getChildren());
    });
    return count;
  }

  setScreenCoord(screenCoord) {
    this.#screenCoord = screenCoord;
  }

  getText() {
    return this.#text;
  }
}

export class XlsxCategory {
  #degree;
  #depth;
  #screenCoord; // 모니터 좌표
  #startAngle;
  #endAngle;
  #categoryId;
  #text;
  #children = [];
  #parent;

  constructor(category) {
    this.#categoryId = category.categoryId;
    this.#text = category.text;
  }

  addChildren(theory) {
    this.#children = [...this.#children, theory];
    theory.setParent(this);
  }

  getChildren() {
    return this.#children;
  }
  // Getter & Setter
  setCategoryId(categoryId) {
    this.#categoryId = categoryId;
  }
  getCategoryId() {
    return this.#categoryId;
  }

  getDepth() {
    return this.#depth;
  }

  setDegree(degree) {
    this.#degree = degree;
  }

  getDegree() {
    return this.#degree;
  }

  setText(text) {
    this.#text = text;
  }
  getText() {
    return this.#text;
  }

  setScreenCoord(screenCoord) {
    this.#screenCoord = screenCoord;
  }

  getScreenCoord() {
    return this.#screenCoord;
  }

  getCircleCoord() {
    return {
      degree: this.#degree,
      depth: this.#depth,
    };
  }

  getAngles() {
    return {
      startAngle: this.#startAngle,
      endAngle: this.#endAngle,
    };
  }

  setAngles(angles) {
    this.#startAngle = angles.startAngle;
    this.#endAngle = angles.endAngle;
  }
}
