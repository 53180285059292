import React from "react";
import styles from "../../../styles/CreateCase.module.css";

export default function Question2({
  idx,
  temporaryStorage,
  inputCopy,
  setInputText,
  createClick,
}) {
  return (
    <div className={styles.ChoiceTask}>
      <div className={styles.DivBox}>
        <span>질문 2*</span>
      </div>
      <div className={styles.Test}>
        <input
          className={styles.Answer}
          name="text"
          value={
            temporaryStorage?.[idx]?.subQuestionBodyList[1]
              ? temporaryStorage[idx].subQuestionBodyList[1]
              : inputCopy[idx].question2
          }
          autoComplete="off"
          onChange={(e) => {
            temporaryStorage?.[idx]?.subQuestionBodyList[1]
              ? (temporaryStorage[idx].subQuestionBodyList[1] = e.target.value)
              : (inputCopy[idx].question2 = e.target.value);

            setInputText(inputCopy);
          }}
        />
        {createClick && inputCopy[idx]?.question2.length === 0 && (
          <span style={{ color: "red" }}>필수 정보를 입력해주세요.</span>
        )}
      </div>
    </div>
  );
}
