import React, { useEffect } from "react";
import styles from "../../styles/UserRank.module.css";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { SlRefresh } from "react-icons/sl";
import Slider from "react-slick";
import { useLocation, useNavigate } from "react-router-dom";
import { useAxiosContext } from "../../context/AxiosContainer";
import { useDispatch, useSelector } from "react-redux";
import { signOut, updateAccessToken } from "../../redux/slice/login/loginInfo";
import { useMediaQuery } from "react-responsive";

export default function SubQuestionRank({ subQuestionRankList, idx }) {
  const isPc = useMediaQuery({
    query: "(min-width:768px)",
  });

  const sliderSetting = {
    arrows: false,
    dots: true,
    infinite: false,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: idx ? idx : 0, // 특정 하위퀴즈 누르면 해당 하위퀴즈의 결과 화면으로 바로 이동시키기 위해
    autoplay: false,
    centerMode: true,
    centerPadding: isPc ? "50px" : "40px",
  };

  let location = useLocation();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { httpInterface } = useAxiosContext();
  const { accessToken } = useSelector((state) => state.loginInfo);

  let today = new Date();

  let year = today.getFullYear();
  let month = today.getMonth() + 1;
  let date = today.getDate();

  const GoSolveTest = () => {
    httpInterface
      .getCurrentQuestion(accessToken)
      .then((res) => {
        console.log(res);
        httpInterface
          .getGotoStartTest(
            "/v2/api/caseps/" + res.data.data[0].subQuestionId,
            accessToken
          )
          .then((res) => {
            console.log(res);
            navigate("/StartTest", {
              state: {
                data: res.data.data[0],
                nextSubQuestionId: location.state.nextSubQuestionId,
              },
            });
          })
          .catch((error) => {
            const { status } = error.response;
            console.log("Test Component Error", error);
            if (status === 401) {
              // reissue Token 관련 코드 작성할 것
              httpInterface
                .reissueToken(accessToken)
                .then((response) => {
                  const accessToken = response.data.data[0].accessToken;
                  httpInterface.getGotoStartTest(
                    "/v2/api/caseps/" + res.data.data[0].subQuestionId,
                    accessToken
                  );
                  dispatch(
                    updateAccessToken({
                      accessToken,
                    })
                  );
                })
                .catch((error) => {
                  console.log("Refresh Token Error");
                  alert("유효정보가 만료되어 로그아웃되었습니다.");
                  dispatch(signOut());
                });
            } else {
              console.log("Validation Token Error");
              alert("유효정보가 만료되어 로그아웃되었습니다.");
              dispatch(signOut());
            }
          });
      })
      .catch((error) => {
        const { status } = error.response;
        console.log("Test Component Error", error);
        if (status === 401) {
          // reissue Token 관련 코드 작성할 것
          httpInterface
            .reissueToken(accessToken)
            .then((response) => {
              const accessToken = response.data.data[0].accessToken;
              httpInterface.getCurrentQuestion(accessToken);
              dispatch(
                updateAccessToken({
                  accessToken,
                })
              );
            })
            .catch((error) => {
              console.log("Refresh Token Error");
              alert("유효정보가 만료되어 로그아웃되었습니다.");
              dispatch(signOut());
            });
        } else {
          console.log("Validation Token Error");
          alert("유효정보가 만료되어 로그아웃되었습니다.");
          dispatch(signOut());
        }
      });
  };
  console.log(subQuestionRankList);

  useEffect(() => {}, []);
  return (
    <Slider {...sliderSetting} style={{ maxWidth: isPc ? "90%" : "100%" }}>
      {subQuestionRankList?.map((a, idx) => (
        <div key={idx} className={styles.CardWrap}>
          <Card
            style={{
              backgroundColor: "#ECECEC",
              marginRight: "2.5%",
              minHeight: "45vh",
              maxWidth: isPc ? "17rem" : "70vw",
              marginTop: "5%",
            }}
          >
            <Card.Body style={{ width: "80%" }}>
              <Card.Title className={styles.QuestionNum}>
                질문 {[idx + 1]}
              </Card.Title>
              {a !== null && (
                <span className={styles.SubQuestionTitle}>{a.body}</span>
              )}
              <div className={styles.RankBox}>
                <div className={styles.RankBoxContent}>
                  내 점수
                  <div style={{ marginTop: "6%", fontSize: "1.125rem" }}>
                    <span style={{ fontWeight: "bolder" }}>
                      {a !== null ? a.score : "?"}{" "}
                    </span>
                    점
                  </div>
                </div>
              </div>
              <span style={{ fontSize: "0.875rem" }}>
                {a !== null
                  ? `이 질문을 완료한 ${a.totalPeople}명 중`
                  : "나의 순위는 ??? 명 중 ? 위"}
              </span>
              <br />
              {a !== null && (
                <span style={{ fontWeight: "bolder", fontSize: "1.125rem" }}>
                  {a.rank}위 입니다.
                </span>
              )}

              {a !== null ? (
                <div>
                  <span style={{ fontSize: "0.625rem" }}>
                    {" "}
                    {`*${year}년 ${month}월 ${date}일 기준`}
                  </span>
                  <div style={{ margin: "4% 0" }}>
                    <SlRefresh className={styles.RefreshIcon} />
                  </div>
                </div>
              ) : (
                <>
                  <div
                    style={{
                      marginTop: "30%",
                      color: "#3D5398",
                      fontSize: "0.75rem",
                    }}
                  >
                    {subQuestionRankList[idx - 1] !== null ? (
                      <>
                        <span>
                          문제를 풀어야
                          <br />
                          나의 순위를 알 수 있어요!
                        </span>
                      </>
                    ) : (
                      <>
                        <span>
                          아직 풀 수 없어요!
                          <br />
                          순서대로 문제를 풀어볼까요?
                        </span>
                      </>
                    )}
                  </div>
                  <Button
                    className={styles.StartQuestionBtn}
                    onClick={() => GoSolveTest()}
                  >
                    문제 풀러 가기
                  </Button>
                </>
              )}
            </Card.Body>
          </Card>
        </div>
      ))}
    </Slider>
  );
}
