import React, { forwardRef } from "react";
import styles from "./ContextQuestion.module.css";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Timer from "./Timer";

const UpperClassQuestion = forwardRef((_, ref) => {
  let location = useLocation();

  return (
    <div>
      <span>다음 상황에 상상하고 아래 질문에 답하세요.</span>
      <Timer ref={ref} />
      <p
        style={{
          padding: "2%",
          marginTop: "2%",
          backgroundColor: "rgba(217, 217, 217, 0.5)",
          borderRadius: "8px",
        }}
      >
        {location.state ? location.state.data.quizBody : ""}
      </p>
    </div>
  );
});

export default UpperClassQuestion;
