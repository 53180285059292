export const theoryMode = {
  createCategory: 'createCategory',
  createTheory: 'createTheory',
  // modifyCategory: 'modifyCategory',
  // modifyTheory: 'modifyTheory',
  modify: 'modify',
  move: 'move',
  delete: 'delete',
  // deleteCategory: 'deleteCategory',
  // deleteTheory: 'deleteTheory',
};
