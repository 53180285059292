import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styles from "../styles/CreateCase.module.css";
import Button from "react-bootstrap/Button";
import { useAxiosContext } from "../context/AxiosContainer";
import { AiOutlinePlus } from "react-icons/ai";
import { useSelector } from "react-redux";
import Question3 from "../components/adminMain/createCase/Question3";
import Question4 from "../components/adminMain/createCase/Question4";
import Question2 from "../components/adminMain/createCase/Question2";
import Question1 from "../components/adminMain/createCase/Question1";
import Case from "../components/adminMain/createCase/Case";
import CaseName from "../components/adminMain/createCase/CaseName";
import Language from "../components/adminMain/createCase/Language";
import Level from "../components/adminMain/createCase/Level";
import Category from "../components/adminMain/createCase/Category";
import CreateCaseCompleteModal from "../components/adminMain/createCase/CreateCaseCompleteModal";
import RegisterExcel from "../components/adminMain/createCase/RegisterExcel";
/*
TO DO
2. 추가 생성 버튼 처리
 */
export default function CreateCaseAdmin() {
  const { httpInterface } = useAxiosContext();
  const { accessToken } = useSelector((state) => state.loginInfo);

  let location = useLocation();
  console.log(location.state);
  let temporaryStorage = location.state?.leave; // 임시저장데이터

  const [excelData, setExcelData] = useState([]);
  const [createClick, setCreateClick] = useState(false);
  const [inputText, setInputText] = useState();

  useEffect(() => {
    setInputText(
      temporaryStorage
        ? temporaryStorage.map((a, i) => {
            return {
              category: a.category,
              level: a.level,
              language: a.language,
              caseName: a.caseName,
              case: a.quizBody,
              question1: a.subQuestionBodyList[0],
              question2: a.subQuestionBodyList[1],
              question3: a.subQuestionBodyList[2],
              question4: a.subQuestionBodyList[3],
            };
          })
        : excelData.length > 0
        ? excelData.map((a, i) => {
            console.log(a);
            return {
              caseName: a.케이스명,
              case: a.하위유형,
              question1: a.질문1,
              question2: a.질문2,
              question3: a.질문3,
              question4: a.질문4,
            };
          })
        : [
            {
              category: "",
              level: "",
              language: "KOREAN",
              caseName: "",
              case: "",
              question1: "",
              question2: "",
              question3: "",
              question4: "",
            },
          ]
    );

    console.log(inputText);
  }, [excelData]);

  const [quizId, setQuizId] = useState(
    temporaryStorage
      ? temporaryStorage.map((a, i) => {
          return {
            quizId: a.quizId,
          };
        })
      : [
          {
            quizId: "",
          },
        ]
  );

  useEffect(() => {
    if (!temporaryStorage || (temporaryStorage && inputText?.length > 1)) {
      console.log("퀴즈 아이디 생성", inputText?.length);
      httpInterface
        .postFirstViewCreateQuizId(accessToken)
        .then((res) => {
          let quizIdCopy = [...quizId];

          quizIdCopy[quizId[0].quizId.length !== 0 ? quizIdCopy.length : 0] = {
            quizId: res.data.data[0],
          };

          setQuizId(quizIdCopy);
          console.log(quizIdCopy);
        })
        .catch((error) => {
          console.log("error : ", error.response);
        });
    }
  }, [inputText?.length]);

  useEffect(() => {
    console.log(inputText);
    inputText?.map((a, i) =>
      a.caseName?.length !== 0 &&
      a.case?.length !== 0 &&
      a.question1?.length !== 0 &&
      a.question2?.length !== 0
        ? setAddBtnOn(true)
        : setAddBtnOn(false)
    );
  }, [inputText]);

  const [modalOpen, setModalOpen] = useState(false);

  // 추가생성 버튼 활성화 여부
  const [addBtnOn, setAddBtnOn] = useState(false);

  let inputCopy = inputText && [...inputText];

  const AddCase = () => {
    setInputText([
      ...inputText,

      {
        category: "",
        level: "",
        language: "KOREAN",
        caseName: "",
        case: "",
        question1: "",
        question2: "",
        question3: "",
        question4: "",
      },
    ]);

    setCreateClick(false);
  };

  // 임시저장시
  const TemporaryStorage = async (complete) => {
    console.log(temporaryStorage, complete);

    // 임시저장한 것을 또 임시저장할 때 기존 임시저장 데이터도 함께 저장하기 위해
    await httpInterface
      .postCreateCase(
        accessToken,
        inputText?.map((a, i) => {
          return {
            quizId: quizId[i].quizId,
            caseName: inputText[i].caseName,
            quizBody: inputText[i]?.case,
            level: inputText[i].level ? inputText[i].level : null,
            category: inputText[i].category ? inputText[i].category : null,
            language: inputText[i].language,
            subQuestionBodyList: [
              inputText[i]?.question1?.length > 0
                ? inputText[i]?.question1
                : "",
              inputText[i]?.question2?.length > 0
                ? inputText[i]?.question2
                : "",
              inputText[i]?.question3?.length > 0
                ? inputText[i]?.question3
                : "",
              inputText[i]?.question4?.length > 0
                ? inputText[i]?.question4
                : "",
            ].filter((list) => list.length > 0),
          };
        })
      )
      .then((res) => {
        console.log(res);
        alert("임시저장 완료되었습니다.");
        complete &&
          httpInterface
            .postCreateComplete(accessToken)
            .then((res) => {
              console.log(res);
            })
            .catch((error) => {
              console.log("error : ", error.response);
            });
      })
      .catch((error) => {
        console.log("error : ", error.response);
      });
  };

  return (
    <>
      <div className={styles.Container}>
        <span className={styles.Title}>케이스 생성</span>
        <div className={styles.TaskWrap}>
          <RegisterExcel setExcelData={setExcelData} />
          <div>
            {inputText?.map((a, i) => {
              return (
                <>
                  <div className={styles.Task}>
                    <div className={styles.DivBox}>
                      <span style={{ fontWeight: "bolder" }}>
                        케이스 {i + 1}
                      </span>
                    </div>
                  </div>
                  <Category
                    idx={i}
                    setCategoryChoose={setInputText}
                    categoryChoose={inputCopy}
                    temporaryStorage={temporaryStorage}
                    excelData={excelData.length > 0 && excelData}
                    createClick={createClick}
                  />
                  <Level
                    idx={i}
                    setLevelChoose={setInputText}
                    temporaryStorage={temporaryStorage}
                    excelData={excelData.length > 0 && excelData}
                    createClick={createClick}
                    levelChoose={inputCopy}
                  />
                  <Language
                    idx={i}
                    setLanguageChoose={setInputText}
                    temporaryStorage={temporaryStorage}
                    excelData={excelData.length > 0 && excelData}
                    languageChoose={inputCopy}
                  />
                  <CaseName
                    idx={i}
                    temporaryStorage={temporaryStorage}
                    inputCopy={inputCopy}
                    setInputText={setInputText}
                    createClick={createClick}
                    excelData={excelData.length > 0 && excelData}
                  />
                  <Case
                    idx={i}
                    temporaryStorage={temporaryStorage}
                    inputCopy={inputCopy}
                    setInputText={setInputText}
                    createClick={createClick}
                  />
                  <Question1
                    idx={i}
                    temporaryStorage={temporaryStorage}
                    inputCopy={inputCopy}
                    setInputText={setInputText}
                    createClick={createClick}
                  />
                  <Question2
                    idx={i}
                    temporaryStorage={temporaryStorage}
                    inputCopy={inputCopy}
                    setInputText={setInputText}
                    createClick={createClick}
                  />
                  <Question3
                    idx={i}
                    temporaryStorage={temporaryStorage}
                    inputCopy={inputCopy}
                    setInputText={setInputText}
                  />
                  <Question4
                    idx={i}
                    temporaryStorage={temporaryStorage}
                    inputCopy={inputCopy}
                    setInputText={setInputText}
                  />
                </>
              );
            })}
            <div className={styles.AddBtn}>
              <Button
                className={styles.PlusBtn}
                disabled={addBtnOn ? false : true}
                onClick={() => {
                  AddCase();
                }}
              >
                <AiOutlinePlus size={30} color="#878787" />
              </Button>
              <span style={{ fontWeight: "bold" }}>추가 생성</span>
            </div>
            <div className={styles.FinishBtn}>
              <Button
                onClick={() => {
                  TemporaryStorage();
                }}
              >
                임시저장
              </Button>
              <Button
                onClick={() => {
                  setCreateClick(true);
                  console.log(addBtnOn);
                  addBtnOn && setModalOpen(true);
                }}
              >
                생성하기
              </Button>
            </div>
          </div>

          <CreateCaseCompleteModal
            TemporaryStorage={TemporaryStorage}
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
          />
        </div>
      </div>
    </>
  );
}
