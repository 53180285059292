import { useMediaQuery } from "react-responsive";
import WelcomeText from "../components/mModule/mainPage/WelcomeText";
import "../language/i18n";
import styles from "../styles/Main.module.css";
import { useAxiosContext } from "../context/AxiosContainer";
import { useDispatch, useSelector } from "react-redux";
import TestInfoContainer from "../components/mModule/mainPage/TestInfoContainer";
import { signOut, updateAccessToken } from "../redux/slice/login/loginInfo";
import { useEffect } from "react";
import { closeModal } from "../redux/slice/modal/modal";
import { useState } from "react";

export default function Main() {
  //console.log("main");
  const isPc = useMediaQuery({
    query: "(min-width:768px)",
  });
  const { httpInterface } = useAxiosContext();
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state) => state.loginInfo);

  const [caseList, setCaseList] = useState([]);

  useEffect(() => {
    httpInterface
      .getCaseView(accessToken)
      .then((res) => {
        console.log(res);
        setCaseList(res.data.data);
      })
      .catch((error) => {
        const { status } = error.response;
        console.log("Test Component Error", error);
        if (status === 401) {
          // reissue Token 관련 코드 작성할 것
          httpInterface
            .reissueToken(accessToken)
            .then((response) => {
              const accessToken = response.data.data[0].accessToken;
              httpInterface.getCaseView(accessToken);
              dispatch(
                updateAccessToken({
                  accessToken,
                })
              );
            })
            .catch((error) => {
              console.log("Refresh Token Error");
              alert("유효정보가 만료되어 로그아웃되었습니다.");
              dispatch(signOut());
            });
        } else {
          console.log("Validation Token Error");
          alert("유효정보가 만료되어 로그아웃되었습니다.");
          dispatch(signOut());
        }
      });
    return () => {
      dispatch(closeModal());
    };
  }, []);

  return (
    <>
      <div className={styles.Container}>
        <WelcomeText isPc={isPc} />
        <TestInfoContainer caseList={caseList} isPc={isPc} />
      </div>
    </>
  );
}
