import axios from "axios";
export default class Http {
  constructor() {
    const axiosConfigure = {
      // baseURL: process.env.API_SERVER_URL,
      //baseURL: "https://rtw.co.kr",
      baseURL: "https://dg2xs86sayn3p.cloudfront.net",
      // timeout: 1000,
      withCredentials: true,
    };

    this.httpClient = axios.create(axiosConfigure);
  }

  // getAccessToken() {
  //   const accessToken = `Bearer ${localStorage.getItem('userToken')}`;

  //   return {
  //     headers: {
  //       Authorization: accessToken,
  //     },
  //   };
  // }

  // Refresh Token 정책이 쿠키로 바뀜에 따라 사용안할 것 같습니다...
  // getTokenForRefresh() {
  //   const accessToken = `Bearer ${localStorage.getItem('userToken')}`;
  //   const refreshToken = `Bearer ${localStorage.getItem('refreshToken')}`;
  //   const headerOptions = {
  //     headers: {
  //       'Access-Token': accessToken,
  //       'Refresh-Token': refreshToken,
  //     },
  //   };
  //   return headerOptions;
  // }

  // async validateAccessToken(url, params, method = 'GET') {
  //   switch (method) {
  //     case 'GET':
  //       return this.httpClient.get(url, {
  //         ...params,
  //         ...this.getAccessToken(),
  //       });
  //     default: // 나중에 바꿀 예정
  //       return this.httpClient.get(url, {
  //         ...params,
  //         ...this.getAccessToken(),
  //       });
  //   }
  // }

  // async refreshValidation() {
  //   return this.httpClient
  //     .post('/v2/api/user/refresh', null, {
  //       ...this.getTokenForRefresh(),
  //     })
  //     .then((res) => {
  //       localStorage.setItem('userToken', res.data.data[0].accessToken);
  //       localStorage.setItem('refreshToken', res.data.data[0].refreshToken);
  //     })
  //     .catch((err) => {
  //       console.log('Refresh-Validation Error', err);
  //     });
  // }

  async getWithAccessToken(url, accessToken, params) {
    return this.httpClient.get(url, {
      ...params,
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    // .catch((error) => console.error('get error', error));
  }

  async post(url, params) {
    return this.httpClient.post(url, params);
    // .then((res) => {
    //   return res;
    // })
    // .catch((err) => {
    //   console.log(err);
    // });
  }

  async postWithAccessToken(url, accessToken, data, config) {
    return this.httpClient.post(url, data, {
      headers: { Authorization: `Bearer ${accessToken}` },
      ...config,
    });
    // .catch((error) => {
    //   console.log(error);
    // });
  }

  async putWithAccessToken(url, accessToken, data, config) {
    return this.httpClient
      .put(url, data, {
        headers: { Authorization: `Bearer ${accessToken}` },
        ...config,
      })
      .catch((error) => {
        console.log("http", error);
      });
  }

  async deleteWithAccessToken(url, accessToken, config) {
    return this.httpClient.delete(url, {
      headers: { Authorization: `Bearer ${accessToken}` },
      ...config,
    });
    // .catch((error) => {
    //   console.log(error);
    // });
  }
  //
  async modifyWithAccessToken(url, modifyData, deleteData) {
    return this.httpClient
      .all([
        this.apiClient.put(url, modifyData, {
          ...this.getAccessToken(),
        }),
        this.apiClient.delete(url, deleteData, {
          ...this.getAccessToken(),
        }),
      ])
      .then(
        axios.spread((res1, res2) => {
          return { res1, res2 };
        })
      );
    // .catch((error) => console.error(error));
  }
}
