import { isEmptyObj } from '../common/common';
import * as d3 from '../js/d3';
import { Category, DBCategory } from '../theory/category';
import { DBTheory, Theory } from '../theory/theory';
import { XlsxCategory, XlsxTheory } from '../theory/theoryTree';
// import { canvasOptions, roundOptions } from './canvasSize';

/* const { height: canvasHeight, width: canvasWidth } = canvasOptions;
const {
  center: { x: centerX, y: centerY },
  radius: { big: bigR, small: smallR },
} = roundOptions;
 */

export class RealTimeCanvas {
  #g;
  #height = window.innerHeight * 0.5;
  #width = window.innerWidth;
  #outerRadius = this.#height / 2;
  #arcDepth = 5;
  #radiusUnit = this.#outerRadius / this.#arcDepth;

  #setGroup() {
    this.#g = d3
      .select('g svg')
      .append('g')
      .attr('transform', `translate(${this.#width / 2}, ${this.#height / 2})`);
  }
  #getGroup() {
    return this.#g;
  }

  getOuterRadius() {
    return this.#outerRadius;
  }

  getArcDepth() {
    return this.#arcDepth;
  }

  getRadiusUnit() {
    return this.#radiusUnit;
  }

  getHeight() {
    return this.#height;
  }

  removeCanvas() {
    d3.select('g svg').selectAll('g').remove();
    d3.select('g svg').selectAll('circle').remove();
    d3.select('g svg').selectAll('text').remove();
  }

  #drawArc(radius, color, isCategory) {
    // const height = window.innerHeight * 0.4;
    this.#setGroup();
    const g = this.#getGroup();
    const pie = d3.pie();
    const arc = d3.arc().innerRadius(0).outerRadius(radius);
    const arcs = g
      .selectAll('arc')
      .data(pie([1])) // pie chart 만들기
      .enter()
      .append('g')
      .attr('class', `arc${isCategory ? 'Category' : ''}`) // (가장 안쪽원은 카테고리 영역을 나누기 위해 클래스명 추가)
      .style('z-index', 10)
      .attr('stroke', '#aaaaaa');
    // .attr('stroke', 'gray');
    arcs
      .append('path')
      .attr('fill', (d, i) => color([i]))
      // .attr('fill', (d, i) => color[i])
      .attr('d', arc);
  }

  drawTheoryGraph(color) {
    let outerCircleRadius = this.#height / 2;
    let smallR = outerCircleRadius / this.#arcDepth;
    let currentRadius = outerCircleRadius;
    for (let i = 0; i < this.#arcDepth; i++) {
      const isCategoryArc = i === this.#arcDepth - 1;
      this.#drawArc(currentRadius, color[i], isCategoryArc);
      currentRadius -= smallR;
    }
  }

  drawGravityCenter(screenCoord, categoryId) {
    d3.selectAll('g.arcCategory')
      .append('circle')
      .attr('id', 'click_spot_' + categoryId)
      .attr('cx', screenCoord.x)
      .attr('cy', screenCoord.y)
      .attr('r', 3)
      .style('stroke', 'black')
      .style('fill', 'black')
      .style('z-index', 10);
    this.drawText(
      categoryId,
      `(${screenCoord.x}, ${screenCoord.y})`,
      screenCoord
    );
  }

  isExistTheoryManager(theoryManager) {
    if (!theoryManager) return false;
    if (theoryManager && isEmptyObj(theoryManager)) return false;
    return true;
  }

  drawTheories(theoryManager) {
    if (!this.isExistTheoryManager(theoryManager)) return;

    const theoryList = theoryManager?.getTheoryList();
    if (!theoryList.length) return;

    for (const theory of theoryList) {
      this.drawText(
        theory.getCategoryId(),
        theory.getText(),
        theory.getScreenCoord()
      );
      this.drawDot(theory.getCategoryId(), theory.getScreenCoord());
    }
  }
  drawText(categoryId, text, coord) {
    d3.selectAll('g.arcCategory')
      .append('text')
      .text(text)
      .attr('id', 'click_spot_' + categoryId)
      .attr('x', coord.x + 10)
      .attr('y', coord.y + 5)
      .attr('font-family', 'sans-serif')
      .attr('font-size', '11px')
      .attr('text-anchor', 'start')
      .style('stroke', 'none')
      .style('fill', 'black')
      .style('font-weight', '900')
      .style('z-index', 10);
  }

  drawDot(categoryId, coord) {
    d3.selectAll('g.arcCategory')
      .append('circle')
      .attr('id', 'click_spot_' + categoryId)
      .attr('cx', coord.x)
      .attr('cy', coord.y)
      .attr('r', 3)
      .style('stroke', 'red')
      .style('fill', 'red')
      .style('z-index', 10);
  }
}

export class CreateTheoryCanvas {
  #g;
  #width = window.innerWidth * 0.7;
  // #height = this.#width;
  #height = window.innerHeight * 0.9;
  // #outerRadius = this.#height / 2;
  // #outerRadius = this.#width / 2;
  #outerRadius =
    this.#width > this.#height ? this.#height / 2 : this.#width / 2;
  #arcDepth = 5;
  #radiusUnit = this.#outerRadius / this.#arcDepth;
  #theoryManager;

  setTheoryManager(theoryManager) {
    this.#theoryManager = theoryManager;
  }

  setGroup() {
    this.#g = d3
      .select('g svg')
      .append('g')
      .attr('transform', `translate(${this.#width / 2}, ${this.#height / 2})`);
  }
  getGroup() {
    return this.#g;
  }

  getOuterRadius() {
    return this.#outerRadius;
  }

  getArcDepth() {
    return this.#arcDepth;
  }

  getRadiusUnit() {
    return this.#radiusUnit;
  }

  getHeight() {
    return this.#height;
  }

  removeCanvas() {
    d3.select('g svg').selectAll('g').remove();
    d3.select('g svg').selectAll('circle').remove();
    d3.select('g svg').selectAll('text').remove();
  }

  drawGraph(
    color,
    categoryAreaClickHandler,
    theoryAreaClickHandler,
    categoryList
  ) {
    // let outerCircleRadius = this.#height / 2;
    let outerCircleRadius = this.#outerRadius;
    let smallR = outerCircleRadius / this.#arcDepth;
    let currentRadius = outerCircleRadius;
    for (let i = 0; i < this.#arcDepth; i++) {
      const isCategoryArc = i === this.#arcDepth - 1;
      this.#drawArc(currentRadius, color[i], isCategoryArc, categoryList);
      currentRadius -= smallR;
    }
    d3.selectAll('g.arcCategory').on('click', categoryAreaClickHandler);
    theoryAreaClickHandler &&
      d3.selectAll('g.arc').on('click', theoryAreaClickHandler);
  }

  #drawArc(radius, color, isCategory, categoryList) {
    this.setGroup();
    const g = this.getGroup();

    // const areaPortion =
    //   categoryList?.length > 0 ? categoryList.map((_) => 1) : [1];
    // const pie = d3.pie();

    const areaPortion =
      categoryList?.length > 0
        ? // ? categoryList.map((item) => {
          //     const { startAngle, endAngle } = item.getAngles();
          //     return endAngle - startAngle;
          //   })
          categoryList.map(() => 1)
        : [1];

    const unit = Math.PI / 180;
    // const pie = d3.pie()([30, 40, 10, 60]);
    const pie = d3.pie()(areaPortion);

    if (categoryList?.length > 0) {
      pie.forEach((data, idx) => {
        const { startAngle, endAngle } = categoryList[idx].getAngles();
        data.startAngle = startAngle * unit;
        data.endAngle = endAngle * unit;
      });
    }

    const arc = d3.arc().innerRadius(0).outerRadius(radius);
    const arcs = g
      .selectAll('arc')
      .data(pie) // pie chart 만들기
      .enter()
      .append('g')
      .attr('class', `arc${isCategory ? 'Category' : ''}`) // (가장 안쪽원은 카테고리 영역을 나누기 위해 클래스명 추가)
      .style('z-index', 10)
      .attr('stroke', 'gray');
    arcs
      .append('path')
      .attr('fill', (d, i) => color[i])
      // .attr('fill', (d, i) => color[i])
      .attr('d', arc);
  }

  drawCategories(categoryList) {
    if (!categoryList) return;
    if (categoryList?.length <= 0) return;
    if (!(categoryList[0] instanceof Category)) {
      return;
    }
    for (const category of categoryList) {
      const screenCoord = category.getScreenCoord();
      const categoryId = category.getCategoryId();
      const text = category.getText();
      this.drawText(categoryId, text, screenCoord);
      this.drawDot(categoryId, screenCoord);
    }
  }

  drawTheories(theoryList, currentTarget = null) {
    if (!theoryList) return;
    if (theoryList.length <= 0) return;
    if (!(theoryList[0] instanceof Theory)) {
      return;
    }

    for (const theory of theoryList) {
      const screenCoord = theory.getScreenCoord();
      // const categoryId = theory.getCategoryId();
      const text = theory.getText();
      const theoryId = theory.getId();
      this.drawText(theoryId, text, screenCoord, currentTarget);
      this.drawDot(theoryId, screenCoord, currentTarget);
    }
  }

  drawText(theoryId, text, coord, currentTarget) {
    const defaultStyle = {
      color: 'gray',
      fontSize: '10px',
      fontWeight: '500',
      zIndex: 10,
    };

    let color = defaultStyle.color;
    let fontSize = defaultStyle.fontSize;
    let fontWeight = defaultStyle.fontWeight;
    let zIndex = defaultStyle.zIndex;
    if (
      currentTarget &&
      currentTarget.theory &&
      currentTarget.theory.getId() === theoryId
    ) {
      color = 'black';
      fontSize = '12px';
      fontWeight = '900';
      zIndex = 15;
    }

    d3.selectAll('g.arcCategory')
      .append('text')
      .text(text)
      .attr('id', 'click_spot_text_' + theoryId)
      .attr('x', coord.x + 10)
      .attr('y', coord.y - 3)
      .attr('font-family', 'sans-serif')
      .style('font-size', fontSize)
      .attr('text-anchor', 'start')
      .style('stroke', 'none')
      .style('fill', color)
      .style('font-weight', fontWeight)
      .style('z-index', zIndex);
  }

  // generateRandomValue(number) {
  //   let randomNum = Math.random() * number - number / 2;
  //   return randomNum;
  // }

  drawDot(theoryId, coord, currentTarget) {
    const defaultStyle = {
      color: 'red',
      radius: 3,
      zIndex: 10,
    };

    let color = defaultStyle.color;
    let radius = defaultStyle.radius;
    let zIndex = defaultStyle.zIndex;

    if (
      currentTarget &&
      currentTarget.theory &&
      currentTarget.theory.getId() === theoryId
    ) {
      color = 'blue';
      radius = 6;
      zIndex = 15;
    }
    d3.selectAll('g.arcCategory')
      .append('circle')
      .attr('id', 'click_spot_dot_' + theoryId)
      .attr('cx', coord.x)
      .attr('cy', coord.y)
      .attr('r', radius)
      // .style('stroke', 'red')
      .style('fill', color)
      .style('z-index', zIndex)
      .on('mouseover', function (d) {
        d3.select(this)
          .transition()
          .duration(200)
          .attr('r', radius * 1.1)
          .style('fill', 'yellow');
        // d3.select(`#click_spot_text_${theoryId}`)
        //   .transition()
        //   .duration(200)
        //   .style('z-index', 14)
        //   .style('fill', 'black')
        //   .style('font-weight', '800')
        //   .style('font-size', '10px');
      })
      .on('mouseout', function (d) {
        d3.select(this)
          .transition()
          .duration(200)
          .attr('r', radius)
          .style('fill', color);
        // d3.select(`#click_spot_text_${theoryId}`)
        //   .transition()
        //   .duration(200)
        //   .style('z-index', 10)
        //   .style('fill', 'gray')
        //   .style('font-weight', '500')
        //   .style('font-size', '8px');
      });
  }
}

export class XlsxTheoryCanvas extends CreateTheoryCanvas {
  #g;
  #width = window.innerWidth * 0.7;
  #height = window.innerHeight * 0.9;
  #outerRadius =
    this.#width > this.#height ? this.#height / 2 : this.#width / 2;
  #arcDepth = 5;
  #radiusUnit = this.#outerRadius / this.#arcDepth;
  drawGraph(
    color,
    categoryAreaClickHandler,
    theoryAreaClickHandler,
    categoryList
  ) {
    let outerCircleRadius = this.#outerRadius;
    let smallR = outerCircleRadius / this.#arcDepth;
    let currentRadius = outerCircleRadius;
    for (let i = 0; i < this.#arcDepth; i++) {
      const isCategoryArc = i === this.#arcDepth - 1;
      this.#drawArc(currentRadius, color[i], isCategoryArc, categoryList);
      currentRadius -= smallR;
    }
    d3.selectAll('g.arcCategory').on('click', categoryAreaClickHandler);
    theoryAreaClickHandler &&
      d3.selectAll('g.arc').on('click', theoryAreaClickHandler);
  }
  #drawArc(radius, color, isCategory, categoryList) {
    this.setGroup();
    const g = this.getGroup();
    if (!categoryList) return;
    if (!(categoryList[0] instanceof Category)) {
      categoryList = [];
    }

    const areaPortion =
      categoryList?.length > 0
        ? // ? categoryList.map((item) => {
          //     const { startAngle, endAngle } = item.getAngles();
          //     return endAngle - startAngle;
          //   })
          categoryList.map(() => 1)
        : [1];

    const unit = Math.PI / 180;
    // const pie = d3.pie()([30, 40, 10, 60]);
    const pie = d3.pie()(areaPortion);

    if (categoryList?.length > 0) {
      pie.forEach((data, idx) => {
        const { startAngle, endAngle } = categoryList[idx].getAngles();
        data.startAngle = startAngle * unit;
        data.endAngle = endAngle * unit;
      });
    }

    const arc = d3.arc().innerRadius(0).outerRadius(radius);
    const arcs = g
      .selectAll('arc')
      // .data(pie(areaPortion)) // pie chart 만들기
      .data(pie)
      .enter()
      .append('g')
      .attr('class', `arc${isCategory ? 'Category' : ''}`) // (가장 안쪽원은 카테고리 영역을 나누기 위해 클래스명 추가)
      .style('z-index', 10)
      .attr('stroke', 'gray');
    arcs
      .append('path')
      .attr('fill', (d, i) => color([i]))
      // .attr('fill', (d, i) => colors[i])
      .attr('d', arc);
  }
  drawTheories(theoryList) {
    if (!theoryList) return;
    if (theoryList.length <= 0) return;
    if (!(theoryList[0] instanceof XlsxTheory)) {
      return;
    }

    for (const theory of theoryList) {
      const screenCoord = theory.getScreenCoord();
      const categoryId = theory.getCategoryId();
      const text = theory.getText();
      this.drawText(categoryId, text, screenCoord);
      this.drawDot(categoryId, screenCoord);
    }
  }
}

export class ManageTheoryCanvas {
  #g;
  #width = window.innerWidth * 0.7;
  // #height = this.#width;
  #height = window.innerHeight * 0.9;
  // #outerRadius = this.#height / 2;
  // #outerRadius = this.#width / 2;
  #outerRadius =
    this.#width > this.#height ? this.#height / 2 : this.#width / 2;
  #arcDepth = 5;
  #radiusUnit = this.#outerRadius / this.#arcDepth;
  #theoryManager;

  setTheoryManager(theoryManager) {
    this.#theoryManager = theoryManager;
  }

  setGroup() {
    this.#g = d3
      .select('g svg')
      .append('g')
      .attr('transform', `translate(${this.#width / 2}, ${this.#height / 2})`);
  }
  getGroup() {
    return this.#g;
  }

  getOuterRadius() {
    return this.#outerRadius;
  }

  getArcDepth() {
    return this.#arcDepth;
  }

  getRadiusUnit() {
    return this.#radiusUnit;
  }

  getHeight() {
    return this.#height;
  }

  removeCanvas() {
    d3.select('g svg').selectAll('g').remove();
    d3.select('g svg').selectAll('circle').remove();
    d3.select('g svg').selectAll('text').remove();
  }

  drawGraph(
    color,
    // categoryAreaClickHandler,
    theoryAreaClickHandler,
    categoryList
  ) {
    // let outerCircleRadius = this.#height / 2;
    let outerCircleRadius = this.#outerRadius;
    let smallR = outerCircleRadius / this.#arcDepth;
    let currentRadius = outerCircleRadius;
    for (let i = 0; i < this.#arcDepth; i++) {
      const isCategoryArc = i === this.#arcDepth - 1;
      this.#drawArc(currentRadius, color[i], isCategoryArc, categoryList);
      currentRadius -= smallR;
    }
    // d3.selectAll('g.arcCategory').on('click', categoryAreaClickHandler);
    theoryAreaClickHandler &&
      d3.selectAll('g.arc').on('click', theoryAreaClickHandler);
  }

  #drawArc(radius, color, isCategory, categoryList) {
    this.setGroup();
    const g = this.getGroup();

    // const areaPortion =
    //   categoryList?.length > 0 ? categoryList.map((_) => 1) : [1];
    // const pie = d3.pie();

    const areaPortion =
      categoryList?.length > 0
        ? // ? categoryList.map((item) => {
          //     const { startAngle, endAngle } = item.getAngles();
          //     return endAngle - startAngle;
          //   })
          categoryList.map(() => 1)
        : [1];

    const unit = Math.PI / 180;
    // const pie = d3.pie()([30, 40, 10, 60]);
    const pie = d3.pie()(areaPortion);

    if (categoryList?.length > 0) {
      pie.forEach((data, idx) => {
        const { startAngle, endAngle } = categoryList[idx].getAngles();
        data.startAngle = startAngle * unit;
        data.endAngle = endAngle * unit;
      });
    }

    const arc = d3.arc().innerRadius(0).outerRadius(radius);
    const arcs = g
      .selectAll('arc')
      .data(pie) // pie chart 만들기
      .enter()
      .append('g')
      .attr('class', `arc${isCategory ? 'Category' : ''}`) // (가장 안쪽원은 카테고리 영역을 나누기 위해 클래스명 추가)
      .style('z-index', 10)
      .attr('stroke', 'gray');
    arcs
      .append('path')
      .attr('fill', (d, i) => color[i])
      // .attr('fill', (d, i) => color[i])
      .attr('d', arc);
  }

  drawCategories(categoryList) {
    if (!categoryList) return;
    if (categoryList?.length <= 0) return;
    if (!(categoryList[0] instanceof DBCategory)) {
      return;
    }
    for (const category of categoryList) {
      const screenCoord = category.getScreenCoord();
      const categoryId = category.getCategoryId();
      const text = category.getText();
      this.drawText(categoryId, text, screenCoord);
      this.drawDot(categoryId, screenCoord);
    }
  }

  drawTheories(theoryList, currentTarget = null) {
    if (!theoryList) return;
    if (theoryList.length <= 0) return;
    if (!(theoryList[0] instanceof DBTheory)) {
      return;
    }

    for (const theory of theoryList) {
      const screenCoord = theory.getScreenCoord();
      // const categoryId = theory.getCategoryId();
      const text = theory.getText();
      const theoryId = theory.getId();
      this.drawText(theoryId, text, screenCoord, currentTarget);
      this.drawDot(theoryId, screenCoord, currentTarget);
    }
  }

  drawText(theoryId, text, coord) {
    const defaultStyle = {
      color: 'black',
      fontSize: '10px',
      fontWeight: '500',
      zIndex: 10,
    };

    let color = defaultStyle.color;
    let fontSize = defaultStyle.fontSize;
    let fontWeight = defaultStyle.fontWeight;
    let zIndex = defaultStyle.zIndex;

    d3.selectAll('g.arcCategory')
      .append('text')
      .text(text)
      .attr('id', 'click_spot_text_' + theoryId)
      .attr('x', coord.x + 10)
      .attr('y', coord.y - 3)
      .attr('font-family', 'sans-serif')
      .style('font-size', fontSize)
      .attr('text-anchor', 'start')
      .style('stroke', 'none')
      .style('fill', color)
      .style('font-weight', fontWeight)
      .style('z-index', zIndex);
  }

  // generateRandomValue(number) {
  //   let randomNum = Math.random() * number - number / 2;
  //   return randomNum;
  // }

  drawDot(theoryId, coord) {
    const defaultStyle = {
      color: 'red',
      radius: 3,
      zIndex: 10,
    };

    let color = defaultStyle.color;
    let radius = defaultStyle.radius;
    let zIndex = defaultStyle.zIndex;

    d3.selectAll('g.arcCategory')
      .append('circle')
      .attr('id', 'click_spot_dot_' + theoryId)
      .attr('cx', coord.x)
      .attr('cy', coord.y)
      .attr('r', radius)
      // .style('stroke', 'red')
      .style('fill', color)
      .style('z-index', zIndex)
      .on('mouseover', function (d) {
        d3.select(this)
          .transition()
          .duration(200)
          .attr('r', radius * 1.6)
          .style('fill', 'yellow');
        // d3.select(`#click_spot_text_${theoryId}`)
        //   .transition()
        //   .duration(200)
        //   .style('z-index', 14)
        //   .style('fill', 'black')
        //   .style('font-weight', '800')
        //   .style('font-size', '10px');
      })
      .on('mouseout', function (d) {
        d3.select(this)
          .transition()
          .duration(200)
          .attr('r', radius)
          .style('fill', color);
        // d3.select(`#click_spot_text_${theoryId}`)
        //   .transition()
        //   .duration(200)
        //   .style('z-index', 10)
        //   .style('fill', 'gray')
        //   .style('font-weight', '500')
        //   .style('font-size', '8px');
      });
  }
}
