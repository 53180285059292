import { createSlice } from '@reduxjs/toolkit';
import { signOut } from '../login/loginInfo';
import { Theory } from '../../../api/theory/theory';
// import { TheoryManagement } from '../../../api/theory/theoryManager';
import { getRandomValue } from '../../../api/common/common';

const quizAnswerSlice = createSlice({
  name: 'quizAnswer',
  initialState: {
    currentNum: 0,
    theoryManager: null,
    answersForOneQuestion: [
      {
        idx: 0,
        text: '',
        startTime: null,
        endTime: null,
        degree: null,
        depth: null,
        theory: null,
      },
    ],
    answersForAllQuestion: [],
  },
  reducers: {
    setTheoryManager: (state, action) => {
      state.theoryManager = action.payload.theoryManager;
    },
    updateAnswer: (state, action) => {
      const { answer, index } = action.payload;

      const answerByIdx = state.answersForOneQuestion[index];
      answerByIdx.text = answer;
      if (!answerByIdx.theory) {
        answerByIdx.theory = new Theory(Math.random(), answer);
        state.theoryManager?.addTheory(answerByIdx.theory);
        // console.log(answerByIdx.theory);
      } else {
        answerByIdx.theory.setText(answer);
      }
    },
    updateDepthAndDegree(state, action) {
      const { degree, depth } = action.payload;
      console.log(degree, depth);
      if (degree > 100 || degree < 0) {
        throw new Error('Out of range in Degree');
      }
      if (depth > 100 || depth < 0) {
        throw new Error('Out of range in Depth');
      }

      const index = state.currentNum;
      const answer = state.answersForOneQuestion[index];

      if (!answer.theory) {
        throw new Error('No Theory Instance');
      }
      answer.degree = degree;
      answer.depth = depth;
      answer.theory?.setDegree(degree);
      answer.theory?.setDepth(depth);
      answer.theory?.setCategoryId(
        getRandomValue(0, state.theoryManager.getMaxCategoryId(), true)
      );
    },
    addAnswerState: (state) => {
      const lastIndex = state.answersForOneQuestion.length;
      state.answersForOneQuestion.push({
        idx: lastIndex,
        text: '',
        startTime: new Date().getTime(),
        endTime: null,
        degree: null,
        depth: null,
        theory: null,
      });
    },
    clearAnswersState: (state) => {
      state.theoryManager = null;
      state.answersForOneQuestion = [
        {
          idx: 0,
          text: '',
          startTime: null,
          endTime: null,
          degree: null,
          depth: null,
          theory: null,
        },
      ];
      state.answersForAllQuestion = [];
      state.currentNum = 0;
    },
    removeOneAnswer: (state, action) => {
      const index = action.payload.index;
      const answers = state.answersForOneQuestion
        .filter((_, idx) => idx !== index)
        .map((item, idx) => ({ ...item, idx }));
      // answers.splice(index, 1);
      const currentNum = answers[index] ? index : index - 1;
      state.answersForOneQuestion = answers;
      state.currentNum = currentNum;
    },
    updateCurrentNum: (state, action) => {
      state.currentNum = action.payload.currentNum;
    },
    finishCurrentAnswer: (state) => {
      state.answersForOneQuestion[state.currentNum].endTime =
        new Date().getTime();
    },
    setStartTime: (state) => {
      state.answersForOneQuestion[0].startTime = new Date().getTime();
    },
    finishLastAnswer: (state) => {
      const lastIdx = state.answersForOneQuestion.length - 1;
      if (state.answersForOneQuestion[lastIdx].text)
        state.answersForOneQuestion[lastIdx].endTime = new Date().getTime();
    },
  },
  extraReducers: {
    [signOut]: (state) => {
      state.answersForOneQuestion = [
        {
          idx: 0,
          text: '',
          startTime: null,
          endTime: null,
          degree: null,
          depth: null,
        },
      ];
      state.answersForAllQuestion = [];
      state.currentNum = 0;
    },
  },
});

export const {
  updateAnswer,
  addAnswerState,
  clearAnswersState,
  removeOneAnswer,
  updateCurrentNum,
  finishCurrentAnswer,
  setStartTime,
  finishLastAnswer,
  updateDepthAndDegree,
  setTheoryManager,
} = quizAnswerSlice.actions;
export default quizAnswerSlice.reducer;
