import React from 'react';
import TheoryData from './TheoryData';

export default function TheoriesData({ theories }) {
  // mode가 modifyTheory일 경우 클릭하면 theory text값 수정
  // mode가 modifyTheory가 아닐 경우 클릭하면 move할 수 있도록

  //TODO: 실시간 저장

  return (
    <div>
      {theories.map((theory) => (
        <TheoryData
          key={theory.getId() + theory.getCategoryId()}
          theory={theory}
        />
      ))}
    </div>
  );
}
