import React, { useEffect, useState, useMemo } from "react";
import styles from "../styles/ManagementMember.module.css";
import { useNavigate } from "react-router-dom";
import Pagination from "react-bootstrap/Pagination";
import { useSelector } from "react-redux";
import { useAxiosContext } from "../context/AxiosContainer";
import Button from "react-bootstrap/Button";
import DropdownBtn from "../components/managementMember/Dropdown";
import MemberList from "../components/managementMember/MemberList";

export default function ManagementMember() {
  let navigate = useNavigate();

  const { httpInterface } = useAxiosContext();
  const { accessToken } = useSelector((state) => state.loginInfo);
  const [data, setData] = useState([]);
  const [postsPerPage, setPostsPerPage] = useState(5);
  let items = [];
  // pagination
  const [activeBtn, setActiveBtn] = useState(0);
  const [page, setPage] = useState();

  for (let i = 0; i < page; i++) {
    items.push(
      <Pagination.Item
        key={i}
        onClick={() => {
          setActiveBtn(i);
        }}
        active={i === activeBtn}
      >
        {i + 1}
      </Pagination.Item>
    );
  }
  useEffect(() => {
    httpInterface
      .getMemberList(accessToken, {
        page: activeBtn,
        size: 10,
        sort: "createdAt,desc",
      })
      .then(async (res) => {
        console.log(res);
        await setData(res.data.data.content);
        await setPage(res.data.data.totalPages);
      })
      .catch((error) => {
        console.log("error : ", error.response);
      });
  }, [activeBtn]);

  const columns = useMemo(
    () => [
      {
        accessor: "userNum",
        Header: "id",
      },
      {
        accessor: "name",
        Header: "이름",
      },
      {
        accessor: "email",
        Header: "이메일",
      },
      {
        accessor: "round",
        Header: "테스트시작",
      },
      {
        accessor: "totalScore",
        Header: "랭킹",
      },
      {
        accessor: "phone",
        Header: "최근 접속일",
      },
    ],
    []
  );

  return (
    <>
      <div className={styles.Container}>
        <span style={{ marginRight: "2%" }}>
          <b>회원관리</b>
        </span>
        <span
          onClick={() => {
            navigate("/Admin/managementMember");
          }}
          style={{ marginRight: "1%" }}
        >
          <b>
            <ins>일반</ins>
          </b>
        </span>
        <span
          onClick={() => {
            navigate("/Admin/managementResearcher");
          }}
        >
          연구원
        </span>
        <div className={styles.MenuWrap}>
          <DropdownBtn />

          <input type="search" className={styles.Search} />

          <Button className={styles.SearchBtn}>조회</Button>
          <Button className={styles.AddMemberBtn}>+신규 회원 생성</Button>
        </div>
        <MemberList columns={columns} data={data} />
      </div>
      <Pagination>{items}</Pagination>
    </>
  );
}
