import axios from "axios";
import { isEmptyObj } from "../common/common";

export default class HttpInterface {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  // 유효성 검사(Token 검사), isRefresh = true는 토큰 재발급 여부
  //Todo 230423 - 현재 동작하지 않음.. axios error 발생 -> 아마 안 쓸 것 같습니다 (아래 reissueToken을 사용하여 처리할 것 같습니다.)
  async hasValidation(url, isRefresh, params, method) {
    if (!isRefresh) {
      return this.apiClient.validateAccessToken(url, params, method);
      // .catch((err) => console.log('at-error', err));
    } else {
      return this.apiClient.refreshValidation().then(console.log);
      // .catch((err) => console.log('rt-error', err));
    }
  }

  async reissueToken(accessToken) {
    return this.apiClient.postWithAccessToken(
      "/v2/api/user/refresh",
      accessToken
    );
  }

  async getQuizData(accessToken, params) {
    const url = "/v2/api/psquiz";
    return this.apiClient.getWithAccessToken(url, accessToken, params);
  }

  async getNextQuizData(accessToken, params) {
    const url = "/v2/api/caseps/answer";
    console.log(accessToken, params);
    return this.apiClient.postWithAccessToken(url, accessToken, params);
  }

  async signInNew(data) {
    return this.apiClient.post("/v2/api/user/signin", data);
  }
  async signIn(data) {
    return this.apiClient.post("/v2/api/user/login", data);
  }

  async postChangePasswordEmail(data) {
    return this.apiClient.post("/v2/api/user/password", data);
  }

  async postResendEmail(data) {
    return this.apiClient.post("/v2/api/user/validation/retry", data);
  }

  async getCurrentQuestion(accessToken) {
    return this.apiClient.getWithAccessToken(
      "/v2/api/caseps/current",
      accessToken
    );
  }

  async getUserStatus(accessToken) {
    return this.apiClient.getWithAccessToken("/v2/api/user", accessToken);
  }

  async getFilterValueData(accessToken) {
    const url = "/v2/api/admin/users/tasks/filter";
    return this.apiClient.getWithAccessToken(url, accessToken);
  }

  async getAdminPageData(accessToken, params) {
    return this.apiClient.getWithAccessToken(
      "/v2/api/admin/tests",
      accessToken,
      params
    );
  }

  async getTheoryData(accessToken, params) {
    return this.apiClient.getWithAccessToken(
      "/v2/api/admin/tests/batch/default",
      accessToken,
      params
    );
  }

  async postTheoryData(accessToken, data, config) {
    return this.apiClient.postWithAccessToken(
      "/v2/api/batch/default",
      accessToken,
      data,
      config
    );
  }

  async postClearTest(accessToken) {
    return this.apiClient.postWithAccessToken(
      "/v2/api/caseps/reset",
      accessToken
    );
  }
  async putModifyTheoryData(accessToken, data = [], config) {
    if (isEmptyObj(data)) return null;
    return this.apiClient.putWithAccessToken(
      "/v2/api/batch/default",
      accessToken,
      data,
      config
    );
  }

  async deleteTheoryData(accessToken, config) {
    return this.apiClient.deleteWithAccessToken(
      "/v2/api/batch/default",
      accessToken,
      config
    );
  }

  async getAssignTheoryData(accessToken, config) {
    return this.apiClient.getWithAccessToken(
      "/v2/api/batch/default",
      accessToken,
      config
    );
  }

  async getTheoriesForReseacher(accessToken, params) {
    const url = "/v2/api/batch";
    return this.apiClient.getWithAccessToken(url, accessToken, params);
  }

  async getFiveAnswers(accessToken, params) {
    const url = "/v2/api/batch/answer";
    return this.apiClient.getWithAccessToken(url, accessToken, params);
  }

  async getHundredAnswers(accessToken, params) {
    const url = "/v2/api/batch/default/answer";
    return this.apiClient.getWithAccessToken(url, accessToken, params);
  }

  async postAnswersAndGetNewAnswers(accessToken, params) {
    const url = "/v2/api/batch/next";
    return this.apiClient.postWithAccessToken(url, accessToken, params);
  }

  async postCreateCase(accessToken, params) {
    const url = "/v2/api/psquiz/creation";
    return this.apiClient.postWithAccessToken(url, accessToken, params);
  }

  async getViewCase(accessToken) {
    const url = "/v2/api/psquiz/creation/temp/admin";
    return this.apiClient.getWithAccessToken(url, accessToken);
  }

  async postFirstViewCreateQuizId(accessToken) {
    const url = "/v2/api/psquiz/creation/temp";
    return this.apiClient.postWithAccessToken(url, accessToken);
  }

  async postCreateComplete(accessToken) {
    const url = "/v2/api/psquiz/creation/completion";
    return this.apiClient.postWithAccessToken(url, accessToken);
  }
  async postCreateCaseResearcher(accessToken, data, params) {
    const url = "/v2/api/psquiz/creation/completion";
    return this.apiClient.postWithAccessToken(url, accessToken, data, params);
  }
  async deleteCreateCase(accessToken, config) {
    console.log(accessToken);
    const url = "/v2/api/psquiz/creation";
    return this.apiClient.deleteWithAccessToken(url, accessToken, config);
  }

  async getResearcherList(accessToken, params) {
    return this.apiClient.getWithAccessToken(
      "/v2/api/admin/users/researchers",
      accessToken,
      {
        params: params,
      }
    );
  }

  async getMemberList(accessToken, params) {
    return this.apiClient.getWithAccessToken(
      "/v2/api/admin/users",
      accessToken,
      { params: params }
    );
  }

  async getTaskFilter(accessToken) {
    return this.apiClient.getWithAccessToken(
      "/v2/api/admin/users/tasks/filter",
      accessToken
    );
  }

  async getTaskSearch(accessToken, params) {
    return this.apiClient.getWithAccessToken(
      "/v2/api/admin/users/tasks/search",
      accessToken,
      {
        params: params,
      }
    );
  }

  async getReassignData(accessToken, params) {
    const url = "/v2/api/admin/tests/batch/trash";
    return this.apiClient.getWithAccessToken(url, accessToken, {
      params,
    });
  }

  async postResearcherTask(accessToken, data) {
    const url = "/v2/api/admin/users/tasks";
    return this.apiClient.postWithAccessToken(url, accessToken, data);
  }

  async getCreateCaseResearcherView(accessToken, params) {
    const url = "/v2/api/psquiz/creation/temp/researcher";
    return this.apiClient.getWithAccessToken(url, accessToken, params);
  }

  async getCaseView(accessToken) {
    return this.apiClient.getWithAccessToken("/v2/api/caseps", accessToken);
  }
  async getReassignAnswerList(accessToken, params) {
    const queryString = { subQuestionId: params };
    const url = "/v2/api/admin/tests/batch/trash";
    return this.apiClient.getWithAccessToken(url, accessToken, {
      params: queryString,
    });
  }

  async getSubQuestionRankList(url, accessToken) {
    return this.apiClient.getWithAccessToken(url, accessToken);
  }

  async getRankCaseDropdown(accessToken) {
    return this.apiClient.getWithAccessToken(
      "/v2/api/rank/dropdown",
      accessToken
    );
  }

  async getTotalRankView(url, accessToken) {
    return this.apiClient.getWithAccessToken(url, accessToken);
  }

  async getGotoStartTest(url, accessToken) {
    return this.apiClient.getWithAccessToken(url, accessToken);
  }

  async postSignUp(data) {
    return this.apiClient.post("/v2/api/user/signup", data);
  }

  async postEmailValidation(data, headers) {
    return this.apiClient.post("v2/api/user/validation", data, headers);
  }

  async postChangePassword(data) {
    return this.apiClient.post("v2/api/user/password/change", data);
  }

  async getResearcherView(accessToken) {
    return this.apiClient.getWithAccessToken(
      "/v2/api/admin/users/tasks/researchers",
      accessToken
    );
  }
}
