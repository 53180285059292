import React from "react";
import styles from "../../styles/Admin.module.css";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setSubQuestionBody,
  setSubQuestionId,
} from "../../redux/slice/theory/createTheory";
// import { setSubQuestionId } from '../../redux/slice/theory/reassignTheory';

export default function TableList({ batchData }) {
  const {
    batchCount,
    notBatchCount,
    subQuestionBody,
    level,
    category,
    caseName,
    hasDefaultBatch,
    subQuestionId,
  } = batchData;
  const dispatch = useDispatch();

  // console.log(batchData);

  return (
    <li className={`${styles["grid-design"]} ${styles["table-list"]}`}>
      <div>
        <Form.Check aria-label="option 1" />
      </div>
      <div>일반</div>
      <div>{transDifficultyToNum(level)}</div>
      <div>{caseName}</div>
      <div colSpan={3}>{subQuestionBody}</div>
      <div>
        미배치 {!notBatchCount ? "-" : notBatchCount}
        <br />
        배치 {!batchCount ? "-" : batchCount}
        <br />
      </div>
      <div>
        {/* 배치가 0인 경우에 생성, 아니면 편집 */}
        {hasDefaultBatch && (
          <Link to="/admin/edit" state={subQuestionId}>
            수정
          </Link>
        )}
        {!hasDefaultBatch && (
          <Link to="/admin/create" state={subQuestionId}>
            생성
          </Link>
        )}
      </div>
      <div>
        <Link
          to="/admin/rearrange"
          onClick={() => {
            dispatch(setSubQuestionBody({ subQuestionBody }));
            dispatch(setSubQuestionId({ subQuestionId }));
          }}
        >
          {hasDefaultBatch && "재배치"}
        </Link>
      </div>
      <div>
        <Link style={{ marginBottom: "0.25rem" }}>수정</Link>
        <Link>삭제</Link>
      </div>
    </li>
  );
}

function transDifficultyToNum(difficulty) {
  if (difficulty === "EASY") return 1;
  if (difficulty === "NORMAL") return 2;
  if (difficulty === "HARD") return 3;
  if (difficulty === "VERY_HARD") return 4;
  if (difficulty === "EXPERT") return 5;
  //throw new Error("Difficulty Value is invalid");
}

function transQuestionString(question) {
  if (!question) return question;
  const temp = question.split("-", 2);
  const questionString = temp[1]?.split(".", 2);
  //console.log(questionString);
  /*if (questionString?.length !== 2) {
    throw new Error("transQuestionString function has error");
  }
*/
  return questionString && `${questionString[0]}번-${questionString[1]}`;
}
